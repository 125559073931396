// import { configureStore } from "@reduxjs/toolkit";
// import boardsSlice from "./boardsSlice2";
// import AxiosService from "../axios";

// const store = configureStore({
//   reducer: {
//     boards: boardsSlice,
//   },
//   preloadedState: initialState,

// })

// export default store
import { configureStore } from "@reduxjs/toolkit";
import { api ,authSlice} from "./ApiSlice";
console.log(api);
const initialState = {
	boards: [
		{
			name: "Funil de vendas",
			isActive: false,
			columns: [
				{
					name: "Pre Orçamento",
					tasks: [],
				},
				{
					name: "Proposta final",
					tasks: [],
				},
				{
					name: "Aguardando Pagamento",
					tasks: [],
				},
			],
		},
	],
};


const store = configureStore({
	reducer: {
		[api.reducerPath]: api.reducer,
		auth: authSlice.reducer,
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(api.middleware),
});

export default store;
