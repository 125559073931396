import React, { useState } from "react";
import ColumnComponent from "./ColumnComponent"; // Import the ColumnComponent

function BoardComponent({ boardData, onChange }) {
  const [activeColumnIndex, setActiveColumnIndex] = useState(0);

  // Function to set active column index
  const setActiveColumn = (index) => {
    setActiveColumnIndex(index);
  };

  return (
    <div className="overflow-auto pt-[90px] min-w-[360px] custom-scrollbar mx-2">
      <div className="flex space-x-4 pb-4"> {/* Adjusted for spacing between buttons */}
        {/* Iterate through your buttons */}
        {['Pre Orçamento', 'Proposta Final', 'Aguardando Pagamento', 'Expirados'].map((buttonLabel, index) => (
          <button
            key={index}
            onClick={() => setActiveColumnIndex(index)}
            className={` ${index === activeColumnIndex ? 'active-button' : 'button '}`}
          >
            {buttonLabel}
          </button>
        ))}
      </div>

      {/* Render the active column component */}
      {boardData.columns.length > 0 && (
        <ColumnComponent
          columnData={boardData.columns[activeColumnIndex]}
          colIndex={activeColumnIndex}
          onChange={onChange}
        />
      )}
    </div>
  );
}

export default BoardComponent;
