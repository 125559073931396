import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import crossIcon from "../assets/icon-cross.svg";
import boardsSlice from "../redux/boardsSlice.js";
import AxiosService from "../axios.js";
import DeleteModal from "./DeleteModal.js";
import { MdAddTask, MdDeleteOutline } from "react-icons/md";
import { Store } from "react-notifications-component";
import Input from "../components/Input.tsx";
import { SubmitHandler, useForm } from "react-hook-form";
import * as XLSX from "xlsx";
import utils from "../utils.js";

function AddEditLeadModal({
	type,
	device,
	setIsTaskModalOpen,
	setIsAddTaskModalOpen,
	taskIndex,
	prevColIndex = 0,
	colIndex,
	action,
	onClose,
}) {
	const dispatch = useDispatch();
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [isValid, setIsValid] = useState(true);
	const [nome, setNome] = useState(""); // Initialize 'nome' with an empty string
	const [origemList, setOrigemList] = useState([]);
	const [origem, setOrigem] = useState(""); // Initialize 'origem' with an empty string
	const [origemId, setOrigemId] = useState();
	const [dataRegistro, setDataRegistro] = useState(""); // Initialize 'dataRegistro' with an empty string
	const [tipoDesc, setTipoDesc] = useState(0); // Initialize 'tipoDesc' with an empty string
	const [statusLead, setStatusLead] = useState(0); // Initialize 'statusLead' with an empty string
	const [contato, setContato] = useState(""); // Initialize 'contato' with an empty string
	const [valor, setValor] = useState("R$ 0"); // Initialize 'valor' with an empty string
	const [cidade, setCidade] = useState(""); // Initialize 'cidade' with an empty string
	const [email, setEmail] = useState(""); // Initialize 'email' with an empty string
	const [fone, setFone] = useState(""); // Initialize 'fone' with an empty string
	const [idProj, setidProj] = useState(0);
	const [codProj, setCodProj] = useState();
	const [revendas, setRevendas] = useState([]);
	const [idRevenda, setRevendaId] = useState();
	const [revenda, setRevenda] = useState("");
	const [atividadeList, setAtividadeList] = useState([]);
	const [atividade, setAtividade] = useState("");
	const [leadid, setLeadId] = useState("");
	const [FaturamentoAno, setFaturamentoAno] = useState([]);

	const task = action;

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const token = useSelector((state) => state.auth.token);

	const setToken = (token) => {
		AxiosService.setAuthorizationToken(token);
	};

	const handleDateChange = (e) => {
		setDataRegistro(e.target.value); // Update the state with the selected date value
		console.log(e.target.value);
	};
	const getCurrentDateTime = () => {
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
		const day = String(now.getDate()).padStart(2, "0");
		const hours = String(now.getHours()).padStart(2, "0");
		const minutes = String(now.getMinutes()).padStart(2, "0");

		return `${year}-${month}-${day}T${hours}:${minutes}`;
	};
	const onChangeStatus = (e) => {
		setContato(e.target.value);
	};

	const validate = () => {
		setIsValid(false);
		if (!nome.trim()) {
			return false;
		}
		setIsValid(true);
		return true;
	};

	if (type === "edit" && isFirstLoad) {
		// const currentDate = new Date(action.DATA_RETORNO);
		// const date = new Date(currentDate- 3 * 60 * 60 * 1000);
		// const formattedDate = date.toISOString().slice(0, 16);
		// setTitle(action.TIPO);
		// setDescription(action.DESCRICAO);
		// setContactType(action.TIPO_CONTATO);
		// setContact(action.CONTATO);
		// setDate(formattedDate);
		// setIsFirstLoad(false);
		setNome(action.NOME_LEAD);
		setOrigem(action.ORIGEM_LEAD);
		setDataRegistro(action.DATA_REGISTRO);
		setTipoDesc(action.TIPO_DESC);
		setStatusLead(action.STATUS_LEAD);
		setContato(action.CONTATO);
		setValor(action.VALOR);
		setCidade(action.CIDADE);
		setEmail(action.EMAIL);
		setFone(action.FONE);
		setCodProj(action.COD_PROJ);
		setOrigemId(action.ORIGEM_ID);
		setRevendaId(action.ID_REVENDA);
		setRevenda(action.RAZAO_SOCIAL);
		setLeadId(action.ID_LEAD);
		setIsFirstLoad(false);
	}

	const setOpenDeleteModal = () => {
		setIsDeleteModalOpen(true);
	};

	const onDelete = (type, id) => {
		if (type === "edit") {
			const data = {
				ID_LEAD: id, // Replace with the actual ID_PROJ value // Replace with the actual completada value
			};

			const endpoint = `/lead/deleteLead/${id}`;

			AxiosService.delete(endpoint)
				.then((response) => {
					console.log("POST Request Success:", response.data);
					// Handle successful response data
				})
				.catch((error) => {
					console.error("POST Request Error:", error);
					// Handle errors
				});
		}
		Store.addNotification({
			title: "Deletada!",
			message: "Atividade deletada!",
			type: "danger",
			insert: "top",
			container: "top-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 2500,
				onScreen: true,
			},
		});
	};

	const onSubmit = (type) => {
		var cleanedValue=valor;
		var numericValue=0;
		if (valor != undefined || valor != null) {
			cleanedValue=cleanedValue.replace(/[^\d,.]/g, "");
		}
		numericValue = parseFloat(
			cleanedValue.replace(".", "").replace(",", "."),
		); 
		
		if (type === "add") {
			const endpoint = "/leadRevenda/createLead";
			const postData = {
				nome: nome,
				origem: origem,
				dataRegistro: dataRegistro.replace("T", " "),
				tipoDesc: tipoDesc,
				statusLead: statusLead,
				contato: contato,
				valor: numericValue,
				cidade: cidade,
				email: email,
				fone: fone,
				origemId: origemId,
				codProj: codProj,
				// Other fields as needed
			};

			AxiosService.post(endpoint, postData)
				.then((response) => {
					console.log("POST Request Success:", response.data);
					// Handle successful response data
				})
				.catch((error) => {
					console.error("POST Request Error:", error);
					// Handle errors
				});
			Store.addNotification({
				title: "Salvo!",
				message: "Lead salvo!",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 2500,
					onScreen: true,
				},
			});
		} else {
			const endpoint = `/leadRevenda/updateLead/${action.ID_LEAD}`;
			const postData = {
				nome: nome,
				dataRegistro: dataRegistro.replace("T", " "),
				statusLead: statusLead,
				contato: contato,
				valor: numericValue,
				cidade: cidade,
				email: email,
				fone: fone,
				codProj: codProj,
				origem:origem,
				origemId:origemId
				// Other fields as needed
			};

			AxiosService.post(endpoint, postData)
				.then((response) => {
					console.log("POST Request Success:", response.data);
					// Handle successful response data
				})
				.catch((error) => {
					console.error("POST Request Error:", error);
					// Handle errors
				});

			Store.addNotification({
				title: "Salvo!",
				message: "Atividade salva!",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 2500,
					onScreen: true,
				},
			});
		}
	};
	const getRevendas = async () => {
		try {
			const result = await AxiosService.get("/revendas/revendas");

			setRevendas(
				result.data.sort((a, b) =>
					a.RAZAO_SOCIAL.localeCompare(b.RAZAO_SOCIAL),
				),
			);
		} catch (error) {
			console.log(error);
		}
	};

	const getAtividadesLead = async () => {
		try {
			const result = await AxiosService.get("/atividade/getAllbyType/lead");
			setAtividadeList(
				result.data.sort((a, b) => a.ATIVIDADE.localeCompare(b.ATIVIDADE)),
			);
		} catch (error) {
			console.log(error);
		}
	};

	const getOrigem = async () => {
		try {
			const result = await AxiosService.get("/origem/");
			setOrigemList(
				result.data.sort((a, b) => a.NOME_ORIGEM.localeCompare(b.NOME_ORIGEM)),
			);
		} catch (error) {
			console.log(error);
		}
	};
	const handleFoneChange = (e) => {
		const input = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
		let formattedInput = "";

		if (input.length < 3) {
			formattedInput = `(${input}`;
		} else if (input.length < 6) {
			formattedInput = `(${input.slice(0, 2)}) ${input.slice(2)}`;
		} else if (input.length < 11) {
			formattedInput = `(${input.slice(0, 2)}) ${input.slice(
				2,
				7,
			)}-${input.slice(7)}`;
		} else {
			formattedInput = `(${input.slice(0, 2)}) ${input.slice(
				2,
				7,
			)}-${input.slice(7, 11)}`;
		}

		setFone(formattedInput);
	};
	const contactList = [
		{ name: "Telefone" },
		{ name: "Email" },
		{ name: "Whatsapp" },
		{ name: "Telegram" },
	];
	const handleFileUpload = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = async (event) => {
			const data = new Uint8Array(event.target.result);
			const workbook = XLSX.read(data, {
				type: "array",
				cellText: false,
				cellDates: true,
			});
			const sheet = workbook.Sheets["Leads"];
			if (!sheet) {
				alert('Sheet "leads" not found in the Excel file');
				return;
			}
			const jsonData = XLSX.utils.sheet_to_json(sheet, {
				raw: false, // Don't parse cell formulas or errors
				defval: null, // Default value for empty cells
			});
			for (const lead of jsonData) {
				const postData = {
					nome: lead.Cliente,
					origem: "Intelbras",
					statusLead: lead.nel == "Ganho" ? 5 : lead.nel == "Perdido" ? 4 : 0,
					cidade: lead.Cidade,
					email: lead["E-mail do cliente"],
					fone: lead["Telefone do cliente"],
					origemId: 1,
					// Other fields as needed
				};
				
				try {
					const response = await AxiosService.post(
						"/lead/createLead",
						postData,
					);
					console.log(
						`Lead ${lead.NOME_LEAD} inserted, response:`,
						response.data,
					);
				} catch (error) {
					console.error(`Error inserting lead ${lead.NOME_LEAD}:`, error);
				}
				await utils.sleep(5000);
			}
		};
		reader.readAsArrayBuffer(file);
	};
	useEffect(() => {
		setToken(token);
		setDataRegistro(getCurrentDateTime());
		getAtividadesLead();
		getRevendas();
		getOrigem();
	}, []);

	const handleValorChange = (e) => {
		const inputValue = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
		const numberValue = Number(inputValue) / 100; // Divide by 100 to get the proper decimal representation (assuming the input represents cents)
		const newValue = numberValue.toLocaleString("pt-BR", {
			style: "currency",
			currency: "BRL",
		});
		setValor(newValue);
	};
	return (
		<div
			className={
				device === "mobile"
					? "  px-6 z-50 absolute  left-0 flex right-0 top-0 dropdown "
					: "  px-6 z-50 absolute left-0 flex right-0 bottom-0 top-0 dropdown "
			}
			onClick={(e) => {
				if (e.target !== e.currentTarget) {
					return;
				}
				onClose();
				setIsAddTaskModalOpen(false);
				onClose();
			}}
		>
			{/* Modal Section */}

			<div
				className=" relative scrollbar-hide overflow-auto max-h-[100vh] my-auto bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold
       shadow-md shadow-[#364e7e1a] max-w-[800px] w-[800px] px-8 mx-auto py-8 rounded-xl"
			>
				{" "}
				<div>
					<h1 className=" text-xl text-center hover:scale-105 hover:text-[#3A007E] duration-500 ease-in-out">
						{type === "edit" ? "Editar" : "Adicionar Novo Lead"}
					</h1>
				</div>
				{/* Task Name */}
				<div className="mt-4 flex flex-col space-y-1 ">
					<Input
						className="mt-2"
						labelText="Nome"
						value={nome}
						onChange={(e) => setNome(e.target.value)}
					/>
				</div>
				{/* Description */}
				{/* Subtasks */}
				<div className="mt-2 flex flex-col space-y-1 ">
					<Input
						className="mt-2"
						labelText="Contato"
						type="text"
						value={contato}
						onChange={(e) => setContato(e.target.value)}
					/>
				</div>
				<div className="mt-2 flex flex-col space-y-1 ">
					<Input
						className="mt-2"
						type="text"
						labelText="Valor"
						value={valor}
						onChange={(e) => handleValorChange(e)}
					/>
				</div>
				<div className="mt-2 flex flex-col space-y-1 ">
					<Input
						className="mt-2"
						labelText="Telefone"
						type="text"
						value={fone}
						onChange={handleFoneChange}
					/>
				</div>
				<div className="mt-2 flex flex-col space-y-1 ">
					<Input
						className="mt-2"
						labelText="Email"
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<div className="mt-2 flex flex-col space-y-1 ">
					<Input
						className="mt-2"
						labelText="Cidade"
						value={cidade}
						onChange={(e) => setCidade(e.target.value)}
					/>
				</div>
				<div className="mt-2 flex flex-col space-y-1 ">
					<Input
						className="mt-2"
						labelText="Projeto"
						type="text"
						value={codProj}
						onChange={(e) => setCodProj(e.target.value)}
					/>
				</div>
				<div className="mt-2 flex flex-col space-y-1">
					<label className="text-sm dark:text-white text-gray-500 py-2">
						Origem
					</label>
					<select
						value={origem}
						onChange={(e) => {
							setOrigem(e.target.value);
							const selectedIndex = e.target.selectedIndex;
							const selectedOptionKey =
								e.target[selectedIndex].getAttribute("data-key");
							setOrigemId(selectedOptionKey);
						}}
						className=" select-status flex-grow px-4 py-2 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  border-[1px] border-gray-300 focus:outline-[#3A007E] outline-none hover:outline-[#3A007E]  duration-500 ease-in-out"
					>
						{Array.isArray(origemList) && origemList.length !== 0
							? origemList.map((origem, index) => (
									<option data-key={origem.ID_ORIGEM} key={origem.ID_ORIGEM}>
										{origem.NOME_ORIGEM}
									</option>
							  ))
							: null}
					</select>
					<label className="text-sm dark:text-white text-gray-500 py-2">
						Atividades
					</label>
					{/* <select
						value={atividade}
						onChange={(e) => {
							setAtividade(e.target.value);
							const selectedIndex = e.target.selectedIndex;
							const selectedOptionKey =
								e.target[selectedIndex].getAttribute("data-key");
							setStatusLead(selectedOptionKey);
						}}
						className=" select-status flex-grow px-4 py-2 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  border-[1px] border-gray-300 focus:outline-[#3A007E] outline-none hover:outline-[#3A007E]  duration-500 ease-in-out"
					>
						{Array.isArray(atividadeList) && atividadeList.length !== 0
							? atividadeList.map((atividade, index) => (
									<option
										data-key={atividade.NIVEL}
										key={atividade.ID_ATIVIDADE}
									>
										{atividade.ATIVIDADE}
									</option>
							  ))
							: null}
					</select> */}
				</div>
				<div className="mt-2 flex flex-col space-y-1 text-black items-center justify-center">
					<label className="text-sm dark:text-white text-gray-500 py-2 self-start	">
						Data de Registro
					</label>
					<div className="mt-2 flex items-center justify-center ">
						{/* <Calendar
							className="mt-4 rounded-lg bg-[#f4f7fd] dark:bg-[#20212c] dark:text-white hover:scale-105 duration-500 ease-in-out border-0"
							onChange={onChange}
							value={date}
              
						/> */}
						<input
							aria-label="Date and time"
							type="datetime-local"
							className="p-2 rounded-lg bg-[#f4f7fd] dark:bg-[#20212c] dark:text-white hover:scale-105 duration-500 ease-in-out border-0"
							onChange={handleDateChange}
							value={dataRegistro}
						/>
					</div>
				</div>
				{/* <div className="mt-8 flex flex-col space-y-3">
          <label className="  text-sm dark:text-white text-gray-500">
            Subtasks
          </label>

          {subtasks.map((subtask, index) => (
            <div key={index} className=" flex items-center w-full ">
              <input
                onChange={(e) => {
                  onChangeSubtasks(subtask.id, e.target.value);
                }}
                type="text"
                value={subtask.title}
                className=" bg-transparent outline-none focus:border-0 flex-grow px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-[1px]  "
                placeholder=" e.g Take coffee break"
              />
              <img
                src={crossIcon}
                onClick={() => {
                  onDelete(subtask.id);
                }}
                className=" m-4 cursor-pointer "
              />
            </div>
          ))}

          <button
            className=" w-full items-center dark:text-[#3A007E] dark:bg-white  text-white bg-[#3A007E] py-2 rounded-full hover:scale-105 duration-500 ease-in-out "
            onClick={() => {
              setSubtasks((state) => [
                ...state,
                { title: "", isCompleted: false, id: uuidv4() },
              ]);
            }}
          >
            + Add New Subtask
          </button>
        </div> */}
				{/* current Status  */}
				<div className="mt-8 flex flex-row ">
					<button
						onClick={() => {
							const isValid = validate();
							if (isValid) {
								onSubmit(type);
								onClose();
								setIsAddTaskModalOpen(false);
								onClose();
							}
						}}
						className="w-full flex items-center justify-center text-white m-2 bg-gradient-to-l from-[#fae24d] to-[#ed772f] hover:opacity-80 py-2 rounded-md  hover:scale-105 duration-500 ease-in-out "
					>
						<MdAddTask className="mr-2" />
						{type === "edit" ? " Salvar" : "Criar "}
					</button>
					<button
						className={`w-full py-2 flex items-center justify-center m-2 shadow-lg bg-red-700 hover:opacity-80 rounded-md text-center hover:scale-105 duration-500 ease-in-out ${
							type === "edit" ? "" : "hidden"
						}`}
						onClick={() => {
							setOpenDeleteModal();
						}}
					>
						{" "}
						<MdDeleteOutline className="mr-2" />
						{type === "edit" ? "Excluir" : ""}
					</button>
				</div>
				<div className="mt-4 flex justify-center items-center mt-2">
					<label
						htmlFor="file-upload"
						className="flex items-center justify-center px-4 py-2 bg-green-500 text-white font-medium rounded-md cursor-pointer hover:bg-blue-700 focus:outline-none focus:shadow-outline"
					>
						<svg
							className="mr-2 h-6 w-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4-4m0 0l-4 4m4-4v12"
							></path>
						</svg>
						Inserir com Excel
					</label>
					<input
						id="file-upload"
						type="file"
						onChange={handleFileUpload}
						accept=".xlsx, .xls"
						className="hidden"
					/>
				</div>
			</div>
			{isDeleteModalOpen && (
				<DeleteModal
					setIsDeleteModalOpen={setIsDeleteModalOpen}
					title={"jogos"}
					onDeleteBtnClick={() => {
						onDelete(type, action.ID_LEAD);
						setIsAddTaskModalOpen(false);
						onClose();
					}}
					type="atividade"
				/>
			)}
		</div>
	);
}

export default AddEditLeadModal;
