import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import AddEditBoardModal from "../modals/AddEditBoardModal";
import Column from "./Column";
import EmptyBoard from "./EmptyBoard";
import Sidebar from "./Sidebar";
import AxiosService from "../axios";
import { useFetchDataFromApiQuery } from "../redux/ApiSlice"; // Replace with the correct path to your boardsSlice file
import AddEditLeadModal from "../modals/AddEditLeadModal";
import DigitalSearch from "./DigitalSearch";
import DigitalSearchProj from "./DigitalSearchProj";
import ElipsisMenuProjFilter from "./ElipsisMenuProjFilter";
import elipsis from "../assets/icon-vertical-ellipsis.svg";
import ElipsisMenuLeadFilter from "./ElipsisMenuLeadFilter";
import { CiLogout } from "react-icons/ci";
import { useFetchAtividadeQuery } from "../redux/ApiSlice"; // Replace with the correct path to your boardsSlice file
import UserListFilterTask from "./UserListFIlterTask";
import AddEditOffModal from "../modals/AddEditOffModal";
import ListColumn from "./ListColumn";
import BoardComponent from "./BoardComponent";
import EmpresaListFilterTask from "./EmpresaListFilterTask";
import AddEditDistribuidoraModal from "../modals/AddEditDistribuidoraModel";

function Home() {
	const [windowSize, setWindowSize] = useState([
		window.innerWidth,
		window.innerHeight,
	]);
	const [boards, setBoards] = useState([]);
	const [columns, setColumns] = useState([]);
	const [activeBoard, setActiveBoard] = useState([]);
	const [listening, setListening] = useState(false);
	const [bordid, setBordId] = useState(0);
	const bordidRef = useRef(bordid);
	const [isLeadModalOpen, setIsLeadModalOpen] = useState(false);
	const [isOffModalOpen, setIsOffModalOpen] = useState(false);
	const [isDistribuidroaModalOpen, setIsDistribuidroaModalOpen] =
		useState(false);
	const [isCodProjFilter, setIsCodProjFilter] = useState(false);
	const [isValueFilter, setIsValueFilter] = useState(false);
	const [isRevendaFilter, setIsRevendaFilter] = useState(false);
	const [isClientFilter, setIsClientFilter] = useState(false);
	const [isOtherUserTask, setisOtherUserTask] = useState(false);
	const [isOtherEmpresaTask, setisOtherEmpresaTask] = useState(false);
	const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
	const [isNomeLeadFilter, setIsNomeLeadFilter] = useState(false);
	const [isOrigemFilter, setIsOrigemFilter] = useState(false);
	const [isCidadeFilter, setIsCidadeFilter] = useState(false);
	const [showInput, setShowInput] = useState(false);
	const [Filter, setFilter] = useState(false);
	const [searchTerm, setSearchTerm] = useState(true);
	const [filteredResults, setFilterBoard] = useState([]);
	const [AtivadesUsuario, setAtivadesUsuario] = useState([]);
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.auth.user);
	const setToken = (token) => {
		AxiosService.setAuthorizationToken(token);
	};
	const {
		isLoading: isLoadingAtividade,
		isError: isErrorAtividade,
		isSuccess: isSuccessAtividade,
		data: atividadeData,
	} = useFetchAtividadeQuery();
	useEffect(() => {
		bordidRef.current = bordid;
	}, [bordid]);

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize([window.innerWidth, window.innerHeight]);
		};

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	});
	const [isSuccess, setIsSucess] = useState(false);
	const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
	const [isSideBarOpen, setIsSideBarOpen] = useState(true);
	const setOtherTasks = () => {
		setisOtherUserTask(true);
	};
	const setEmpresaTasks = () => {
		setisOtherEmpresaTask(true);
	};

	const onChangeActive = () => {
		setActiveBoard(boards.find((board) => board.isActive === true));
		setColumns(activeBoard.columns);
		console.log(boards, activeBoard, columns);
	};

	const handleClick = async (boardId) => {
		if (boardId != null) {
			const updatedBoards = boards.map((board) => {
				if (board.id === boardId) {
					setBordId(boardId);
					return { ...board, isActive: true };
				} else {
					return { ...board, isActive: false };
				}
			});
			setFilterBoard([]);

			setBoards(updatedBoards);
			setActiveBoard(updatedBoards.find((board) => board.isActive === true));
			setColumns(
				updatedBoards.find((board) => board.isActive === true).columns,
			);
		}
	};
	const fetchUpdatedTaskList = async () => {
		try {
			const result = await AxiosService.get("/funilByRevendaId");
			setBoards(result.data.boards);
			setActiveBoard(
				result.data.boards.find((board) => board.isActive === true),
			);
			setColumns(
				result.data.boards.find((board) => board.isActive === true).columns,
			);
			setBordId(result.data.boards.find((board) => board.isActive === true).id);
		} catch (error) {
			console.log(error);
		}
	};
	const [listType, setListType] = useState(0);
	useEffect(() => {
		// Check if data is successfully fetched and has changed
		const getData = async () => {
			await fetchUpdatedTaskList();

			setIsSucess(true);
		};
		getData();
	}, []);

	useEffect(() => {
		if (!listening) {
			const events = new EventSource(
				process.env.REACT_APP_API_HOST + "/streaming",
			);
			events.onmessage = async (event) => {
				const parsedData = JSON.parse(event.data);
				const currentBordId = bordidRef.current; // Access the current value using the ref
				if (parsedData != null) {
					try {
						const result = await AxiosService.get("/funilByRevendaId");
						setBoards(result.data.boards);
						setActiveBoard(
							result.data.boards.find((board) => board.id === currentBordId),
						);
						setColumns(
							result.data.boards.find((board) => board.id === currentBordId)
								.columns,
						);
					} catch (error) {
						console.log(error);
					}
				}
			};
			setListening(true);
		}
	}, [listening]);

	const fetchCurrent = async () => {
		if (searchTerm != false) {
			const currentBordId = bordidRef.current; // Access the current value using the ref
			try {
				const result = await AxiosService.get("/funilByRevendaId");
				setBoards(result.data.boards);

				setActiveBoard(
					result.data.boards.find((board) => board.id === currentBordId),
				);
				setColumns(
					result.data.boards.find((board) => board.id === currentBordId)
						.columns,
				);
			} catch (error) {
				console.log(error);
			}
		}
	};

	const setCodProjFilter = () => {
		setIsElipsisMenuOpen(false);
		setIsCodProjFilter(true);
		setFilter("COD_PROJETO");
	};
	const setValueFilter = () => {
		setIsElipsisMenuOpen(false);
		setIsCodProjFilter(true);
		setFilter("VALOR");
	};
	const setRevendaFilter = () => {
		setIsElipsisMenuOpen(false);
		setIsCodProjFilter(true);
		setFilter("REVENDA");
	};

	const setRevendaLeadFilter = () => {
		setIsElipsisMenuOpen(false);
		setIsNomeLeadFilter(true);
		setFilter("REVENDA");
	};
	const setListTypeClose = () => {
		setIsElipsisMenuOpen(false);
		if (listType == 1) setListType(0);
		else if (listType == 0) setListType(1);
		setIsCodProjFilter(false);
	};
	const setClientProjFilter = () => {
		setIsElipsisMenuOpen(false);
		setIsNomeLeadFilter(true);
		setFilter("CLIENTE");
	};

	const setTask = () => {
		setIsElipsisMenuOpen(false);
		onlyMyTask("COD_PROJETO");
	};
	const setClientFilter = () => {
		setIsElipsisMenuOpen(false);
		setIsNomeLeadFilter(true);
		setFilter("CLIENTE");
	};

	const setNomeLeadFilter = () => {
		setIsNomeLeadFilter(true);
		setIsElipsisMenuOpen(false);

		setFilter("NOME_LEAD");
	};
	const setOrigemFilter = () => {
		setIsElipsisMenuOpen(false);
		setIsNomeLeadFilter(true);

		setFilter("ORIGEM_LEAD");
	};

	const setNomeOffFilter = () => {
		setIsNomeLeadFilter(true);
		setIsElipsisMenuOpen(false);

		setFilter("NOME_OFF");
	};
	const setOrigemOffFilter = () => {
		setIsElipsisMenuOpen(false);
		setIsNomeLeadFilter(true);
		setFilter("ORIGEM_OFF");
	};

	const setCidadeFilter = () => {
		setIsElipsisMenuOpen(false);
		setIsNomeLeadFilter(true);
		setFilter("CIDADE");
	};
	const handleSearch = async (term, filter) => {
		if (term == "" && AtivadesUsuario.length == 0) {
			setSearchTerm(true);
			setFilterBoard([]);
			setTimeout(() => {
				fetchCurrent();
			}, 2000);
		} else if (AtivadesUsuario.length > 0) {
			const filtered = term
				? filteredResults.columns.map((column) => ({
						...column,
						tasks: column.tasks.filter((task) =>
							task[filter].toLowerCase().includes(term.toLowerCase()),
						),
				  }))
				: filteredResults.columns;
			setFilterBoard({ ...filteredResults, columns: filtered });
		} else {
			const filteredResults = term
				? activeBoard.columns.map((column) => ({
						...column,
						tasks: column.tasks.filter((task) =>
							task[filter].toLowerCase().includes(term.toLowerCase()),
						),
				  }))
				: activeBoard.columns;

			setFilterBoard({ ...activeBoard, columns: filteredResults });
			setSearchTerm(false);
		}
	};

	const handleOnlyResponsavel = async () => {
		console.log(bordidRef.current);
		if (bordidRef.current == 0) {
			console.log(activeBoard);
			const filteredResults = activeBoard.columns.map((column) => ({
				...column,
				tasks: column.tasks.filter((task) => {
					// Filter for tasks where:
					// 1. RESPONSAVEL is equal to user.ID_USUARIO (assigned to the user)
					// 2. RESPONSAVEL is null OR 'Sem responsavel' (unassigned tasks)
					return (
						task.RESPONSAVEL == user.ID_USUARIO ||
						task.RESPONSAVEL == null ||
						task.RESPONSAVEL.toLowerCase() === "sem responsavel".toLowerCase()
					);
				}),
			}));

			setActiveBoard({ ...activeBoard, columns: filteredResults });
		}
	};
	const onlyMyTask = async () => {
		fetchUpdatedTaskList();
		const orderList = [...atividadeData];

		orderList.forEach((task) => {
			if (user.ID_USUARIO === task.ID_USUARIO) {
				AtivadesUsuario.push(task.COD_PROJETO);
			}
		});

		const filteredResults = activeBoard.columns.map((column) => ({
			...column,
			tasks: column.tasks.filter((task) =>
				AtivadesUsuario.includes(task.COD_PROJETO),
			),
		}));

		setFilterBoard({ ...activeBoard, columns: filteredResults });
		setSearchTerm(false);
	};
	const onClickOtherTask = async (id) => {
		onlyTask(id);
		setisOtherEmpresaTask(false);
		setIsElipsisMenuOpen(false);
	};
	const onClickEmpresaTask = async (id) => {
		handleSearch(id, "CNPJ_DISTRIBUIDORA");
		setisOtherEmpresaTask(false);
		setIsElipsisMenuOpen(false);
	};

	const onlyTask = async (id) => {
		fetchUpdatedTaskList();
		const orderList = [...atividadeData];

		orderList.forEach((task) => {
			if (id == task.ID_USUARIO) {
				AtivadesUsuario.push(task.COD_PROJETO);
			}
		});

		const filteredResults = activeBoard.columns.map((column) => ({
			...column,
			tasks: column.tasks.filter((task) =>
				AtivadesUsuario.includes(task.COD_PROJETO),
			),
		}));

		setFilterBoard({ ...activeBoard, columns: filteredResults });
		console.log({ ...activeBoard, columns: filteredResults });
		setSearchTerm(false);
	};

	const onlyTaskOff = async (id) => {
		const orderList = [...atividadeData];

		orderList.forEach((task) => {
			if (id == task.ID_USUARIO) {
				AtivadesUsuario.push(task.ID_OFF);
			}
		});

		const filteredResults = activeBoard.columns.map((column) => ({
			...column,
			tasks: column.tasks.filter((task) =>
				AtivadesUsuario.includes(task.ID_OFF),
			),
		}));

		setFilterBoard({ ...activeBoard, columns: filteredResults });
	};
	// setInterval(fetchCurrent, 20000);
	
	if (isSuccess) {
		return (
			<>
				<div
					className={
						windowSize[0] >= 768 && isSideBarOpen
							? " bg-[#f4f7fd] relative  overflow-auto h-screen flex dark:bg-[#20212c]  gap-6  ml-[261px] custom-scrollbar"
							: "bg-[#f4f7fd]  relative   h-screen flex dark:bg-[#20212c] overflow-x-scroll gap-6 custom-scrollbar"
					}
				>
					{isLeadModalOpen && (
						<AddEditLeadModal
							setIsAddTaskModalOpen={setIsLeadModalOpen}
							type="add"
							device="mobile"
							onClose={fetchCurrent}
						/>
					)}
					{isOffModalOpen && (
						<AddEditOffModal
							setIsAddTaskModalOpen={setIsOffModalOpen}
							type="add"
							device="mobile"
							onClose={fetchCurrent}
						/>
					)}
					{isDistribuidroaModalOpen && (
						<AddEditDistribuidoraModal
							setIsAddTaskModalOpen={setIsDistribuidroaModalOpen}
							type="add"
							device="mobile"
							onClose={fetchCurrent}
						/>
					)}
					{windowSize[0] >= 768 && (
						<Sidebar
							setIsBoardModalOpen={setIsBoardModalOpen}
							isBoardModalOpen={isBoardModalOpen}
							isSideBarOpen={isSideBarOpen}
							setIsSideBarOpen={setIsSideBarOpen}
							onChange={handleClick}
						/>
					)}

					{activeBoard.id === 1 ? (
						<div className="relative flex flex-col space-x-2 items-center md:space-x-1 items-center justify-center">
							<div className="flex flex-row  space-x-2 items-center justify-center md:space-x-1">
								<div
									className={`absolute left-0 top-20 flex justify-between w-full items-center mt-3 ${
										isNomeLeadFilter ? "ml-7" : "ml-7"
									}`}
								>
									<h1 className="text-lg"></h1>
									{isNomeLeadFilter ? (
										<img
											onClick={() => {
												setIsNomeLeadFilter(false);
											}}
											src={elipsis}
											alt="elipsis"
											className="cursor-pointer h-6"
										/>
									) : (
										<img
											onClick={() => {
												setIsNomeLeadFilter(true);
												setIsElipsisMenuOpen((prevState) => !prevState);
											}}
											src={elipsis}
											alt="elipsis"
											className="cursor-pointer h-6"
										/>
									)}
									{isElipsisMenuOpen && (
										<ElipsisMenuLeadFilter
											setNomeFilter={setNomeLeadFilter}
											setOrigemFilter={setOrigemFilter}
											setCidadeFilter={setCidadeFilter}
											setRevendaFilter={setRevendaLeadFilter}
										/>
									)}
								</div>
								<div className="w-[100px]" />
							</div>
							{isNomeLeadFilter && (
								<DigitalSearch
									data={activeBoard}
									onChange={fetchCurrent}
									filter={Filter}
									onSearch={handleSearch}
								/>
							)}

							<div className="flex flex-row space-x-2 items-center justify-center md:space-x-1">
								<button
									className="button hidden md:block bg-gradient-to-l  from-[#30214b] to-[#181224] w-[100px]"
									onClick={() => {
										setIsLeadModalOpen((prevState) => !prevState);
									}}
								>
									+ Lead
								</button>
								<button
									onClick={() => {
										setIsLeadModalOpen((prevState) => !prevState);
									}}
									className="button py-1 px-3 md:hidden"
								>
									+
								</button>
							</div>
						</div>
					) : activeBoard.id === 2 ? (
						<div className="relative flex flex-col w-[200px] space-x-2 items-center md:space-x-1 items-center justify-center">
							{isCodProjFilter && (
								<DigitalSearchProj
									data={activeBoard}
									onChange={fetchCurrent}
									filter={Filter}
									bordid={activeBoard.id}
									onSearch={handleSearch}
								/>
							)}
							{isOtherUserTask && (
								<UserListFilterTask setTask={onClickOtherTask} />
							)}
							<div className="flex flex-row  space-x-2 items-center justify-center md:space-x-1">
								<div
									className={`absolute left-0 top-20 flex justify-between w-full items-center mt-3 ${
										isCodProjFilter ? "ml-6" : "ml-0"
									}`}
								>
									<h1 className="text-lg"></h1>
									{isCodProjFilter === true ? (
										<img
											onClick={() => {
												setIsCodProjFilter(false);
											}}
											src={elipsis}
											alt="elipsis"
											className="cursor-pointer h-6"
										/>
									) : (
										<img
											onClick={() => {
												setIsCodProjFilter(true);
												setIsElipsisMenuOpen((prevState) => !prevState);
											}}
											src={elipsis}
											alt="elipsis"
											className="cursor-pointer h-6"
										/>
									)}
									{isElipsisMenuOpen && (
										<ElipsisMenuProjFilter
											setCodProjFilter={setCodProjFilter}
											setValueFilter={setValueFilter}
											setClientFilter={setClientProjFilter}
											setRevendaFilter={setRevendaFilter}
											setOtherTask={setOtherTasks}
										/>
									)}
								</div>
								{isCodProjFilter ? (
									<div className="w-[100px]"></div>
								) : (
									<div className="w-[1px]"></div>
								)}
							</div>
						</div>
					) : activeBoard.id === 3 ? (
						<div className="relative flex flex-col space-x-2 items-center md:space-x-1 items-center justify-center">
							<div className="flex flex-row  space-x-2 items-center justify-center md:space-x-1">
								<div
									className={`absolute left-0 top-20 flex justify-between w-full items-center mt-3 ${
										isNomeLeadFilter ? "ml-7" : "ml-7"
									}`}
								>
									<h1 className="text-lg"></h1>
									{isNomeLeadFilter ? (
										<img
											onClick={() => {
												setIsNomeLeadFilter(false);
											}}
											src={elipsis}
											alt="elipsis"
											className="cursor-pointer h-6"
										/>
									) : (
										<img
											onClick={() => {
												setIsNomeLeadFilter(true);
												setIsElipsisMenuOpen((prevState) => !prevState);
											}}
											src={elipsis}
											alt="elipsis"
											className="cursor-pointer h-6"
										/>
									)}
									{isElipsisMenuOpen && (
										<ElipsisMenuLeadFilter
											setNomeFilter={setNomeOffFilter}
											setOrigemFilter={setOrigemOffFilter}
											setCidadeFilter={setCidadeFilter}
											setRevendaFilter={setRevendaLeadFilter}
										/>
									)}
								</div>
								<div className="w-[100px]" />
							</div>
							{isNomeLeadFilter && (
								<DigitalSearch
									data={activeBoard}
									onChange={fetchCurrent}
									filter={Filter}
									onSearch={handleSearch}
								/>
							)}

							<div className="flex flex-row space-x-2 items-center justify-center md:space-x-1">
								<button
									className="button hidden md:block bg-gradient-to-l  from-[#30214b] to-[#181224] w-[100px]"
									onClick={() => {
										setIsOffModalOpen((prevState) => !prevState);
									}}
								>
									+ Off
								</button>
								<button
									onClick={() => {
										setIsOffModalOpen((prevState) => !prevState);
									}}
									className="button py-1 px-3 md:hidden"
								>
									+
								</button>
							</div>
						</div>
					) : activeBoard.id === 4 ? (
						<div className="relative flex flex-col space-x-2 items-center md:space-x-1 items-center justify-center">
							<div className="flex flex-row  space-x-2 items-center justify-center md:space-x-1">
								<div
									className={`absolute left-0 top-20 flex justify-between w-full items-center mt-3 ${
										isNomeLeadFilter ? "ml-7" : "ml-7"
									}`}
								>
									<h1 className="text-lg"></h1>
									{isNomeLeadFilter ? (
										<img
											onClick={() => {
												setIsNomeLeadFilter(false);
											}}
											src={elipsis}
											alt="elipsis"
											className="cursor-pointer h-6"
										/>
									) : (
										<img
											onClick={() => {
												setIsNomeLeadFilter(true);
												setIsElipsisMenuOpen((prevState) => !prevState);
											}}
											src={elipsis}
											alt="elipsis"
											className="cursor-pointer h-6"
										/>
									)}
									{isElipsisMenuOpen && (
										<ElipsisMenuLeadFilter
											setNomeFilter={setNomeOffFilter}
											setOrigemFilter={setOrigemOffFilter}
											setCidadeFilter={setCidadeFilter}
											setRevendaFilter={setRevendaLeadFilter}
										/>
									)}
								</div>
								<div className="w-[100px]" />
							</div>
							{isNomeLeadFilter && (
								<DigitalSearch
									data={activeBoard}
									onChange={fetchCurrent}
									filter={Filter}
									onSearch={handleSearch}
								/>
							)}

							<div className="flex flex-row space-x-2 items-center justify-center md:space-x-1 w-full">
								<button
									className="button hidden md:block bg-gradient-to-l  from-[#30214b] to-[#181224] w-full"
									onClick={() => {
										setIsDistribuidroaModalOpen((prevState) => !prevState);
									}}
								>
									+ Distribuidora
								</button>
								<button
									onClick={() => {
										setIsDistribuidroaModalOpen((prevState) => !prevState);
									}}
									className="button py-1 px-3 md:hidden"
								>
									+
								</button>
							</div>
						</div>
					) : (
						<div className="relative flex flex-col w-max-[200px] space-x-2 items-center md:space-x-1 items-center justify-center">
							{isCodProjFilter && (
								<DigitalSearchProj
									data={{
										...activeBoard,
										columns: activeBoard.columns.map((column) => ({
											...column,
											tasks: column.tasks.filter(
												(task) => typeof task.EXPIRADO === "undefined",
											),
										})),
									}}
									onChange={fetchCurrent}
									filter={Filter}
									bordid={activeBoard.id}
									onSearch={handleSearch}
								/>
							)}
							{isOtherUserTask && (
								<UserListFilterTask setTask={onClickOtherTask} />
							)}
							{isOtherEmpresaTask && (
								<EmpresaListFilterTask setTask={onClickEmpresaTask} />
							)}
							<div className="flex flex-row  space-x-2 items-center justify-center md:space-x-1">
								<div
									className={`absolute left-0 top-20 flex justify-between w-full items-center mt-3 ${
										isCodProjFilter ? "ml-6" : "ml-0"
									}`}
								>
									<h1 className="text-lg"></h1>
									{isCodProjFilter === true ? (
										<img
											onClick={() => {
												setIsCodProjFilter(false);
											}}
											src={elipsis}
											alt="elipsis"
											className="cursor-pointer h-6"
										/>
									) : (
										<img
											onClick={() => {
												setIsCodProjFilter(true);
												setIsElipsisMenuOpen((prevState) => !prevState);
											}}
											src={elipsis}
											alt="elipsis"
											className="cursor-pointer h-6"
										/>
									)}
									{isElipsisMenuOpen && (
										<ElipsisMenuProjFilter
											setCodProjFilter={setCodProjFilter}
											setValueFilter={setValueFilter}
											setClientFilter={setClientProjFilter}
											setRevendaFilter={setRevendaFilter}
											setTask={setTask}
											setOtherTask={setOtherTasks}
											setEmpresaTask={setEmpresaTasks}
											setListType={setListTypeClose}
										/>
									)}
								</div>
								{isCodProjFilter ? (
									<div className="w-[100px]"></div>
								) : (
									<div className="w-[1px]"></div>
								)}
							</div>
						</div>
					)}
					{(columns.length > 0 && listType == 0) ||
					(columns.length > 0 && activeBoard.id != 0) ? (
						<>
							{columns.map((col, index) => (
								<Column
									key={index}
									colIndex={index}
									board={
										filteredResults.length !== 0 ? filteredResults : activeBoard
									}
									onChange={fetchCurrent}
									onModalProject={fetchUpdatedTaskList}
								/>
							))}
							{/* <div
onClick={() => {
  setIsBoardModalOpen(true);
}}
className=" h-screen dark:bg-[#2b2c3740] flex justify-center items-center font-bold text-2xl hover:text-[#635FC7] transition duration-300 cursor-pointer bg-[#E9EFFA] scrollbar-hide mb-2   mx-5 pt-[90px] min-w-[280px] text-[#828FA3] mt-[135px] rounded-lg "
>
+ Nova Coluna
</div> */}
						</>
					) : columns.length > 0 && listType == 1 && activeBoard.id == 0 ? (
						<>
							{/* {columns.map((col, index) => (
								<ListColumn
									key={index}
									colIndex={index}
									board={
										filteredResults.length !== 0 ? filteredResults : activeBoard
									}
									onChange={fetchCurrent}
									onModalProject={fetchUpdatedTaskList}
								/>
							))} */}
							<BoardComponent
								boardData={
									filteredResults.length !== 0 ? filteredResults : activeBoard
								}
								onChange={fetchCurrent}
							/>
						</>
					) : (
						<>
							<EmptyBoard />
						</>
					)}
					{isBoardModalOpen && (
						<AddEditBoardModal
							type="edit"
							setIsBoardModalOpen={setIsBoardModalOpen}
						/>
					)}
				</div>
			</>
		);
	}
}

export default Home;
