import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-plugin-datalabels';

const ActiveProject= ({ value }) => {

const active=value;
  return (
    
    <div className='flex flex-col space-y-1 bg-[#f4f7fd] dark:bg-[#20212c] rounded-lg w-[150px] px-4  ml-1  text-center shadow-lg hover:scale-110 duration-500 ease-in-out hover:text-[#3A007E]'>
      <p className='flex flex-col items-center justify-center'>Propostas Ativas</p>
        <div className="flex flex-col items-center justify-center py-6" >
          <div className="mx-auto bg-gradient-to-l  from-[#30214b] to-[#181224]  px-3 py-3 rounded-md text-white duration-500 ease-in-out">
            <p className='duration-500 ease-in-out text-ys'> {active != null ? active : "0"}</p>
          </div>
      </div>
    </div>
  );
};

export default ActiveProject;