import Logo from "../assets/LOGO_VOICE_SOLAR-removebg-preview.png";
import { MdAddTask, MdDeleteOutline, MdPerson, MdShield } from "react-icons/md";
import React, { useState, useEffect } from "react";

const Login = ({ onLogin }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			login();
		}
	};

	const login = () => {
		if (typeof onLogin === "function") {
			onLogin({ username, password });
		} else {
			console.error("onLogin is not a function");
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleKeyPress);
		return () => {
			document.removeEventListener("keydown", handleKeyPress);
		};
	}, [username, password]);
	return (
		<div
			className={
				"bg-gradient-to-l from-[#30214b] to-[#181224]  scrollbar-hide h-screen flex dark:bg-[#20212c] overflow-x-scroll gap-6 "
			}
		>
			<div
				className=" container mx-auto relative scrollbar-hide overflow-y-scroll max-h-[95vh] my-auto bg-[#20212c] dark:bg-[#2b2c37] text-black dark:text-white font-bold
                shadow-lg shadow-[#364e7e1a] max-w-[800px] w-[600px] mx-auto px-8  py-8 rounded-xl justify-center items-center hover:scale-105 duration-500 ease-in-out	"
			>
				{" "}
				<div className="text-center justify-center items-center">
					<img src={Logo} alt="Logo" className="mx-auto m-0 object-contain object-center w-40 h-40" />

					{/* <h1 className="flex justify-center items-center text-3xl hover:scale-105 text-[#fae24d] duration-500 ease-in-out">
						Bem-vindo {" "}
						<span class="animate-waving-hand">👋🏻</span>
					</h1> */}
				</div>
				{/* Task Name */}
				<div className="mt-8 flex flex-col space-y-1 ">
					<label
						for="input-group-1"
						className="text-sm dark:text-white text-gray-500 py-2"
					>
						Login
					</label>
					<div class="relative mb-6 hover:scale-105 text-white hover:text-[#fae24d] duration-500 ease-in-out">
						<div class="absolute inset-y-0 start-0 flex items-center  pointer-events-none ">
							<MdPerson className="w-4 h-4 text-gray-500 dark:text-gray-400 ml-1" />
						</div>
						<input
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							type="text"
							id="input-group-1"
							class=" w-full bg-transparent px-6 py-2 outline-none focus:border-0 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-1  ring-0  "
							placeholder=""
						/>
					</div>
				</div>
				<div className=" flex flex-col space-y-1 ">
					<label
						for="input-group-1"
						className="text-sm dark:text-white text-gray-500 py-2"
					>
						Senha
					</label>
					<div className="relative mb-6 hover:scale-105 text-white hover:text-[#fae24d] duration-500 ease-in-out">
						<div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ">
							<MdShield className="w-4 h-4 text-gray-500 dark:text-gray-400 ml-1" />
						</div>
						<input
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							type="password"
							id="input-group-1"
							className=" w-full bg-transparent px-6 py-2 outline-none focus:border-0 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-1  ring-0  "
							placeholder=""
						/>
					</div>
				</div>
				<div className="mt-8 flex flex-row ">
					<button
						onClick={(e) => {
							// Check if onLogin is a function before invoking it

							if (typeof onLogin === "function") {
								onLogin({ username, password });
							} else {
								console.error("onLogin is not a function");
							}
						}}
						className="w-full flex items-center justify-center text-white bg-gradient-to-l from-[#fae24d] to-[#ed772f] hover:opacity-80 py-2 rounded-md  hover:scale-105 duration-500 ease-in-out "
					>
						<MdAddTask className="mr-2" />
						Entrar
					</button>
				</div>
			</div>
		</div>
	);
};

export default Login;
