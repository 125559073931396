import React from "react";
interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
  className?: string;
  error?: string;
}
const Input = React.forwardRef<HTMLInputElement, IProps>(
  ({ labelText, className, error,type, ...props }, ref) => {
    return (
      <>
         
        <div
          className={`${className} ${
            error && "animate-shake"
          } relative border-b-2 inline-flex justify-center`}
        >
          <input
            ref={ref}
            {...props}
            className="w-full focus:ring-0 border-transparent focus:border-transparent peer bg-transparent px-4 py-2 outline-none focus:border-0 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-1  ring-0  focus:scale-105 hover:text-[#3A007E] duration-500 ease-in-out  peer"
            type={type}
            placeholder=" "
          />
          <div className="border-[#3A007E] absolute top-full  transition-all duration-300 bg-[#3A007E]  w-0 h-0.5 peer-focus:w-full"></div>
          <label className="absolute -top-3 left-2 transition-all bg-transparent scale-75 px-1 duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-2 dark:peer-placeholder-shown:text-white  peer-placeholder-shown:text-black peer-focus:-top-2 peer-focus:scale-75 peer-focus:-left-2 peer-focus:text-[#3A007E]">
            {labelText}
          </label>
        </div>
        {error && (
          <p className={`${error && "animate-shake"} text-red-500 mt-1`}>
            {error}
          </p>
        )}
      </>
    );
  }
);

export default Input;