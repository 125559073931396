import { createSlice, createAsyncThunk,produce  } from "@reduxjs/toolkit";
import AxiosService from "../axios";

export const fetchDataFromApi = createAsyncThunk(
  "boards/fetchDataFromApi",
  async () => {
    try {
      const response = await AxiosService.get("/funildevendas");
      return response.data;
    } catch (error) {
      throw new Error("Error fetching data");
    }
  },
);

const boardsSlice = createSlice({
  name: "boards",
  initialState: {
    boards: [],
    loading: false,
    error: null,
  },
  reducers: {
    addBoard: (state, action) => {
        const isActive = state.length > 0 ? false : true;
        const payload = action.payload;
        const board = {
            name: payload.name,
            isActive,
            columns: [],
        };
        board.columns = payload.newColumns;
        state.push(board);
    },
    editBoard: (state, action) => {
        const payload = action.payload;
        const board = state.find((board) => board.isActive);
        board.name = payload.name;
        board.columns = payload.newColumns;
    },
    deleteBoard: (state) => {
        const board = state.find((board) => board.isActive);
        state.splice(state.indexOf(board), 1);
    },
    setBoardActive: (state, action) => {
        state.map((board, index) => {
            index === action.payload.index
                ? (board.isActive = true)
                : (board.isActive = false);
            return board;
        });
    },
    addTask: (state, action) => {
        const { title, status, description, subtasks, newColIndex } =
            action.payload;
        const task = { title, description, subtasks, status };
        const board = state.find((board) => board.isActive);
        const column = board.columns.find((col, index) => index === newColIndex);
        column.tasks.push(task);
    },
    editTask: (state, action) => {
        const {
            title,
            status,
            description,
            subtasks,
            prevColIndex,
            newColIndex,
            taskIndex,
        } = action.payload;
        const board = state.find((board) => board.isActive);
        const column = board.columns.find((col, index) => index === prevColIndex);
        const task = column.tasks.find((task, index) => index === taskIndex);
        task.title = title;
        task.status = status;
        task.description = description;
        task.subtasks = subtasks;
        if (prevColIndex === newColIndex) return;
        column.tasks = column.tasks.filter((task, index) => index !== taskIndex);
        const newCol = board.columns.find((col, index) => index === newColIndex);
        newCol.tasks.push(task);
    },
    dragTask: (state, action) => {
        // const { colIndex, prevColIndex, taskIndex } = action.payload;
        // const board = state.find((board) => board.isActive);
        // const prevCol = board.columns.find((col, i) => i === prevColIndex);
        // const task = prevCol.tasks.splice(taskIndex, 1)[0];
        // board.columns.find((col, i) => i === colIndex).tasks.push(task);
    },
    setSubtaskCompleted: (state, action) => {
        const payload = action.payload;
        const board = state.find((board) => board.isActive);
        const col = board.columns.find((col, i) => i === payload.colIndex);
        const task = col.tasks.find((task, i) => i === payload.taskIndex);
        const subtask = task.subtasks.find((subtask, i) => i === payload.index);
        subtask.isCompleted = !subtask.isCompleted;
    },
    setTaskStatus: (state, action) => {
        const { taskIndex, colIndex, newColIndex, status } = action.payload;
        const board = state.find((board) => board.isActive);
        const columns = board.columns;
        const col = columns.find((col, i) => i === colIndex);
        
        // If the task should not move to a new column, update the PAGAMENTO of the specific task in the same column
        if (colIndex === newColIndex) {
          const task = col.tasks.find((task, i) => i === taskIndex);
          task.PAGAMENTO_FUNIL = status;
        } else {
          // If the task should move to a new column, remove it from the current column and add it to the new column
          const task = col.tasks.find((task, i) => i === taskIndex);
          col.tasks = col.tasks.filter((task, i) => i !== taskIndex);
          const newCol = columns.find((col, i) => i === newColIndex);
          task.PAGAMENTO = status;
          newCol.tasks.push(task);
        }
      
    },
    deleteTask: (state, action) => {
        const payload = action.payload;
        const board = state.find((board) => board.isActive);
        const col = board.columns.find((col, i) => i === payload.colIndex);
        col.tasks = col.tasks.filter((task, i) => i !== payload.taskIndex);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataFromApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataFromApi.fulfilled,  (state, action) =>{ state= action.payload
      })
      .addCase(fetchDataFromApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default boardsSlice;
