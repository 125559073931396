import React from "react";

function ElipsisMenuProjFilter({
	type,
	setCodProjFilter,
	setValueFilter,
	setClientFilter,
	setRevendaFilter,
	setTask,
	setOtherTask,
	setListType,
	setEmpresaTask
}) {
	return (
		<div
			className={
				type === "Boards"
					? " absolute  top-16  right-5"
					: " absolute  top-6  right-1"
			}
		>
			<div className=" flex justify-end items-center">
				<div className=" w-40 text-sm z-50 font-medium shadow-md shadow-[#364e7e1a] bg-white dark:bg-[#20212c] space-y-4 py-5 px-4 rounded-lg  h-auto pr-12">
					<p
						onClick={() => {
							setCodProjFilter();
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease-in-out"
					>
						Codigo Projeto
					</p>
					<p
						onClick={() => {
							setValueFilter();
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease-in-out"
					>
						Valor
					</p>
					<p
						onClick={() => {
							setRevendaFilter();
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease-in-out"
					>
						Revenda
					</p>
					<p
						onClick={() => {
							setClientFilter();
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease-in-out"
					>
						Cliente
					</p>
					<p
						onClick={() => {
							setTask();
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease-in-out"
					>
						Minhas Atividades
					</p>
					<p	onClick={() => {
							setOtherTask();
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease"
						>
					Atividades 
					</p>
					<p	onClick={() => {
							setEmpresaTask();
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease"
						>
					Empresas 
					</p>
					<p	onClick={() => {
							setListType(1);
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease"
						>
					Trocar Listagem 
					</p>
				</div>
			</div>
		</div>
	);
}

export default ElipsisMenuProjFilter;
