import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import utils from "../utils";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";

const LineChart = ({ chartData, onClick }) => {
	const getCurrentMonth = () => {
		const months = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		const currentDate = new Date();
		const currentMonth = months[currentDate.getMonth()];
		return currentMonth;
	};
	const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
	const [hoveredLabel, setHoveredLabel] = useState(null);
	const monthArray = [
		"Jan",
		"Fev",
		"Mar",
		"Abr",
		"Mai",
		"Jun",
		"Jul",
		"Ago",
		"Set",
		"Out",
		"Nov",
		"Dez",
	];
	const currentYear = new Date().getFullYear();
	const getMonthNumber = (monthName) => {
		const monthMap = {
			Jan: 1,
			Fev: 2,
			Mar: 3,
			Abr: 4,
			Mai: 5,
			Jun: 6,
			Jul: 7,
			Ago: 8,
			Set: 9,
			Out: 10,
			Nov: 11,
			Dez: 12,
		};
		return monthMap[monthName] || 1;
	};

	const handlePointHover = (event, chartElements) => {
		if (chartElements && chartElements.length > 0) {
			const index = chartElements[0].index;
			// Assuming your labels are stored in an array
			const label = chartData.labels[index];
			setHoveredLabel(label);
		} else {
			setHoveredLabel(null);
		}
	};
	const options = {
		legend: {
			display: false,
		},
		plugins: {
			datalabels: {
				font: {
					size: 9, // Set the font size of the labels
				},
				display: true,
				color: "white", // Set the color of the labels
				anchor: "end",
				align: "end",
				formatter: (value) => {
					// Format the label as needed (e.g., currency formatting)
					return value === 0 ? "" : value;
				},
			},
		},
	};
	const dataMonth = {
		labels: Array.from({ length: 30 }, (_, index) => (index + 1).toString()),
		datasets: Object.entries(chartData).map(
			([userName, userActivities], index) => ({
				label: utils.firstName(userName),
				data: Array.from({ length: 30 }, (_, dayIndex) => {
					const matchingActivity = userActivities.find(
						(activity) => activity.day === dayIndex + 1,
					);
					return matchingActivity ? matchingActivity.atividade : 0;
				}),
				fill: false,
				borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
				borderWidth: 2,
			}),
		),
	};

	return (
		<div className="bg-[#f4f7fd] dark:bg-[#20212c] max-h-[300px] w-[400px] rounded-lg text-center shadow-lg hover:scale-110 duration-500 ease-in-out hover:text-[#3A007E] mt-2">
			Produtividade
			<select
				value={selectedMonth}
				onChange={(e) => {
					setSelectedMonth(e.target.value);
					onClick(getMonthNumber(e.target.value), currentYear);
				}}
				className="select-status ml-2 pr-8 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  focus:outline-[#3A007E] outline-none"
			>
				<option value=""></option>
				{monthArray.map((month) => (
					<option className="status-options" key={month} value={month}>
						{month}
					</option>
				))}
			</select>
			<Line
				data={dataMonth}
				height={200}
				width={300}
				options={options}
				plugins={[ChartDataLabels]}
			/>
		</div>
	);
};

export default LineChart;
