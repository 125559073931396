import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AxiosService from "../axios";
import Input from "../components/Input.tsx";

const UserRevendasModal = ({ isOpen, setIsOpen }) => {
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [revendasUser, setRevendasUser] = useState([]);
	const [otherRevendas, setOtherRevendas] = useState([]);
	const [revendas, setRevendas] = useState([]);
	const [selectedRevenda, setSelectedRevenda] = useState(null);
	const token = useSelector((state) => state.auth.token);
	const [meta, setMeta] = useState(null);

	const setToken = (token) => {
		AxiosService.setAuthorizationToken(token);
	};

	const getUserList = async () => {
		const usersList = await AxiosService.getUserList();
		setUsers(usersList);
	};

	const getRevendasList = async () => {
		const revendasList = await AxiosService.getRevendas();
		setRevendas(revendasList);
	};

	useEffect(() => {
		setToken(token);
		getUserList();
		getRevendasList();
	}, []);

	const handleUserChange = (event) => {
		const userId = event.target.value;
		const user = users.find((u) => u.ID_USUARIO == userId);
		setSelectedUser(user);
		if (user) {
			const userRevendas = revendas.filter(
				(revenda) => revenda.ID_RESPONSAVEL == user.ID_USUARIO,
			);
			const otherRevendasList = revendas.filter(
				(revenda) => revenda.ID_RESPONSAVEL != user.ID_USUARIO,
			);
			setRevendasUser(userRevendas);
			setOtherRevendas(otherRevendasList);
		} else {
			setRevendasUser([]);
		}
	};

	const handleRevendaChange = (event) => {
		const revendaId = event.target.value;
		const revenda = otherRevendas.find((r) => r.ID_REVENDA == revendaId);
		setSelectedRevenda(revenda);
	};

	const handleSaveMeta = async () => {
		if (selectedUser) {
			try {
				const result = await AxiosService.put("users/updatemeta", {
					USERID: selectedUser.ID_USUARIO,
					META: meta,
				});
				alert("Meta atualizada com sucesso!");
				if (result != null) {
					await getRevendasList();
					handleUserChange({ target: { value: selectedUser.ID_USUARIO } }); // Refresh lists
				}
			} catch (error) {
				console.error("Erro ao atualizar meta:", error);
				alert("Algo deu errado. Tente novamente.");
			}
		} else {
			alert("Por favor, selecione um usuário.");
		}
	};
	const handleSave = async () => {
		if (selectedRevenda && selectedUser) {
		  try {
			await AxiosService.post(`/api/revendas/${selectedRevenda.id}/update-responsavel`, {
			  id_responsavel: selectedUser.id
			});
			handleUserChange({ target: { value: selectedUser.id } }); // Refresh lists
		  } catch (error) {
			console.error("Erro ao atualizar responsável:", error);
			alert("Algo deu errado. Tente novamente.");
		  }
		} else {
		  alert("Por favor, selecione um usuário e uma revenda.");
		}
	  };
	// Função para fechar o modal
	const closeModal = () => {
		setIsOpen(false);
	};

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
			<div className="relative scrollbar-hide overflow-auto max-h-[100vh] my-auto bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] max-w-[800px] w-[800px] px-8 mx-auto py-8 rounded-xl  dark:text-white">
				<div className="mt-3 text-center">
					<h3 className="text-lg leading-6 font-medium dark:text-white text-gray-900">
						Gerenciar Revendas
					</h3>
					<div className="mt-2 space-y-5">
						<div className="mb-4">
							<label
								htmlFor="userSelect"
								className="block text-sm font-medium text-gray-700 mb-2  dark:text-white"
							>
								Selecionar Usuário:
							</label>
							<select
								id="userSelect"
								className="select-status block w-full px-4 py-2 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  border-[1px] border-gray-300 focus:outline-[#3A007E] outline-none hover:outline-[#3A007E]  duration-500 ease-in-out"
								onChange={handleUserChange}
								value={selectedUser ? selectedUser.ID_USUARIO : ""}
							>
								<option
									className="block w-full p-2 border border-gray-300 rounded-md shadow-sm dark:text-white"
									value=""
								>
									Selecione um usuário
								</option>
								{users?.map((user) => (
									<option
										className="block w-full p-2 border border-gray-300 rounded-md shadow-sm dark:text-white"
										key={user.ID_USUARIO}
										value={user.ID_USUARIO}
									>
										{user.NOME}
									</option>
								))}
							</select>
						</div>
						{selectedUser && (
							<div>
								<h2 className="text-xl font-semibold  dark:text-white">
									Revendas sob responsabilidade de {selectedUser.NOME}:
								</h2>
								<Input
									type="number"
									labelText="Meta"
									placeholder="Meta"
									value={meta}
									onChange={(e) => setMeta(e.target.value)}
								/>
								<button
									className="px-4 py-2 bg-green-500 text-white rounded-md mx-2"
									onClick={handleSaveMeta}
								>
									Salvar Meta
								</button>
								<ul className="flex flex-wrap list-black dark:list-white pl-5 mt-2 mb-4">
									{revendasUser?.map((revenda) => (
										<li
											key={revenda.ID_REVENDA}
											className="w-3/6 p-2 my-1 rounded-md shadow-sm dark:bg-[#20212c] dark:text-white text-xs"
										>
											{revenda.RAZAO_SOCIAL}
										</li>
									))}
								</ul>
								<div className="mb-4">
									<label
										htmlFor="revendaSelect"
										className="block text-sm font-medium text-gray-700 mb-2  dark:text-white"
									>
										Selecionar Revenda para adicionar responsabilidade:
									</label>
									<select
										id="revendaSelect"
										className="select-status block w-full px-4 py-2 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  border-[1px] border-gray-300 focus:outline-[#3A007E] outline-none hover:outline-[#3A007E]  duration-500 ease-in-out"
										onChange={handleRevendaChange}
										value={selectedRevenda ? selectedRevenda.ID_REVENDA : ""}
									>
										<option className=" dark:text-white" value="">
											Selecione uma revenda
										</option>
										{revendas?.map((revenda) => (
											<option
												className=" dark:text-white"
												key={revenda.ID_REVENDA}
												value={revenda.ID_REVENDA}
											>
												{revenda.RAZAO_SOCIAL}
											</option>
										))}
									</select>
								</div>
								{selectedRevenda && (
									<button
										className="px-4 py-2 bg-green-500 text-white rounded-md"
										onClick={handleSave}
									>
										Salvar
									</button>
								)}
							</div>
						)}
					</div>
					<div className="flex items-center justify-end p-6 rounded-b">
						<button
							type="button"
							className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
							onClick={closeModal}
						>
							Cancelar
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserRevendasModal;
