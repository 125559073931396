import React, { useState, useEffect } from "react";
import Input from "../components/Input.tsx";
import AxiosService from "../axios";
import ExpiringLocalStorage from "../ExpiringLocalStorage.js";
import { Store } from "react-notifications-component";

const EditUserModal = ({ isOpen, setIsOpen, currentUser }) => {
    const [username, setUsername] = useState("");
    const [login,setLogin]= useState("");
    const [email, setEmail] = useState("");
    const [password,setPassword]=useState("");

    useEffect(() => {
        if (currentUser) {
            setUsername(currentUser.user.NOME);
            setEmail(currentUser.user.EMAIL);
            setLogin(currentUser.user.LOGIN)
        }
    }, [currentUser]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Here, you would send the updated data to your server using Axios or another HTTP client.
        console.log({ username, email });
        await AxiosService.put(`users/update`, { NOME:username, LOGIN:email ,SENHA:password });

        // After submission, you can clear the form and close the modal
        setUsername("");
        setEmail("");
        setIsOpen(false);
        Store.addNotification({
            title: "Salvo!",
            message: "Usuário salvo!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
            },
        });
    };

    // Close modal function
    const closeModal = () => {
        setIsOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative scrollbar-hide overflow-auto max-h-[100vh] my-auto bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] max-w-[800px] w-[800px] px-8 mx-auto py-8 rounded-xl">
                <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 font-medium dark:text-white text-gray-900">
                        Editar Usuário
                    </h3>
                    <form onSubmit={handleSubmit} className="mt-2 space-y-5">
                        <Input
                            type="text"
                            labelText="Nome"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full mt-2"
                        />
                        <Input
                            type="text"
                            labelText="Email"
                            value={email}
                            onChange={(e) => setLogin(e.target.value)}
                            className="w-full mt-2"
                        />
                        <Input
                            type="text"
                            labelText="Login"
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                            className="w-full mt-2"
                        />
                        <Input
                            type="password"
                            labelText="Senha"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full mt-2"
                        />
                        <div className="flex items-center justify-end p-6 rounded-b">
                            <button
                                type="button"
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                onClick={closeModal}
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="bg-green-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            >
                                Salvar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditUserModal;
