import { shuffle } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import boardsSlice from "../redux/boardsSlice";
import Task from "./Task";
import AxiosService from "../axios";
import { useFetchAtividadeQuery } from "../redux/ApiSlice"; // Replace with the correct path to your boardsSlice file
import utils from "../utils";
import TaskLead from "./TaskLead";
import { Store } from "react-notifications-component";
import TaskFinanciamento from "./TaskFinanciamento";
import ArrowButton from "./ArrowButton";
import TaskOff from "./TaskOff";
import TaskDistribuidora from "./TaskDistribuidora";

function Column({ colIndex, board, onChange, onModalProject }) {
	const colors = [
		"bg-red-500",
		"bg-orange-500",
		"bg-blue-500",
		"bg-purple-500",
		"bg-green-500",
		"bg-indigo-500",
		"bg-yellow-500",
		"bg-pink-500",
		"bg-sky-500",
	];
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.auth.user);
	const setToken = (token) => {
		AxiosService.setAuthorizationToken(token);
	};
	const [reOrderBydate, setReOrderByDate] = useState([]);
	const [color, setColor] = useState(null);
	const [atividadeLead, setAtividadeLead] = useState([]);
	const [atividadeFinan, setAtividadeFinan] = useState([]);
	const [atividadeOff, setAtividadeOff] = useState([]);
	const onChangeProject = () => {
		onChange();
		onModalProject();
	};

	useEffect(() => {
		setColor(shuffle(colors).pop());
		setToken(token);
	}, []);
	const {
		isLoading: isLoadingAtividade,
		isError: isErrorAtividade,
		isSuccess: isSuccessAtividade,
		data: atividadeData,
	} = useFetchAtividadeQuery();

	const col = board.columns.find((col, i) => i === colIndex);

	const handleOnDrop = (e) => {
		e.preventDefault();
		setToken(token);
		const data = JSON.parse(e.dataTransfer.getData("text/plain"));
		onChange();
		if (board.id === 1) {
			try {
				if (data.colIndex === 0 && data.revenda == null) {
					Store.addNotification({
						title: "Erro!",
						message:
							"Para mover um lead, para etapas acima, primeiro precisa cadastrar a sua revenda!",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
							onScreen: true,
						},
					});
					return;
				}
				if (data.colIndex != colIndex) {
					const endpoint = `/lead/updateLeadStatus/${data.taskIndex}`;
					const postData = {
						statusLead: colIndex == 4 ? 5 : colIndex == 5 ? 4 : colIndex, // Other fields as needed
					};

					AxiosService.post(endpoint, postData)
						.then((response) => {
							// Handle successful response data
						})
						.catch((error) => {
							console.error("POST Request Error:", error);
							// Handle errors
						});

					Store.addNotification({
						title: "Salvo!",
						message: "Atividade salva!",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 2500,
							onScreen: true,
						},
					});
					onChange();
				}
			} catch (error) {
				// Implement logic to handle the drop, e.g., dispatching an action to update the state
				// Example: dispatch(boardsSlice.actions.dragTask(data));
			}
		} else if (board.id === 2) {
			try {
				if (data.colIndex != colIndex) {
					const endpoint = `/financiamento/updateFinanStatus/${data.taskIndex}`;
					const postData = {
						statusLead: colIndex, // Other fields as needed
					};

					AxiosService.post(endpoint, postData)
						.then((response) => {
							Store.addNotification({
								title: "Salvo!",
								message: "Atividade salva!",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 2500,
									onScreen: true,
								},
							});
							// Handle successful response data
						})
						.catch((error) => {
							console.error("POST Request Error:", error);
							Store.addNotification({
								title: "Erro!",
								message: "Erro ao movimentar O financiamento!",
								type: "danger",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 2500,
									onScreen: true,
								},
							});
							// Handle errors
						});

					onChange();
				}
			} catch (error) {
				Store.addNotification({
					title: "Erro!",
					message: "Erro ao movimentar O financiamento!",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 2500,
						onScreen: true,
					},
				});
			}
		} else if (board.id === 3) {
			try {
				if (data.colIndex != colIndex) {
					const endpoint = `/offGrid/updateLeadStatus/${data.taskIndex}`;
					const postData = {
						statusOFF: colIndex, // Other fields as needed
					};

					AxiosService.post(endpoint, postData)
						.then((response) => {
							Store.addNotification({
								title: "Salvo!",
								message: "Atividade salva!",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 2500,
									onScreen: true,
								},
							});
							// Handle successful response data
						})
						.catch((error) => {
							console.error("POST Request Error:", error);
							Store.addNotification({
								title: "Erro!",
								message: "Erro ao movimentar O financiamento!",
								type: "danger",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 2500,
									onScreen: true,
								},
							});
							// Handle errors
						});

					onChange();
				}
			} catch (error) {
				Store.addNotification({
					title: "Erro!",
					message: "Erro ao movimentar O financiamento!",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 2500,
						onScreen: true,
					},
				});
			}
		} else if (board.id === 4) {
			try {
				if (data.colIndex != colIndex) {
					const endpoint = `/distribuidora/updateLeadStatus/${data.taskIndex}`;
					const postData = {
						statusLead: colIndex, // Other fields as needed
					};

					AxiosService.post(endpoint, postData)
						.then((response) => {
							Store.addNotification({
								title: "Salvo!",
								message: "Atividade salva!",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 2500,
									onScreen: true,
								},
							});
							// Handle successful response data
						})
						.catch((error) => {
							console.error("POST Request Error:", error);
							Store.addNotification({
								title: "Erro!",
								message: "Erro ao movimentar O financiamento!",
								type: "danger",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 2500,
									onScreen: true,
								},
							});
							// Handle errors
						});

					onChange();
				}
			} catch (error) {
				Store.addNotification({
					title: "Erro!",
					message: "Erro ao movimentar O financiamento!",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 2500,
						onScreen: true,
					},
				});
			}
		}

		onChange();
	};

	const handleOnDragOver = (e) => {
		e.preventDefault();
	};
	const reorderTasksByDate = () => {
		if (board.id === 0) {
			const sortedTasks = col.tasks.sort((taskA, taskB) => {
				const dateA = new Date(taskA.DATA_CRIACAO_DO_PROJETO);
				const dateB = new Date(taskB.DATA_CRIACAO_DO_PROJETO);
				return dateA - dateB;
			});

			setReOrderByDate({ ...col, tasks: sortedTasks });
		} else if (board.id === 1) {
			const sort = col.tasks.sort((taskA, taskB) => {
				const dateA = new Date(taskA.DATA_REGISTRO);
				const dateB = new Date(taskB.DATA_REGISTRO);
				return dateA - dateB;
			});
			setReOrderByDate({ ...col, tasks: sort });
		} else if (board.id === 2) {
			const sort = col.tasks.sort((taskA, taskB) => {
				const dateA = new Date(taskA.DATA_REGISTRO);
				const dateB = new Date(taskB.DATA_REGISTRO);
				return dateA - dateB;
			});
			setReOrderByDate({ ...col, tasks: sort });
		}
	};
	const reorderNewTasksByDate = () => {
		if (board.id === 0) {
			const sortedTasks = col.tasks.sort((taskA, taskB) => {
				const dateA = new Date(taskA.DATA_CRIACAO_DO_PROJETO);
				const dateB = new Date(taskB.DATA_CRIACAO_DO_PROJETO);
				return dateB - dateA;
			});

			setReOrderByDate({ ...col, tasks: sortedTasks });
		} else if (board.id === 1) {
			const sort = col.tasks.sort((taskA, taskB) => {
				const dateA = new Date(taskA.DATA_REGISTRO);
				const dateB = new Date(taskB.DATA_REGISTRO);
				return dateB - dateA;
			});
			setReOrderByDate({ ...col, tasks: sort });
		} else if (board.id === 2) {
			const sort = col.tasks.sort((taskA, taskB) => {
				const dateA = new Date(taskA.DATA_REGISTRO);
				const dateB = new Date(taskB.DATA_REGISTRO);
				return dateB - dateA;
			});
			setReOrderByDate({ ...col, tasks: sort });
		} else if (board.id === 3) {
			const sort = col.tasks.sort((taskA, taskB) => {
				const dateA = new Date(taskA.DATA_REGISTRO);
				const dateB = new Date(taskB.DATA_REGISTRO);
				return dateB - dateA;
			});
			setReOrderByDate({ ...col, tasks: sort });
		}
	};

	const fetchAtividadeLead = () => {
		const endpoint = "/atividadeLead/";
		AxiosService.get(endpoint)
			.then((response) => {
				setAtividadeLead(response.data);
			})
			.catch((error) => {
				console.error("GET Request Error:", error);
			});
	};
	const fetchAtividadeFinan = () => {
		const endpoint = "/atividadeFinan/";
		AxiosService.get(endpoint)
			.then((response) => {
				setAtividadeFinan(response.data);
			})
			.catch((error) => {
				console.error("GET Request Error:", error);
			});
	};

	useEffect(() => {
		fetchAtividadeLead();
		fetchAtividadeFinan();
	}, []);
		const sumAllTasksValue = () => {
			let totalValue = 0;
			if (board.id === 0) {
				col.tasks.forEach((task) => {
					totalValue += Number(task.VALOR) || 0; // Converta VALOR para número e some
				});
			}
			return utils.formatNumber(totalValue.toFixed(2));
		};
	if (isSuccessAtividade) {
		const orderList = [...atividadeData];

		return (
			<div
				onDrop={handleOnDrop}
				onDragOver={handleOnDragOver}
				className=" overflow-x-auto scrollbar-hide mx-5 pt-[90px] min-w-[360px] "
			>
				<div className="flex flex-row justify-between">
					<p className=" font-semibold flex items-center gap-2 tracking-widest md:tracking-[.1em] text-[#828fa3]">
						<div className={`rounded-full w-4 h-4 ${color} `} />
						{col.name} ({col.tasks.length})
					</p>
					<ArrowButton
						OnDown={reorderTasksByDate}
						onUp={reorderNewTasksByDate}
					/>
				</div>
				{board.id == 0 && (
					<p className="font-semibold flex items-center gap-2 tracking-widest md:tracking-[.1em] text-[#828fa3]">
						Valor total: {sumAllTasksValue()}
					</p>
				)}
				{col.tasks.map((task, index) => {
					if (board.id == 0) {
						if (
							utils.binarySearch(
								orderList.sort((a, b) => a.COD_PROJETO - b.COD_PROJETO),
								task.COD_PROJETO,
							)
						) {
							var list = orderList.filter(
								(item) => item.ID_PROJ == task.ID_PROJ,
							);
							return (
								<Task
									key={index}
									taskIndex={index}
									colIndex={colIndex}
									project={task}
									tasks={list}
									onChange={onChangeProject}
								/>
							);
						} else
							return (
								<Task
									key={index}
									taskIndex={index}
									colIndex={colIndex}
									project={task}
									tasks={[]}
									onChange={onChangeProject}
								/>
							);
					} else if (board.id == 1) {
						if (
							utils.binarySearchLead(
								atividadeLead.sort((a, b) => a.ID_LEAD - b.ID_LEAD),
								task.ID_LEAD,
							)
						) {
							let list = atividadeLead.filter(
								(item) => item.ID_LEAD == task.ID_LEAD,
							);
							return (
								<TaskLead
									key={index}
									taskIndex={index}
									colIndex={colIndex}
									lead={task}
									tasks={list}
									onChange={onChange}
								/>
							);
						} else
							return (
								<TaskLead
									key={index}
									taskIndex={index}
									colIndex={colIndex}
									lead={task}
									tasks={[]}
									onChange={onChange}
								/>
							);
					} else if (board.id == 2) {
						if (
							atividadeFinan.some(
								(item) => item.ID_FINANCIAMENTO == task.ID_FINANCIAMENTO,
							)
								? true
								: false
						) {
							let list = atividadeFinan.filter(
								(item) => item.ID_FINANCIAMENTO == task.ID_FINANCIAMENTO,
							);
							return (
								<TaskFinanciamento
									key={index}
									taskIndex={index}
									colIndex={colIndex}
									project={task}
									tasks={list}
									onChange={onChange}
								/>
							);
						} else {
							return (
								<TaskFinanciamento
									key={index}
									taskIndex={index}
									colIndex={colIndex}
									project={task}
									tasks={[]}
									onChange={onChange}
								/>
							);
						}
					} else if (board.id == 3) {
						if (
							atividadeOff.some((item) => item.ID_OFF == task.ID_OFF)
								? true
								: false
						) {
							let list = atividadeOff.filter(
								(item) => item.ID_OFF == task.ID_OFF,
							);
							return (
								<TaskOff
									key={index}
									taskIndex={index}
									colIndex={colIndex}
									project={task}
									tasks={list}
									onChange={onChange}
									OFF={task}
								/>
							);
						} else {
							return (
								<TaskOff
									key={index}
									taskIndex={index}
									colIndex={colIndex}
									project={task}
									tasks={[]}
									onChange={onChange}
									OFF={task}
								/>
							);
						}
					} else if (board.id == 4) {
						return (
							<TaskDistribuidora
								key={index}
								taskIndex={index}
								colIndex={colIndex}
								project={task}
								tasks={list}
								onChange={onChange}
								lead={task}
							/>
						);
					}
				})}
			</div>
		);
	}
}

export default Column;
