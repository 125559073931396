import React, { useEffect, useState } from "react";
import AxiosService from "../axios";
import utils from "../utils"
const ProjetosListModal = ({ setIsProjetosListModalOpen, mes, ano }) => {
    const [projects, setProjects] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    const GetProjectList = async () => {
        const response = await AxiosService.get(`faturamentoMesList/${ano}/${mes}`);
        setProjects(response.data);
    };

    useEffect(() => {
        GetProjectList();
    }, [mes, ano]);

    const closeModal = () => setIsProjetosListModalOpen(false);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedProjects = React.useMemo(() => {
        let sortableItems = [...projects];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                let aVal = a[sortConfig.key];
                let bVal = b[sortConfig.key];

                // If sorting by "VALOR" or "POTENCIA", parse the string as float for correct numeric sorting
                if (sortConfig.key === 'VALOR' || sortConfig.key === 'POTENCIA') {
                    aVal = parseFloat(aVal.replace(/[$,]/g, ''));
                    bVal = parseFloat(bVal.replace(/[$,]/g, ''));
                }

                if (aVal < bVal) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aVal > bVal) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [projects, sortConfig]);

    const totalValor = sortedProjects.reduce((acc, project) => {
        const valor = Number(project.VALOR.replace(/[$,]/g, ""));
        return acc + valor;
    }, 0);


    return (
        <div className="fixed inset-0 z-50 overflow-auto flex dropdown">
            <div className="relative p-8 bg-white dark:bg-[#2b2c37] dark:text-white w-full max-w-5xl m-auto flex-col flex rounded-lg overflow-hidden">
                <div className="flex justify-end">
                    <button onClick={closeModal} className="cursor-pointer text-xl z-50">
                        <svg className="fill-current text-black dark:text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/>
                        </svg>
                    </button>
                </div>
                <h1 className="text-bold text-3xl text-center font-medium mb-2">
                    Projetos do Mês {mes}
                </h1>

                <div className="overflow-auto max-h-[500px] bg-white dark:bg-[#20212c] custom-scrollbar rounded-lg">
                    <table className="w-full divide-y divide-gray-200 dark:divide-gray-500 rounded-lg">
                        <thead className="bg-gray-50 dark:bg-gray-900 rounded-lg">
                            <tr>
                                <th onClick={() => requestSort('COD_PROJETO')} className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    COD_PROJETO
                                </th>
                                <th onClick={() => requestSort('POTENCIA')} className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    POTENCIA
                                </th>
                                <th onClick={() => requestSort('VALOR')} className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    VALOR
                                </th>
                                <th onClick={() => requestSort('REVENDA')} className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    REVENDA
                                </th>
                                <th onClick={() => requestSort('CLIENTE')} className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    CLIENTE
                                </th>
                                <th onClick={() => requestSort('DATA_EMISSAO_NOTA_FISCAL')} className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    DATA DA NOTA
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-gray-500">
                            {sortedProjects.map((project, index) => (
                                <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-700 duration-300 ease-in-out text-black dark:text-gray-500">
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                                        {project.COD_PROJETO}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                                        {project.POTENCIA}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                                        {new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(project.VALOR)}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                                        {project.REVENDA}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                                        {project.CLIENTE}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                                        {utils.formatDate(project.DATA_EMISSAO_NOTA_FISCAL)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="mt-4 bg-gray-100 text-gray-500 dark:bg-gray-900 p-4 rounded-lg">
                    <strong>Total de Valor: </strong>
                    {new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(totalValor)}
                </div>
            </div>
        </div>
    );
};

export default ProjetosListModal;
