import React, { useState } from "react";
import Logo from "../assets/LOGO_VOICE_SOLAR-removebg-preview.png";
import iconDown from "../assets/icon-chevron-down.svg";
import iconUp from "../assets/icon-chevron-up.svg";
import elipsis from "../assets/icon-vertical-ellipsis.svg";
import HeaderDropDown from "./HeaderDropDown";
import ElipsisMenu from "./ElipsisMenu";
import AddEditTaskModal from "../modals/AddEditTaskModal";
import AddEditBoardModal from "../modals/AddEditBoardModal";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../modals/DeleteModal";
import boardsSlice from "../redux/boardsSlice";
import { useFetchDataFromApiQuery } from "../redux/ApiSlice"; // Replace with the correct path to your boardsSlice file
import AddEditLeadModal from "../modals/AddEditLeadModal";
import DashModal from "../modals/DashModal";
import AddEmpresaModal from "../modals/AddEmpresaModal";
import ElipsisMenuHeader from "./ElipsisMenuHeader";
import EditUserModal from "../modals/EditUserModal";
import ExpiringLocalStorage from "../ExpiringLocalStorage";
import UserRevendasModal from "../modals/UserRevendasModal";

function Header({ setIsBoardModalOpen, isBoardModalOpen }) {
	const { isLoading, isError, isSuccess, data, isUninitialized } =
		useFetchDataFromApiQuery();

	const [openDropdown, setOpenDropdown] = useState(false);
	const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
	const [boardType, setBoardType] = useState("add");
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
	const [isLeadModalOpen, setIsLeadModalOpen] = useState(false);
	const [isDashModalOpen, setIsDashModalOpen] = useState(false);
	const [isEmpresaModalOpen, setIsEmpresaModalOpen] = useState(false);
	const [isUserModalOpen, setIsUserModalOpen] = useState(false);
	const [isUserRevendaModalOpen, setIsUserRevendaModalOpen] = useState(false);

	if (isLoading || isUninitialized) {
		return <p>Carregando</p>;
	}
	if (isLoading || isUninitialized) {
		return <p>Carregando</p>;
	}
	if (isSuccess) {
		const boards = data.boards;

		const board = boards.find((board) => board.isActive == true);

		const onDropdownClick = () => {
			setOpenDropdown((state) => !state);
			setIsElipsisMenuOpen(false);
			setBoardType("add");
		};

		const setOpenEditModal = () => {
			setIsBoardModalOpen(true);
			setIsElipsisMenuOpen(false);
		};
		const setOpenEmpresaModal = () => {
			setIsEmpresaModalOpen(true);
			setIsElipsisMenuOpen(false);
		};
		const setOpenUserModal = () => {
			setIsUserModalOpen(true);
			setIsElipsisMenuOpen(false);
		};
		const setOpenUserRevendaModal = () => {
			setIsUserRevendaModalOpen(true);
			setIsElipsisMenuOpen(false);
		};

		const setOpenDashModal = () => {
			setIsDashModalOpen(true);
			setIsElipsisMenuOpen(false);
		};
		const setOpenDeleteModal = () => {
			setIsDeleteModalOpen(true);
			setIsElipsisMenuOpen(false);
		};

		const onDeleteBtnClick = (e) => {
			if (e.target.textContent === "Delete") {
				setIsDeleteModalOpen(false);
			} else {
				setIsDeleteModalOpen(false);
			}
		};
		const handleButtonClick = () => {
			// Add functionality for the button click event
			console.log("Button clicked!");
		};
		return (
			<div className=" p-4 fixed left-0 bg-white dark:bg-[#2b2c37] z-50 right-0 ">
				<header className=" flex justify-between dark:text-white items-center  ">
					{/* Left Side  */}
					<div className=" flex flex-row items-center text-center">
						<img src={Logo} alt=" Logo " className="h-12 w-24 object-contain object-center	" />
					
						<div className=" flex items-center ">
					
							<img
								src={openDropdown ? iconUp : iconDown}
								alt=" dropdown icon"
								className=" w-3 ml-2 md:hidden"
								onClick={onDropdownClick}
							/>
						</div>
					</div>

					<div className=" flex space-x-4 items-center md:space-x-6 ">
						<button
							onClick={() => {
								setOpenDashModal();
							}}
							className=" text-white px-4 py-2 rounded-md bg-gradient-to-l from-[#fae24d] to-[#ed772f] hover:opacity-80 focus:outline-none focus:ring focus:border-blue-300"
						>
							Dashboard
						</button>
						<img
							onClick={() => {
								setIsElipsisMenuOpen(true);
							}}
							src={elipsis}
							alt="elipsis"
							className=" cursor-pointer h-6"
						/>
						{isElipsisMenuOpen && (
							<ElipsisMenuHeader
								setOpenEmpresaModal={setOpenEmpresaModal}
								setOpenUserModal={setOpenUserModal}
								setOpenEditModal={setOpenEditModal}
								setOpenDeleteModal={setOpenDeleteModal}
								setOpenUserRevendaModal={setOpenUserRevendaModal}
							/>
						)}
					</div>

					{openDropdown && (
						<HeaderDropDown
							setOpenDropdown={setOpenDropdown}
							setIsBoardModalOpen={setIsBoardModalOpen}
						/>
					)}
				</header>

				{isLeadModalOpen && (
					<AddEditLeadModal
						setIsAddTaskModalOpen={setIsLeadModalOpen}
						type="add"
						device="mobile"
					/>
				)}
				{/* {isTaskModalOpen && (
					<AddEditTaskModal
						setIsAddTaskModalOpen={setIsTaskModalOpen}
						type="add"
						device="mobile"
					/>
				)}

				{isBoardModalOpen && (
					<AddEditBoardModal
						setBoardType={setBoardType}
						type={boardType}
						setIsBoardModalOpen={setIsBoardModalOpen}
					/>
				)}
				{isDeleteModalOpen && (
					<DeleteModal
						setIsDeleteModalOpen={setIsDeleteModalOpen}
						type="board"
						title={board.name}
						onDeleteBtnClick={onDeleteBtnClick}
					/>
				)} */}

				{isDashModalOpen && (
					<DashModal setIsTaskModalOpen={setIsDashModalOpen} />
				)}
				<AddEmpresaModal
					isOpen={isEmpresaModalOpen}
					setIsOpen={setIsEmpresaModalOpen}
				/>
				<EditUserModal
					isOpen={isUserModalOpen}
					setIsOpen={setIsUserModalOpen}
					currentUser={ExpiringLocalStorage.getWithExpiry("user")}
				/>
				<UserRevendasModal
					isOpen={isUserRevendaModalOpen}
					setIsOpen={setIsUserRevendaModalOpen}
					currentUser={ExpiringLocalStorage.getWithExpiry("user")}
				/>
			</div>
		);
	}
}

export default Header;
