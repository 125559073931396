import React, { useEffect, useState, useCallback } from "react";
import { MdAddTask, MdDeleteOutline, MdPerson, MdShield } from "react-icons/md";
import AxiosService from "../axios";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

const ExpiredCheck = ({ idProj, load }) => {
	const [expired, setExpired] = useState(false);
	const [complet, setComplet] = useState(false);
	const [valorAlto, setValorAlto] = useState(false);
	const [semLimite, setSemLimite] = useState(false);
	const [esqueceu, setEsqueceu] = useState(false);
	const [desistiu, setDeisistiu] = useState(false);
	const [retorno, setRetorno] = useState(false);
	const [concorrente, setConcorrente] = useState(false);
	const [materialFaltando, setMaterialFaltando] = useState(false);
	const [MaoDeObraSeparada, setMaoDeObraSeparada] = useState(false);
	const [novo, setNovo] = useState(false);
	const [aguardando, setAguardando] = useState(false);
	const [checked, setChecked] = useState(false);
	const [idExpirado, setExpirado] = useState(false);
	const [financiamentoReprovado,setReprovado]= useState(false);
	const [semIntencao,setSemIntencao]= useState(false);
	const [description, setDescription] = useState("");
	const [type, setType] = useState("add");

	const checkIfExist = async () => {
		"";
		const endpoint = `/findByProjId/${idProj}`;
		const result = await AxiosService.get(endpoint);

		try {
			setValorAlto(result.data.VALOR_ALTO != 1 ? false : true);
			setDeisistiu(result.data.DESISTIU != 1 ? false : true);
			setConcorrente(result.data.CONCORRENTE != 1 ? false : true);
			setEsqueceu(result.data.ESQUECEU != 1 ? false : true);
			setMaoDeObraSeparada(
				result.data.MAO_DE_OBRA_SEPARADA != 1 ? false : true,
			);
			setMaterialFaltando(result.data.MATERIAL_FALTANDO != 1 ? false : true);
			setNovo(result.data.NOVO_PROJETO != 1 ? false : true);
			setSemLimite(result.data.SEM_LIMITE != 1 ? false : true);
			setRetorno(result.data.RETORNO != 1 ? false : true);
			setExpirado(result.data.ID_EXPIRADO);
			setDescription(result.data.OBSERVACOES);
			setAguardando(result.data.AGUARDANDO != 1 ? false : true);
			setReprovado(result.data.FINANCIAMENTO_REPROVADO != 1 ? false : true);
			setSemIntencao(result.data.SEM_INTENCAO != 1 ? false : true);

			if (result.data.VALOR_ALTO != null) setType("edit");
		} catch (error) {
			// Handle error state or display the error message
			console.error("Error occurred:", error.response.data.message);
			Store.addNotification({
				title: "Erro!",
				message: error.response.data.message,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 2500,
					onScreen: true,
				},
			});
		}
	};

	const onCreatePipe = async () => {
		// Prepare your payload here (title, description, contact, contactType, date)

		try {
			const payload = {
				valorAlto,
				semLimite,
				esqueceu,
				desistiu,
				concorrente,
				materialFaltando,
				MaoDeObraSeparada,
				retorno,
				idProj,
				novo,
				aguardando,
				description,
				financiamentoReprovado,
				semIntencao
			};

			if (type != "edit") {
				const endpoint = "/createExpired";

				const result = await AxiosService.post(endpoint, payload);
				if (result) {
					Store.addNotification({
						title: "Sucesso!",
						message: result.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 2500,
							onScreen: true,
						},
					});
				}
			} else {
				const endpoint = `/updateExpired/${idExpirado}`;

				const result = await AxiosService.post(endpoint, payload);
				if (result) {
					Store.addNotification({
						title: "Sucesso!",
						message: result.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 2500,
							onScreen: true,
						},
					});
				}
			}

			// Handle success, reset states, or show success message
		} catch (error) {
			// Handle error state or display the error message
			console.error("Error occurred:", error.response.data.message);
			Store.addNotification({
				title: "Erro!",
				message: error.response.data.message,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 2500,
					onScreen: true,
				},
			});
		}
	};
	useEffect(() => {
		const loading = async () => {
			if (load) {
				await checkIfExist();
			}
		};
		loading();
	}, []);
	return (
		<div className="flex flex-col w-full space-y-3 items-center justify-center text-center">
			<h1 className=" text-xl dark:text-white text-gray-500 text-center">
				Motivos
			</h1>
			<div className="rounded-lg shadow-md items-center justify-center text-center  rounded-lg w-3/4 bg-[#f4f7fd]  dark:bg-[#20212c] shadow-[#364e7e1a] py-3  px-3 shadow-lg dark:text-white  hover:scale-105 cursor-pointer   duration-500 ease-in-out">
				<div className="flex flex-row text-center  justify-stretch">
					<div className="flex flex-row relative text-center justify-center py-2 hover:text-[#3A007E] duration-500 ease-in-out">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={valorAlto}
							onChange={(e) => setValorAlto(e.target.checked)}
						/>

						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Sem limite no cartão.
						</span>
					</div>
					<div className="flex flex-row relative items-center  text-center justify-center py-2 pl-14 hover:text-[#3A007E] duration-500 ease-in-out ">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={semLimite}
							onChange={(e) => setSemLimite(e.target.checked)}
						/>
						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide  text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Valor muito alto.
						</span>
					</div>
				</div>
				<div className="flex flex-row text-center justify-stretch">
					<div className="flex flex-row relative items-center justify-content text-center py-2 hover:text-[#3A007E] duration-500 ease-in-out ">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={esqueceu}
							onChange={(e) => setEsqueceu(e.target.checked)}
						/>

						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide  text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Esqueceu do orçamento.
						</span>
					</div>
					<div className="flex flex-row relative items-center justify-content text-center py-2 pl-9 hover:text-[#3A007E] duration-500 ease-in-out">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={desistiu}
							onChange={(e) => setDeisistiu(e.target.checked)}
						/>

						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide  text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Desistiu.
						</span>
					</div>
				</div>
				<div className="flex flex-row text-center justify-stretch">
					<div className="flex flex-row relative items-center justify-content text-center py-2 hover:text-[#3A007E]  duration-500 ease-in-out">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={concorrente}
							onChange={(e) => setConcorrente(e.target.checked)}
						/>

						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide  text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Fechou com concorrente.
						</span>
					</div>
					<div className="flex flex-row relative items-center justify-content text-center py-2 pl-8 hover:text-[#3A007E] duration-500 ease-in-out">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={materialFaltando}
							onChange={(e) => setMaterialFaltando(e.target.checked)}
						/>
						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide  text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Material faltandos.
						</span>
					</div>
				</div>
				<div className="flex flex-row text-center justify-stretch">
					<div className="flex flex-row relative items-center justify-content text-center py-2 hover:text-[#3A007E] duration-500 ease-in-out">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={MaoDeObraSeparada}
							onChange={(e) => setMaoDeObraSeparada(e.target.checked)}
						/>

						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide  text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Valor mão de obra separada.
						</span>
					</div>
					<div className="flex flex-row relative items-start justify-start text-start py-2  pl-2 hover:text-[#3A007E] duration-500 ease-in-out">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={novo}
							onChange={(e) => setNovo(e.target.checked)}
						/>

						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide  text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Novo projeto.
						</span>
					</div>
				</div>
				<div className="flex flex-row text-center justify-stretch">
					<div className="flex flex-row relative items-start justify-start text-start py-2  hover:text-[#3A007E] duration-500 ease-in-out">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={aguardando}
							onChange={(e) => setAguardando(e.target.checked)}
						/>

						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide  text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Aguardando Financiamento.
						</span>
					</div>

					<div className="flex flex-row relative items-center justify-content text-center py-2 pl-3 hover:text-[#3A007E]  duration-500 ease-in-out">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={retorno}
							onChange={(e) => setRetorno(e.target.checked)}
						/>

						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide  text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Vai ter retorno?
						</span>
					</div>
				</div>

				<div className="flex flex-row text-center justify-stretch">
					<div className="flex flex-row relative items-start justify-start text-start py-2  hover:text-[#3A007E] duration-500 ease-in-out">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={financiamentoReprovado}
							onChange={(e) => setReprovado(e.target.checked)}
						/>

						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide  text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Financimento Reprovado.
						</span>
					</div>

					<div className="flex flex-row relative items-center justify-content text-center py-2 pl-4 hover:text-[#3A007E]  duration-500 ease-in-out">
						<input
							className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
							type="checkbox"
							checked={semIntencao}
							onChange={(e) => setSemIntencao(e.target.checked)}
						/>

						<span
							className={
								checked != true
									? "font-bold tracking-wide text-sm"
									: "font-bold tracking-wide  text-center justify-center text-sm line-through opacity-30 "
							}
						>
							Sem intenção de compra.
						</span>
					</div>
				</div>

				<div className="mt-2 flex flex-col hover:text-[#3A007E] ">
					<textarea
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						id="project-description-input"
						className=" bg-transparent outline-none hover:text-[#3A007E] min-h-[50px] focus:border-0 px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-[1px] focus:scale-105 hover:text-[#3A007E] duration-500 ease-in-out "
						placeholder="Observações "
					/>
				</div>
				<div className="flex flex-col space-y-1 items-center mt-2">
					<button
						onClick={() => {
							onCreatePipe();
						}}
						className="flex items-center justify-center text-center w-1/2 text-white bg-gradient-to-l  from-[#30214b] to-[#181224] py-2 rounded-lg hover:scale-105 duration-500 ease-in-out "
					>
						<MdAddTask className="mr-2" />

						{"Salvar"}
					</button>
				</div>
			</div>
			{/* Description */}
		</div>
	);
};

export default ExpiredCheck;
