import TasksTable from "./TasksTable";

function ColumnComponent({ columnData,colIndex,onChange,colName }) {
    return (
      <div>
        {/* Render the column data here */}
        <h2>{colName}</h2>
        {/* Assume columnData.tasks is an array of task data for this column */}
       
		<TasksTable tasks={columnData.tasks} colIndex={colIndex} onChange={onChange} />
      </div>
    );
  }

  export default ColumnComponent;