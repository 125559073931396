import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TaskLeadModal from "../modals/TaskLeadModal";
import {
	useFetchDataFromApiQuery,
	useFetchAtividadeQuery,
} from "../redux/ApiSlice"; // Replace with the correct path to your boardsSlice file
import { MdTimer } from "react-icons/md";
import AxiosService from "../axios";
import { shuffle } from "lodash";
import TaskDistribuidoraModal from "../modals/TaskDistribuidoraModal";

function TaskDistribuidora({ colIndex, taskIndex, lead, tasks, onChange }) {
	const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
	const [isExpiredApi, setExpiredApi] = useState(false);
	const [isExpiringApi, setExpiringApi] = useState(false);
	const [haveAtividade, setHaveAtividade] = useState(false);
	const [lastUser, setLastUser] = useState(false);

	const colors = [
		"bg-red-500",
		"bg-orange-500",
		"bg-blue-500",
		"bg-purple-500",
		"bg-green-500",
		"bg-indigo-500",
		"bg-yellow-500",
		"bg-pink-500",
		"bg-sky-500",
	];

	const [color, setColor] = useState(null);
	const handleOnDrag = (e) => {
		console.log(e);
	};

	function abbreviateName(name) {
		const words = name.split(" ");
		const initials = words.map((word) => word[0].toUpperCase()).join("");
		return initials;
	}

	
	const today = new Date();
	// // Assuming 'data' is an array of tasks or items with a 'dueDate' field

	const fetchUpdatedTaskList = async () => {
		try {
			const ID_LEAD = lead.ID_DISTRIBUIDORA;
			let count = 0;

			if (tasks.length > 0) {
				const response = await AxiosService.get(
					`atividadeDistribuidora/find/?conditions[]=ID_LEAD&values[]=${ID_LEAD}&conditions[]=ID_REVENDA&values[]=null`,
				);
				const greatestIdActivityObject = response.data.reduce(
					(prev, current) => {
						return prev.ID_ATIVIDADE > current.ID_ATIVIDADE ? prev : current;
					},
				);
				setLastUser(abbreviateName(greatestIdActivityObject.NOME_USUARIO));
				response.data.some((item) => {
					const dueDate = new Date(item.DATA_RETORNO);
					if (item.CONFERIDO == 0) {
						if (dueDate < today) {
							setExpiredApi(true);
							count++;

							return true;
						} else setExpiredApi(false);
						count++;
					}
				});
				response.data.some((item) => {
					const dueDate = new Date(item.DATA_RETORNO); // Replace 'dueDate' with your actual due date field name
					if (item.CONFERIDO == 0) {
						if (dueDate.toDateString() === today.toDateString()) {
							setExpiringApi(true);
							count++;

							return true;
						} else setExpiringApi(false);
						count++;
					}
				});
				response.data.some(async (item) => {
					if (item.CONFERIDO == 0) {
						setHaveAtividade(true);
						await checkExp();
						count++;
					}
				});
				if (count === 0) {
					setHaveAtividade(false);
					setExpiringApi(false);
					setExpiredApi(false);
				}
			} else {
				setLastUser(abbreviateName(lead.NOME_USUARIO));
			}
		} catch (error) {
			console.log(lead);
			setLastUser(abbreviateName(lead.NOME_USUARIO));
			console.error("Error fetching updated lead list:", error);
		}
	};

	const checkExp = async () => {
		if (isExpiredApi && isExpiringApi && haveAtividade) {
			setExpiringApi(false);
			setHaveAtividade(false);
		} else if (isExpiredApi && haveAtividade) {
			setHaveAtividade(false);
		} else if (isExpiringApi && haveAtividade) {
			setHaveAtividade(false);
		} else if (haveAtividade) {
			setHaveAtividade(true);
		} else {
			setHaveAtividade(true);
		}
	};
	const getData = async () => {
		if (tasks.length > 0) {
			const greatestIdActivityObject = tasks.reduce((prev, current) => {
				return prev.ID_ATIVIDADE > current.ID_ATIVIDADE ? prev : current;
			});
			setLastUser(abbreviateName(greatestIdActivityObject.NOME));
			tasks.some((item) => {
				if (item.CONFERIDO == 0) {
					const dueDate = new Date(item.DATA_RETORNO); // Replace 'dueDate' with your actual due date field name
					setHaveAtividade(true);

					if (dueDate < today) {
						setExpiredApi(true);

						return true;
					}

					if (dueDate.toDateString() === today.toDateString())
						setExpiringApi(true);
				}

				// Add 'isExpired' property to the item for further use
			});
		} else {
			setLastUser(abbreviateName(lead.NOME_USUARIO));
		}
	};
	useEffect(() => {
		fetchUpdatedTaskList();
	}, [tasks]);

	useEffect(() => {
		setColor(shuffle(colors).pop());
	}, []);

	const handleDragStart = (e, colIndex, taskIndex, revenda) => {
		e.dataTransfer.setData(
			"text/plain",
			JSON.stringify({ colIndex, taskIndex, revenda }),
		);
	};
	return (
		<div>
			<div
				draggable
				onClick={() => {
					setIsTaskModalOpen(true);
				}}
				onDragStart={(e) =>
					handleDragStart(e, colIndex, lead.ID_DISTRIBUIDORA)
				}
				className=" w-[320px] first:my-5 ml-2 rounded-lg relative  bg-white  dark:bg-[#2b2c37] shadow-[#364e7e1a] py-6 px-3 shadow-lg hover:text-[#3A007E] dark:text-white dark:hover:text-[#3A007E] cursor-pointer hover:scale-105  duration-500 ease-in-out"
			>
				<p className=" font-bold tracking-wide ">{lead.NOME}</p>

				<p className=" font-bold text-xs tracking-tighter mt-1 text-gray-500">
					Email: {lead.EMAIL}
				</p>
				<p className=" font-bold text-xs tracking-tighter mt-1 text-gray-500 ">
					Telefone: {lead.FONE}
				</p>
				<p className=" font-bold text-xs tracking-tighter mt-1 text-gray-500">
					Cidade: {lead.CIDADE}
				</p>

				{/* <p className=" font-bold text-xs tracking-tighter mt-2 text-gray-500">
          {task.ID_REVENDA} 
        </p> */}
				{lastUser != false && (
					<div
						className={`absolute text-center justify-center top-2 right-10 w-7 h-7 font-bold rounded-full p-2 ${color}`}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<p className="text-xs text-gray-700 p-2">{lastUser}</p>
					</div>
				)}

				{haveAtividade && (
					<MdTimer className="absolute top-2 right-2 w-7 h-7 font-bold text-gray-400	" />
				)}
				{isExpiringApi && (
					<MdTimer className="absolute top-2 right-2 w-7 h-7  font-bold text-amber-400	" />
				)}
				{isExpiredApi && (
					<MdTimer className="absolute top-2 right-2 w-7 h-7 font-bold text-red-700 " />
				)}
			</div>

			{isTaskModalOpen && (
				<TaskDistribuidoraModal
					colIndex={colIndex}
					taskIndex={taskIndex}
					lead={lead}
					setIsTaskModalOpen={setIsTaskModalOpen}
					onCloseModal={() => {
						onChange();
						fetchUpdatedTaskList();
					}}
				/>
			)}
		</div>
	);
}

export default TaskDistribuidora;
