import React, { useEffect, useState } from "react";
import AxiosService from "../axios";
import utils from "../utils";
import * as XLSX from "xlsx"; // Make sure to install xlsx package

const FinanceiroList21Modal = ({ setIsProjetosListModalOpen, mes, ano }) => {
	const [projects, setProjects] = useState([]);
	const [totalValorConf, setTotalValorConf] = useState(0);
	const [totalConf,setTotalConf]=useState(0);
	const [sortConfig, setSortConfig] = useState({
		key: null,
		direction: "ascending",
	});

	const GetProjectList = async () => {
		const response = await AxiosService.get(`faturamentoMesIntelbrasList/${ano}/${mes}`);
		const dataWithCheckedStatus = response.data.map((project) => ({
			...project,
			isChecked: project.CONFERIDO === 1, // Assuming 'CONFERIDO' is returned as 0 or 1 from your database
		}));
		setProjects(dataWithCheckedStatus);
	};

	useEffect(() => {
		GetProjectList();
	}, [mes, ano]);
	useEffect(() => {
		const countConferidos = projects.reduce((acc, project) => {
			// Increment the counter if CONFERIDO is equal to 1
			if (project.CONFERIDO === 1) {
				return acc + 1;
			} else {
				return acc;
			}
		}, 0);
		const newTotalValorConf = projects.reduce((acc, project) => {
			if (project.CONFERIDO === 1 || project.isChecked) {
				const valor = Number(project.VALOR.replace(/[$,]/g, ""));
				const porcent=Number(project.PERCENTUAL_DISTRIBUIDOR.replace(/[$,]/g, ""));
				const valorDist = valor*(porcent/100);
				return acc + valorDist;
			} else {
				return acc;
			}
		}, 0);
		setTotalValorConf(newTotalValorConf);
		setTotalConf(countConferidos);

	}, [projects]);

	const closeModal = () => setIsProjetosListModalOpen(false);

	const requestSort = (key) => {
		let direction = "ascending";
		if (sortConfig.key === key && sortConfig.direction === "ascending") {
			direction = "descending";
		}
		setSortConfig({ key, direction });
	};

	const toggleCheckbox = (index) => {
		const updatedProjects = [...projects];
		updatedProjects[index].isChecked = !updatedProjects[index].isChecked;
		console.log(Number(updatedProjects[index].isChecked));
		AxiosService.post(`updateCheckProject/`, {
			ID_PROJ: updatedProjects[index].ID_PROJ,
			completada: Number(updatedProjects[index].isChecked),
		}).catch((error) => console.error("Error updating project:", error));
		// Here you can also call an API to update the 'CONFERIDO' status in your database
		setProjects(updatedProjects);
	};

	const sortedProjects = React.useMemo(() => {
		let sortableItems = [...projects];
		if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
				let aVal = a[sortConfig.key];
				let bVal = b[sortConfig.key];

				// If sorting by "VALOR" or "POTENCIA", parse the string as float for correct numeric sorting
				if (
					sortConfig.key === "VALOR" ||
					sortConfig.key === "POTENCIA" ||
					sortConfig.key === "VALOR_DISTRIBUIDOR" ||
					sortConfig.key === "PERCENTUAL_DISTRIBUIDOR"
				) {
					aVal = parseFloat(aVal.replace(/[$,]/g, ""));
					bVal = parseFloat(bVal.replace(/[$,]/g, ""));
				}
				if (sortConfig.key === "CONFERIDO") {
					aVal = a.isChecked ? 1 : 0; // Assuming isChecked reflects CONFERIDO
					bVal = b.isChecked ? 1 : 0;
				}
				if (aVal < bVal) {
					return sortConfig.direction === "ascending" ? -1 : 1;
				}
				if (aVal > bVal) {
					return sortConfig.direction === "ascending" ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableItems;
	}, [projects, sortConfig]);

	const totalValor = sortedProjects.reduce((acc, project) => {
		const valor = Number(project.VALOR.replace(/[$,]/g, ""));
		return acc + valor;
	}, 0);

	const totalValorDist = sortedProjects.reduce((acc, project) => {
		const valor = Number(project.VALOR.replace(/[$,]/g, ""));
		const porcent=Number(project.PERCENTUAL_DISTRIBUIDOR.replace(/[$,]/g, ""));
		const valorDist = valor*(porcent/100);
		return acc + valorDist;
	}, 0);

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.onload = (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: "array" });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const json = XLSX.utils.sheet_to_json(worksheet);
			checkProjectsFromExcel(json);
		};
		reader.readAsArrayBuffer(file);
	};

	const checkProjectsFromExcel = (excelProjects) => {
		const updatedProjects = projects.map((project) => {
			// Compare the 'PROJETO' field from Excel with 'COD_PROJETO' in your project list
			const found = excelProjects.some((excelProject) => 
            excelProject[" PROJETO "] === project.COD_PROJETO && 
            excelProject[" Status "].includes("Liberado")
        );
			// Update the 'CONFERIDO' status in your database
			if (found) {
			AxiosService.post(`updateCheckProject/`, {
				ID_PROJ: project.ID_PROJ,
				completada: Number(true),
			}).catch((error) => console.error("Error updating project:", error));
			}
			return found ? { ...project, isChecked: true } : project;
		});
	
		setProjects(updatedProjects);
	};
	return (
		<div className="fixed inset-0 z-60 overflow-auto flex dropdown">
			<div className="relative p-8 bg-white dark:bg-[#2b2c37] dark:text-white w-full max-w-5xl m-auto flex-col flex rounded-lg overflow-hidden">
				<div className="flex justify-end">
					<button onClick={closeModal} className="cursor-pointer text-xl z-50">
						<svg
							className="fill-current text-black dark:text-white"
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 18 18"
						>
							<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
						</svg>
					</button>
				</div>
				<h1 className="text-bold text-3xl text-center font-medium mb-2">
					Projetos do Mês {mes}
				</h1>

				<div className="overflow-auto max-h-[500px] bg-white dark:bg-[#20212c] custom-scrollbar rounded-lg">
					<table className="w-full divide-y divide-gray-200 dark:divide-gray-500 rounded-lg">
						<thead className="bg-gray-50 dark:bg-gray-900 rounded-lg">
							<tr>
								<th
									onClick={() => requestSort("CONFERIDO")}
									className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									CONFERIDO
								</th>
								<th
									onClick={() => requestSort("COD_PROJETO")}
									className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									COD_PROJETO
								</th>
								<th
									onClick={() => requestSort("POTENCIA")}
									className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									POTENCIA
								</th>
								<th
									onClick={() => requestSort("VALOR")}
									className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									VALOR
								</th>
								<th
									onClick={() => requestSort("PERCENTUAL_DISTRIBUIDOR")}
									className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									PERCENTUAL
								</th>
								<th
									onClick={() => requestSort("VALOR_DISTRIBUIDOR")}
									className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									VALOR DISTRIBUIDOR
								</th>
								<th
									onClick={() => requestSort("REVENDA")}
									className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									REVENDA
								</th>
								<th
									onClick={() => requestSort("CLIENTE")}
									className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									CLIENTE
								</th>
								<th
									onClick={() => requestSort("DATA_EMISSAO_NOTA_FISCAL")}
									className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									DATA DA NOTA
								</th>
								<th
									onClick={() => requestSort("NF_PROJETO")}
									className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									NF_PROJETO
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200 dark:divide-gray-500">
							{sortedProjects.map((project, index) => (
								<tr
									key={index}
									className={`hover:bg-gray-100 dark:hover:bg-gray-700 text duration-300 ease-in-out ${
										project.isChecked
											? "bg-green-300 dark:bg-green-700 dark:text-white"
											: "text-black dark:text-gray-500"
									}`}
								>
									<td className="px-6 py-4 whitespace-nowrap text-xs ">
										<input
											type="checkbox"
											checked={project.isChecked}
											onChange={() => toggleCheckbox(index)}
										/>
									</td>
									<td className="px-6 py-4 whitespace-nowrap text-xs ">
										{project.COD_PROJETO}
									</td>
									<td className="px-6 py-4 whitespace-nowrap text-xs ">
										{project.POTENCIA}
									</td>
									<td className="px-6 py-4 whitespace-nowrap text-xs ">
										{new Intl.NumberFormat("pt-BR", {
											style: "currency",
											currency: "BRL",
										}).format(project.VALOR)}
									</td>
									<td className="px-6 py-4 whitespace-nowrap text-xs ">
										{project.PERCENTUAL_DISTRIBUIDOR}
									</td>
									<td className="px-6 py-4 whitespace-nowrap text-xs">
										{new Intl.NumberFormat("pt-BR", {
											style: "currency",
											currency: "BRL",
										}).format(project.VALOR_DISTRIBUIDOR)}
									</td>
									<td className="px-6 py-4 whitespace-nowrap text-xs ">
										{project.REVENDA}
									</td>
									<td className="px-6 py-4 whitespace-nowrap text-xs ">
										{project.CLIENTE}
									</td>
									<td className="px-6 py-4 whitespace-nowrap text-xs">
										{utils.formatDate(project.DATA_EMISSAO_NOTA_FISCAL)}
									</td>
									<td className="px-6 py-4 whitespace-nowrap text-xs ">
										{project.NF_PROJETO}
									</td>


								</tr>
							))}
						</tbody>
					</table>
				</div>

				<div className="mt-4 bg-gray-100 text-gray-500 dark:bg-gray-900 p-4 rounded-lg">
					<strong>Total de Valor: </strong>
					{new Intl.NumberFormat("pt-BR", {
						style: "currency",
						currency: "BRL",
					}).format(totalValor)}
					<strong> Comissão: </strong>
					{new Intl.NumberFormat("pt-BR", {
						style: "currency",
						currency: "BRL",
					}).format(totalValorDist)}
					<strong> Conferido: </strong>
					{new Intl.NumberFormat("pt-BR", {
						style: "currency",
						currency: "BRL",
					}).format(totalValorConf)}
					<strong> Total de Conferidos: </strong>
						{totalConf}
				</div>
				<div className="mt-4 flex justify-center items-center mt-2">
					<label
						htmlFor="file-upload"
						className="flex items-center justify-center px-4 py-2 bg-green-500 text-white font-medium rounded-md cursor-pointer hover:bg-blue-700 focus:outline-none focus:shadow-outline"
					>
						<svg
							className="mr-2 h-6 w-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4-4m0 0l-4 4m4-4v12"
							></path>
						</svg>
						Comparar com Excel
					</label>
					<input
						id="file-upload"
						type="file"
						onChange={handleFileUpload}
						accept=".xlsx, .xls"
						className="hidden"
					/>
				</div>
			</div>
		</div>
	);
};

export default FinanceiroList21Modal;
