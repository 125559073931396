import React, { useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import ModalComponent from "./ModalComponent";
import Input from "./Input.tsx";

const DigitalSearchProj = ({ data, onChange, filter, bordid, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  
  const inputRef = useRef(null);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredResults = term
      ? data.columns.flatMap((column) =>
          column.tasks.filter((task) =>
            (task[filter].toLowerCase().includes(term.toLowerCase()) && task.EXPIRADO != "sim" || task.EXPIRADO == undefined)
          )
        )
      : null;
    console.log(filteredResults);
    setSearchResults(filteredResults);
  };

  const handleLeadClick = (lead) => {
    setSelectedLead(lead);
    setIsTaskModalOpen(true);
    setSearchTerm("");
    setSearchResults(null);
  };

  const handleSearchIconClick = () => {
    inputRef.current.focus();
    onSearch(searchTerm, filter);
  };

  return (
    <div className="absolute top-20 stiked rounded-md z-100 hidden md:table duration-500 ease-in-out mt-2">
      <div className="flex items-center hover:scale-105 duration-500 ease-in-out" onClick={handleSearchIconClick}>
        <Input
          type="text"
          labelText="Pesquisar"
          value={searchTerm}
          onChange={handleSearch}
          ref={inputRef}
          className="p-1 md:w-[110px] rounded-md dark:bg-[#2b2c37] bg-white shadow-[#364e7e1a] shadow-lg hover:text-[#3A007E] dark:text-white dark:hover:text-[#3A007E] cursor-pointer"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSearch(searchTerm, filter);
            }
          }}
        />
        <FaSearch className="ml-1 mr-2 text-[#3A007E]" />
      </div>
      <div className="absolute md:w-[125px] bg-white dark:bg-[#2b2c37] duration-500 ease-in-out">
        {searchResults !== null &&
          searchResults.map((result, index) => (
            <div
              key={index}
              onClick={() => handleLeadClick(result)}
              className="text-sm hover:text-[#3A007E] hover:opacity-80 dark:text-white cursor-pointer"
            >
              {result.COD_PROJETO}
            </div>
          ))}
      </div>
      {isTaskModalOpen && selectedLead && (
        <ModalComponent
          lead={selectedLead}
          onClose={() => {
            setIsTaskModalOpen(false);
            setSelectedLead(null);
            setSearchTerm("");
            setSearchResults(null);
          }}
          onChange={onChange}
          bordid={bordid}
        />
      )}
    </div>
  );
};

export default DigitalSearchProj;
