import React, { useEffect, useState, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import AxiosService from "../axios";

import { useFetchDataFromApiQuery } from "../redux/ApiSlice"; // Replace with the correct path to your boardsSlice file
import { Store } from "react-notifications-component";
import ExpiredCheck from "../components/ExpiredCheck";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { MdOutlineSendAndArchive } from "react-icons/md";
import { MdAttachEmail } from "react-icons/md";
import { MdLocationSearching } from "react-icons/md";
import { ImTarget } from "react-icons/im";

import { Bar, Line } from "react-chartjs-2";
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";
import MultipleLineChart from "../components/MultipleLineChart.js";
import PieChart from "../components/PieChart.js";
import HorizontalBarChart from "../components/HorizontalBarChart.js";
import { MdOutlineAttachMoney } from "react-icons/md";
import utils from "../utils.js";
import { GoProjectRoadmap } from "react-icons/go";
import { GiPowerGenerator } from "react-icons/gi";
import { ImPower } from "react-icons/im";
import PieChartPagamento from "../components/PieChartPagamento.js";
import PieChartBanco from "../components/PieChartBanco.js";
import HorizontalBarChartCidade from "../components/HorizontalBarChartCidade.js";
import ProjetosListModal from "./ProjetosListModal.js";
import FinanceiroListModal from "./FinanceiroListModal.js";
import DataQueryForm from "../components/DataQueryForm.js";
import FinanceiroList21Modal from "./FinanceiroList21Modal.js";
import VendaTable from "../components/VendaTable.js";

function DashModal({ setIsTaskModalOpen, onCloseModal }) {
	const dispatch = useDispatch();
	const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isLeadTaskModalOpen, setIsLeadTaskModalOpen] = useState(false);
	const [faturamento, setFaturamento] = useState(0);
	const [ticketMedio, setTicketMedio] = useState(0);
	const [potenciaMes, setPotencia] = useState(0);
	const [projetosCount, setProjetosCount] = useState(0);
	const [FaturamentoAno, setFaturamentoAno] = useState([]);
	const [idRevenda, setRevendaId] = useState();
	const [revenda, setRevenda] = useState();
	const [atividadeList, setAtividadeList] = useState([]);
	const [statusLead, setStatusLead] = useState("");
	const { isError, isSuccess, data, isUninitialized } =
		useFetchDataFromApiQuery();
	const [doughnutData, setDoughnutData] = useState({});
	const [lineChartData, setLineChartData] = useState({});
	const [users, setUser] = useState([]);
	const [Count, setCount] = useState([]);
	const [MultipleLineChartData, setMultiple] = useState([]);
	const [porcentLead, setPorcentLead] = useState([]);
	const [porcentConversion, setPorcent] = useState([]);
	const [TipoPagamento, setTipoPagamento] = useState([]);
	const [HorizontalBar, setHorizontalBarChart] = useState([]);
	const [HorizontalBarCidade, setHorizontalBarChartCidade] = useState([]);
	const [clickCount, setClickCount] = useState(0);
	const [projetosAno, setProjetosAno] = useState([]);
	const [potenciaAno, setPotenciaAno] = useState([]);
	const [ticketMedioAno, setTicketAno] = useState([]);
	const [ExpiradoPorcent, setExpiradoPorcent] = useState([]);
	const [VendidoAno, setVendidoAno] = useState([]);
	const [Processo, setProcesso] = useState([]);
	const [projetoVendido, setProjetoVendido] = useState([]);
	const [projetoProcessado, setProjetoProcessado] = useState([]);
	const [ticketVendido, setTicketVendido] = useState([]);
	const [ticketProcessado, setTicketProcessado] = useState([]);
	const [potenciaVendido, setPotenciaVendido] = useState([]);
	const [potenciaProcessado, setPotenciaProcessado] = useState([]);
	const [banco, setbancoPorcent] = useState([]);
	const [descontoFaturado, setDescontoFaturada] = useState([]);
	const [descontoVendido, setDescontoVendido] = useState([]);
	const [descontoProcessado, setDescontoProcessado] = useState([]);
	const [barAnoClick, setBarAnoClick] = useState();
	const [barMesClick, setBarMesClick] = useState();
	const [faturamentoServico, setFaturamentoServico] = useState();
	const [selectedRevenda, setSelectedRevenda] = useState(null);
	const [revendas, setRevendas] = useState([]);

	const [isLoading, setIsLoading] = useState(true);

	const handleRevendaChange = (event) => {
		setSelectedRevenda(event.target.value);
	};
	const user = useSelector((state) => state.auth.user);
	// Check if the user's cargo is "gerente"
	const isGerente = user.NIVEL == 1;
	const onClose = useCallback((e) => {
		if (e.target !== e.currentTarget) {
			return;
		}
		setIsTaskModalOpen(false);
	}, []);
	const onCloseButton = () => {
		setIsTaskModalOpen(false);
	};
	const faturamentoMes = async () => {
		const result = await AxiosService.get(`/faturamentoMes`);
		setFaturamento(result.data[0].TotalDaSoma);
	};

	const faturamentoservico = async () => {
		const result = await AxiosService.get(`/faturamentoservico`);
		setFaturamentoServico(result.data[0].TotalDaSoma);
	};

	const vendidoAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(`/VendidoAnual/${currentYear}`);

		setVendidoAno(result.data);
	};
	const getBancoPorenct = async () => {
		const result = await AxiosService.get(
			`financiamento/getBancoFinanciamento/`,
		);
		setbancoPorcent(result.data);
	};
	const processadoAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(`/ProcessadoAnual/${currentYear}`);

		setProcesso(result.data);
	};
	const potencia = async () => {
		const result = await AxiosService.get(`/potenciaMes`);

		setPotencia(result.data[0].totalPotencia);
	};
	const potenciaProcessada = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(
			`/potenciaProcessadoAnual/${currentYear}`,
		);

		setPotenciaProcessado(result.data);
	};

	const potenciaVendida = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(
			`/potenciaVendidoAnual/${currentYear}`,
		);

		setPotenciaVendido(result.data);
	};

	const top10Revendas = async () => {
		const result = await AxiosService.get(`/top10RevendasValor`);
		setHorizontalBarChart(result.data);
	};

	const produtividade = async () => {
		const currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth() + 1;
		try {
			const result = await AxiosService.get(
				`/produtividade/${currentYear}/${currentMonth}`,
			);

			setLineChartData(result.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const [isProjetosListModalOpen, setIsProjetosListModalOpen] = useState(false);
	const [isProjetosList21ModalOpen, setIsProjetosList21ModalOpen] =
		useState(false);

	// Function to open the modal
	const openModal = () => setIsProjetosListModalOpen(true);
	const openModal21 = () => setIsProjetosList21ModalOpen(true);

	const projetosmes = async () => {
		const result = await AxiosService.get(`/projetosMes`);

		setProjetosCount(result.data[0].projectCount);
	};

	const faturamentoAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(`/faturamentoAnual/${currentYear}`);

		setFaturamentoAno(result.data);
	};
	const projetosAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(`/projetoAnual/${currentYear}`);

		setProjetosAno(result.data);
	};
	const projetosVendidoAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(
			`/projetoVendidoAnual/${currentYear}`,
		);

		setProjetoVendido(result.data);
	};

	const projetosProcessadoAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(
			`/projetoProcessadoAnual/${currentYear}`,
		);

		setProjetoProcessado(result.data);
	};

	const descontoProcessadoAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(
			`/ProcessadoAnualDesconto/${currentYear}`,
		);

		setDescontoProcessado(result.data);
	};

	const descontoVendidoAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(
			`/VendidoAnualDesconto/${currentYear}`,
		);

		setDescontoVendido(result.data);
	};
	const descontoFaturadoAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(
			`/faturamentoAnualDesconto/${currentYear}`,
		);

		setDescontoFaturada(result.data);
	};

	const potenciaAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(`/potenciaAnual/${currentYear}`);

		setPotenciaAno(result.data);
	};

	const ticketAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(`/ticketAnual/${currentYear}`);

		setTicketAno(result.data);
	};

	const ticketVendidoAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(`/ticketVendidoAnual/${currentYear}`);

		setTicketVendido(result.data);
	};

	const ticketProcessadoAnual = async () => {
		const currentYear = new Date().getFullYear();

		const result = await AxiosService.get(
			`/ticketProcessadoAnual/${currentYear}`,
		);

		setTicketProcessado(result.data);
	};

	const leadPorcent = async () => {
		const result = await AxiosService.get(`/porcentageLead/`);
		setPorcentLead(result.data);
	};

	const PorcentConversion = async () => {
		const result = await AxiosService.get(`/porcentagePago/`);
		setPorcent(result.data);
	};
	const tipoPagamento = async () => {
		const result = await AxiosService.get(`/TipoPagamento/`);
		setTipoPagamento(result.data);
	};
	const onClickPorcent = async (year, month) => {
		if (month == 0 && year == "") {
			const result = await AxiosService.get(`/porcentagePago/`);
			setPorcent(result.data);
		} else if (month == 0) {
			const result = await AxiosService.get(`/porcentagePagoAno/${year}`);
			setPorcent(result.data);
		} else if (year != "") {
			const result = await AxiosService.get(
				`/porcentagePagoMes/${year}/${month}`,
			);
			setPorcent(result.data);
		} else {
			const result = await AxiosService.get(`/porcentagePago`);
			setPorcent(result.data);
		}
	};
	const onClickTipo = async (year, month) => {
		if (month == 0 && year == "") {
			const result = await AxiosService.get(`/TipoPagamento/`);
			setTipoPagamento(result.data);
		} else if (month == 0) {
			const result = await AxiosService.get(`/TipoPagamentoAno/${year}`);
			setTipoPagamento(result.data);
		} else if (year != "") {
			const result = await AxiosService.get(
				`/TipoPagamentoMes/${year}/${month}`,
			);
			setTipoPagamento(result.data);
		} else {
			const result = await AxiosService.get(`/TipoPagamento`);
			setTipoPagamento(result.data);
		}
	};

	const onClickBanco = async (year, month) => {
		if (month == 0 && year == "") {
			const result = await AxiosService.get(
				`/financiamento/getBancoFinanciamento/`,
			);
			setbancoPorcent(result.data);
		} else if (month == 0) {
			const result = await AxiosService.get(
				`/financiamento/getBancoFinanciamentoByYear/${year}`,
			);
			setbancoPorcent(result.data);
		} else if (year != "") {
			const result = await AxiosService.get(
				`/financiamento/getBancoFinanciamentoByMonth/${year}/${month}`,
			);
			setbancoPorcent(result.data);
		} else {
			const result = await AxiosService.get(`/getBancoFinanciamento`);
			setbancoPorcent(result.data);
		}
	};
	const onClickPorcentLead = async (year, month) => {
		if (month == 0 && year == "") {
			const result = await AxiosService.get(`/porcentageLead`);
			setPorcentLead(result.data);
		} else if (month == 0) {
			const result = await AxiosService.get(`/porcentageLeadAno/${year}`);
			setPorcentLead(result.data);
		} else if (year != "") {
			const result = await AxiosService.get(
				`/porcentageLeadMes/${year}/${month}`,
			);
			setPorcentLead(result.data);
		} else {
			const result = await AxiosService.get(`/porcentageLead`);
			setPorcentLead(result.data);
		}
	};

	const onClickPorcentExpirado = async (year, month) => {
		if (month == 0 && year == "") {
			const result = await AxiosService.get(`/ExpiradoChart`);
			setExpiradoPorcent(result.data);
		} else if (month == 0) {
			const result = await AxiosService.get(`/ExpiradoChartAno/${year}`);
			setExpiradoPorcent(result.data);
		} else if (year != "") {
			const result = await AxiosService.get(
				`/ExpiradoChartMes/${year}/${month}`,
			);
			setExpiradoPorcent(result.data);
		} else {
			const result = await AxiosService.get(`/ExpiradoChart`);
			setExpiradoPorcent(result.data);
		}
	};

	const faturamentoMesByDay = async () => {
		const currentMonth = new Date().getMonth() + 1;

		// Make three separate API calls
		const result1 = await AxiosService.get(
			`/faturamentoMesByDay/${currentMonth}`,
		);
		const result2 = await AxiosService.get(`/potenciaMesByDay/${currentMonth}`);
		const result3 = await AxiosService.get(`/projetosMesByDay/${currentMonth}`);

		// Combine the results into an object
		const combinedResults = {
			Faturamento: result1.data,
			Potencia: result2.data,
			Projetos: result3.data,
		};
		// Set the combined results in state
		setMultiple(combinedResults);
	};

	const MultipleChartOnClick = async (month) => {
		// Make three separate API calls
		const result1 = await AxiosService.get(`/faturamentoMesByDay/${month}`);
		const result2 = await AxiosService.get(`/potenciaMesByDay/${month}`);
		const result3 = await AxiosService.get(`/projetosMesByDay/${month}`);

		// Combine the results into an object
		const combinedResults = {
			Faturamento: result1.data,
			Potencia: result2.data,
			Projetos: result3.data,
		};
		// Set the combined results in state
		setMultiple(combinedResults);
	};

	const OnClickBarChartAnual = async (year) => {
		const result = await AxiosService.get(`/faturamentoAnual/${year}`);
		setFaturamentoAno(result.data);
	};
	const OnClickBarChartAnualVendido = async (year) => {
		const result = await AxiosService.get(`/VendidoAnual/${year}`);
		setVendidoAno(result.data);
	};

	const OnClickBarChartAnualProcessado = async (year) => {
		const result = await AxiosService.get(`/ProcessadoAnual/${year}`);
		setProcesso(result.data);
	};

	const OnClickBarChartAnualProjeto = async (year) => {
		const result = await AxiosService.get(`/projetoAnual/${year}`);
		setProjetosAno(result.data);
	};
	const OnClickBarChartAnualProjetoVendido = async (year) => {
		const result = await AxiosService.get(`/projetoVendidoAnual/${year}`);
		setProjetoVendido(result.data);
	};
	const OnClickBarChartAnualProjetoProcessado = async (year) => {
		const result = await AxiosService.get(`/projetoProcessadoAnual/${year}`);
		setProjetoProcessado(result.data);
	};
	const OnClickBarChartAnualPotencia = async (year) => {
		const result = await AxiosService.get(`/potenciaAnual/${year}`);
		setPotenciaAno(result.data);
	};
	const OnClickBarChartAnualPotenciaVendido = async (year) => {
		const result = await AxiosService.get(`/potenciaVendidoAnual/${year}`);
		setPotenciaVendido(result.data);
	};
	const OnClickBarChartAnualPotenciaProcessado = async (year) => {
		const result = await AxiosService.get(`/potenciaProcessadoAnual/${year}`);
		setPotenciaProcessado(result.data);
	};

	const OnClickBarChartAnualTicket = async (year) => {
		const result = await AxiosService.get(`/ticketAnual/${year}`);
		setTicketAno(result.data);
	};
	const OnClickBarChartAnualTicketProcessado = async (year) => {
		const result = await AxiosService.get(`/ticketProcessadoAnual/${year}`);
		setTicketProcessado(result.data);
	};

	const OnClickBarChartAnualTicketVendido = async (year) => {
		const result = await AxiosService.get(`/ticketVendidoAnual/${year}`);
		setTicketVendido(result.data);
	};

	const OnClickBarChartAnualDescontoVendido = async (year) => {
		const result = await AxiosService.get(`/VendidoAnualDesconto/${year}`);
		setDescontoVendido(result.data);
	};

	const OnClickBarChartAnualDescontoProcessado = async (year) => {
		const result = await AxiosService.get(`/ProcessadoAnualDesconto/${year}`);
		setDescontoProcessado(result.data);
	};

	const OnClickBarChartAnualDescontoFaturado = async (year) => {
		const result = await AxiosService.get(`/faturamentoAnualDesconto/${year}`);
		setDescontoFaturada(result.data);
	};

	const onClickHorizontal = async () => {
		// Increment the click count
		setClickCount((prevCount) => prevCount + 1);

		if (clickCount === 0) {
			// If clicked twice, make another request
			const result = await AxiosService.get(`/top10RevendasProjetos`);
			setHorizontalBarChart(result.data);

			// Handle the data as needed
		} else if (clickCount === 1) {
			// If it's the first click, make the initial request
			const result = await AxiosService.get(`/top10RevendasPotencia`);
			setHorizontalBarChart(result.data);
		} else {
			// If it's the first click, make the initial request
			const result = await AxiosService.get(`/top10RevendasValor`);
			setHorizontalBarChart(result.data);
			setClickCount(0);
		}
	};

	const onClickHorizontalCidade = async () => {
		// Increment the click count
		setClickCount((prevCount) => prevCount + 1);

		if (clickCount === 0) {
			// If clicked twice, make another request
			const result = await AxiosService.get(`/top10cidadeProjeto`);
			setHorizontalBarChartCidade(result.data);

			// Handle the data as needed
		} else if (clickCount === 1) {
			// If it's the first click, make the initial request
			const result = await AxiosService.get(`/top10cidadePotencia`);
			setHorizontalBarChartCidade(result.data);
		} else {
			// If it's the first click, make the initial request
			const result = await AxiosService.get(`/top10cidadeValor`);
			setHorizontalBarChartCidade(result.data);
			setClickCount(0);
		}
	};

	const onChangeHorizontal = async (month, year) => {
		setClickCount((prevCount) => prevCount);
		if (clickCount === 0) {
			if (month == 0 && year == "") {
				const result = await AxiosService.get(`/top10RevendasValor/`);
				setHorizontalBarChart(result.data);
			} else if (month == 0) {
				const result = await AxiosService.get(`/top10RevendasValorAno/${year}`);
				setHorizontalBarChart(result.data);
			} else if (year != "") {
				const result = await AxiosService.get(
					`/top10RevendasValorMes/${year}/${month}`,
				);
				setHorizontalBarChart(result.data);
			} else {
				const result = await AxiosService.get(`/top10RevendasValor`);
				setHorizontalBarChart(result.data);
			}
			// Handle the data as needed
		} else if (clickCount === 1) {
			if (month == 0 && year == "") {
				const result = await AxiosService.get(`/top10RevendasPotencia/`);
				setHorizontalBarChart(result.data);
			} else if (month == 0) {
				const result = await AxiosService.get(
					`/top10RevendasPotenciaAno/${year}`,
				);
				setHorizontalBarChart(result.data);
			} else if (year != "") {
				const result = await AxiosService.get(
					`/top10RevendasPotenciaMes/${year}/${month}`,
				);
				setHorizontalBarChart(result.data);
			} else {
				const result = await AxiosService.get(`/top10RevendasPotencia`);
				setHorizontalBarChart(result.data);
			}
		} else {
			if (month == 0 && year == "") {
				const result = await AxiosService.get(`/top10RevendasProjetos/`);
				setHorizontalBarChart(result.data);
			} else if (month == 0) {
				const result = await AxiosService.get(
					`/top10RevendasProjetosAno/${year}`,
				);
				setHorizontalBarChart(result.data);
			} else if (year != "") {
				const result = await AxiosService.get(
					`/top10RevendasProjetosMes/${year}/${month}`,
				);
				setHorizontalBarChart(result.data);
			} else {
				const result = await AxiosService.get(`/top10RevendasProjetos`);
				setHorizontalBarChart(result.data);
			}
		}
	};

	const onChangeHorizontalCidade = async (month, year) => {
		setClickCount((prevCount) => prevCount);
		if (clickCount === 0) {
			if (month == 0 && year == "") {
				const result = await AxiosService.get(`/top10cidadeValor/`);
				setHorizontalBarChartCidade(result.data);
			} else if (month == 0) {
				const result = await AxiosService.get(`/top10cidadeValorAno/${year}`);
				setHorizontalBarChartCidade(result.data);
			} else if (year != "") {
				const result = await AxiosService.get(
					`/top10cidadeValorMes/${year}/${month}`,
				);
				setHorizontalBarChartCidade(result.data);
			} else {
				const result = await AxiosService.get(`/top10cidadeValor`);
				setHorizontalBarChartCidade(result.data);
			}
			// Handle the data as needed
		} else if (clickCount === 1) {
			if (month == 0 && year == "") {
				const result = await AxiosService.get(`/top10cidadePotencia/`);
				setHorizontalBarChartCidade(result.data);
			} else if (month == 0) {
				const result = await AxiosService.get(
					`/top10cidadePotenciaAno/${year}`,
				);
				setHorizontalBarChartCidade(result.data);
			} else if (year != "") {
				const result = await AxiosService.get(
					`/top10RevendasPotenciaMes/${year}/${month}`,
				);
				setHorizontalBarChartCidade(result.data);
			} else {
				const result = await AxiosService.get(`/top10cidadePotenciaMes`);
				setHorizontalBarChartCidade(result.data);
			}
		} else {
			if (month == 0 && year == "") {
				const result = await AxiosService.get(`/top10cidadeProjeto/`);
				setHorizontalBarChartCidade(result.data);
			} else if (month == 0) {
				const result = await AxiosService.get(`/top10cidadeProjetoAno/${year}`);
				setHorizontalBarChartCidade(result.data);
			} else if (year != "") {
				const result = await AxiosService.get(
					`/top10cidadeProjetoMes/${year}/${month}`,
				);
				setHorizontalBarChartCidade(result.data);
			} else {
				const result = await AxiosService.get(`/top10cidadeProjeto`);
				setHorizontalBarChartCidade(result.data);
			}
		}
	};
	const handleBarClick = (data) => {
		console.log("Bar clicked", data);
		setBarMesClick(data.mes);
		setBarAnoClick(data.selectedYear);
		openModal();
		// You can use `data` for more specific tasks, like fetching more information for the modal
	};
	const handleClickFaturamento = (ano, mes) => {
		console.log("Bar clicked", data);
		setBarMesClick(mes);
		setBarAnoClick(ano);
		openModal21();
		// You can use `data` for more specific tasks, like fetching more information for the modal
	};
	const OnClicklineChartMes = async (month, year) => {
		try {
			const result = await AxiosService.get(`/produtividade/${year}/${month}`);
			setLineChartData(result.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const ticket = async () => {
		const result = await AxiosService.get(`/ticketMedio`);

		setTicketMedio(result.data[0].medianTicket);
	};
	useEffect(() => {
		faturamentoMes();
		potencia();
		projetosmes();
		ticket();
		faturamentoAnual();
		produtividade();
		faturamentoMesByDay();
		leadPorcent();
		PorcentConversion();
		top10Revendas();
		projetosAnual();
		potenciaAnual();
		ticketAnual();
		vendidoAnual();
		processadoAnual();
		potenciaProcessada();
		potenciaVendida();
		projetosVendidoAnual();
		projetosProcessadoAnual();
		ticketVendidoAnual();
		ticketProcessadoAnual();
		tipoPagamento();
		getBancoPorenct();
		descontoProcessadoAnual();
		descontoVendidoAnual();
		descontoFaturadoAnual();
		faturamentoservico();
		setTimeout(function () {
			setIsLoading(false);
		}, 5000);
	}, []);

	if (isSuccess) {
		if (isLoading) {
			return (
				<div
					onClick={onClose}
					className="fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide z-50 left-0 bottom-0 justify-center items-center flex dropdown duration-1000 ease-in-out"
				>
					<div role="status" className="text-center">
						<svg
							aria-hidden="true"
							className="inline w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
							viewBox="0 0 100 101"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
								fill="currentColor"
							/>
							<path
								d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
								fill="currentFill"
							/>
						</svg>
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			);
		} else {
			if (isGerente && isSuccess) {
				return (
					<div
						onClick={onClose}
						className="fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide z-50 left-0 bottom-0 justify-center items-center flex dropdown duration-1000 ease-in-out"
					>
						{/* MODAL SECTION */}
						<div className="scrollbar-hide overflow-y-scroll max-h-[95vh] my-auto max-w-[1400px] w-[800px] bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] mx-auto w-full px-8 py-8 rounded-xl">
							<div className="flex justify-end">
								<button
									onClick={onCloseButton}
									className="cursor-pointer text-xl z-50"
								>
									<svg
										className="fill-current text-black dark:text-white"
										xmlns="http://www.w3.org/2000/svg"
										width="18"
										height="18"
										viewBox="0 0 18 18"
									>
										<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
									</svg>
								</button>
							</div>
							<div>
								<h1 className="text-3xl text-center hover:scale-105 hover:text-[#3A007E] duration-500 ease-in-out">
									Dashboard
								</h1>
							</div>

							<div className="flex flex-col space-y-4 text-justify justify-center ">
								<div className="flex flex-row justify-center w-full h-[100px]">
									<div className="relative flex flex-row items-center justify-start w-2/6 ml-2 bg-[#f4f7fd] dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
										<MdOutlineAttachMoney className="absolute text-4xl" />
										<div className="flex flex-row items-center justify-center ml-2 w-full">
											<div className="flex flex-col items-center space-x-2">
												<p className="font-bold text-center text-xl break-keep">
													Faturamento mês
												</p>
												<p className="font-bold text-center break-keep">
													<span className="flex items-center justify-center">
														{faturamento != null
															? utils.formatNumber(faturamento)
															: "R$ 0"}
													</span>
												</p>
											</div>
										</div>
									</div>

									<div className="relative flex flex-row items-center justify-start w-2/6 bg-[#f4f7fd] ml-2 dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
										<GoProjectRoadmap className="absolute text-4xl" />
										<div className="flex flex-row items-center justify-center ml-2 w-full">
											<div className="flex flex-col items-center space-x-2">
												<p className="font-bold text-center text-xl break-keep">
													Número de projetos mês
												</p>
												<p className="font-bold text-center break-keep">
													<span className="flex items-center justify-center">
														{projetosCount}
													</span>
												</p>
											</div>
										</div>
									</div>
									<div className="relative flex flex-row items-center justify-start w-2/6 bg-[#f4f7fd] ml-2 dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
										<ImPower className="absolute text-4xl" />
										<div className="flex flex-row items-center justify-center ml-2 w-full">
											<div className="flex flex-col items-center space-x-2">
												<p className="font-bold text-center text-xl break-keep">
													Ticket médio mês
												</p>
												<p className="font-bold text-center break-keep">
													<span className="flex items-center justify-center">
														{ticketMedio != null
															? parseFloat(ticketMedio).toFixed(2) + " Kwp"
															: "0 Kwp"}
													</span>
												</p>
											</div>
										</div>
									</div>
									<div className="relative flex flex-row items-center justify-start w-2/6 bg-[#f4f7fd] ml-2 dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
										<GiPowerGenerator className="absolute text-4xl" />
										<div className="flex flex-row items-center justify-center ml-2 w-full">
											<div className="flex flex-col items-center space-x-2">
												<p className="font-bold text-center text-xl break-keep">
													Potência mês
												</p>
												<p className="font-bold text-center break-keep">
													<span className="flex items-center justify-center">
														{potenciaMes != null
															? potenciaMes + " Kwp"
															: "0 Kwp"}
													</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="flex justify-center mt-6 flex-row">
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart Example */}
									<BarChart
										chartData={FaturamentoAno}
										vendidoData={VendidoAno}
										processadoData={Processo}
										onClick={OnClickBarChartAnual}
										onClickProcessado={OnClickBarChartAnualProcessado}
										onClickVendido={OnClickBarChartAnualVendido}
										type={"Faturamento"}
										onBarClick={handleBarClick}
									/>
									{/* Line Chart Example */}
									<BarChart
										chartData={ticketMedioAno}
										vendidoData={ticketProcessado}
										processadoData={ticketVendido}
										onClick={OnClickBarChartAnualTicket}
										onClickProcessado={OnClickBarChartAnualTicketProcessado}
										onClickVendido={OnClickBarChartAnualTicketVendido}
										type={"Ticket médio"}
										onBarClick={handleBarClick}
									/>
								</div>
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart Example */}
									<BarChart
										chartData={projetosAno}
										vendidoData={projetoVendido}
										processadoData={projetoProcessado}
										onClick={OnClickBarChartAnualProjeto}
										onClickProcessado={OnClickBarChartAnualProjetoProcessado}
										onClickVendido={OnClickBarChartAnualProjetoVendido}
										type={"Projetos"}
										onBarClick={handleBarClick}
									/>

									<MultipleLineChart
										type={"number"}
										chartData={MultipleLineChartData}
										onClick={MultipleChartOnClick}
									/>

									{/* Line Chart Example */}
								</div>
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart Example */}
									<BarChart
										chartData={potenciaAno}
										vendidoData={potenciaVendido}
										processadoData={potenciaProcessado}
										onClick={OnClickBarChartAnualPotencia}
										onClickProcessado={OnClickBarChartAnualPotenciaProcessado}
										onClickVendido={OnClickBarChartAnualPotenciaVendido}
										type={"Potencia"}
										onBarClick={handleBarClick}
									/>

									{/* Line Chart Example */}
									<LineChart
										chartData={lineChartData}
										onClick={OnClicklineChartMes}
									/>
								</div>
							</div>
							<div className="flex justify-center  flex-row">
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart Example */}
									<PieChart
										chartData={porcentLead}
										onClick={onClickPorcentLead}
										name="Leads"
									/>
									<PieChart
										chartData={ExpiradoPorcent}
										onClick={onClickPorcentExpirado}
										name="Expirados"
									/>
								</div>
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart 	Example */}
									<PieChart
										chartData={porcentConversion}
										onClick={onClickPorcent}
										name=""
									/>
									<PieChartPagamento
										chartData={TipoPagamento}
										onClick={onClickTipo}
										name="Forma de pagamento"
									/>
									{/* Line Chart Example */}
								</div>
								<div className="flex mt-6 flex-col mx-2">
									<BarChart
										chartData={descontoFaturado}
										vendidoData={descontoVendido}
										processadoData={descontoProcessado}
										onClick={OnClickBarChartAnualDescontoFaturado}
										onClickProcessado={OnClickBarChartAnualDescontoProcessado}
										onClickVendido={OnClickBarChartAnualDescontoVendido}
										type={"Desconto"}
										onBarClick={handleBarClick}
									/>
									<PieChartBanco
										chartData={banco}
										onClick={onClickBanco}
										name="Bancos"
									/>
									{/* Line Chart Example */}
								</div>
							</div>
							<div className="flex justify-center  flex-row">
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart Example */}
									{/* <BarChart
										chartData={descontoFaturado}
										vendidoData={descontoVendido}
										processadoData={descontoProcessado}
										onClick={OnClickBarChartAnualDescontoFaturado}
										onClickProcessado={OnClickBarChartAnualDescontoProcessado}
										onClickVendido={OnClickBarChartAnualDescontoVendido}
										type={"Desconto"}
										onBarClick={handleBarClick}
									/> */}
								</div>
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart 	Example */}
									{/* <HorizontalBarChartCidade
										chartData={HorizontalBarCidade}
										onChange={onChangeHorizontalCidade}
										onClick={onClickHorizontalCidade}
										name="Cidades"
										type={clickCount}
									/> */}

									{/* Line Chart Example */}
								</div>

								{/* Line Chart Example */}
							</div>
							<div className="flex justify-center  flex-row mt-6">
								{/* <VendaTable /> */}
							</div>
						</div>

						{/* Delete and Add/Edit Modals */}
						{isProjetosListModalOpen && (
							<FinanceiroListModal
								setIsProjetosListModalOpen={setIsProjetosListModalOpen}
								ano={barAnoClick}
								mes={barMesClick}
							/>
						)}
						{isProjetosList21ModalOpen && (
							<FinanceiroList21Modal
								setIsProjetosListModalOpen={setIsProjetosList21ModalOpen}
								ano={barAnoClick}
								mes={barMesClick}
							/>
						)}
					</div>
				);
			} else {
				// Render something else or nothing if the user is not a "gerente"
				return (
					<div
						onClick={onClose}
						className="fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide z-50 left-0 bottom-0 justify-center items-center flex dropdown duration-1000 ease-in-out"
					>
						{/* MODAL SECTION */}
						<div className="scrollbar-hide overflow-y-scroll max-h-[95vh] my-auto max-w-[1400px] w-[800px] bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] mx-auto w-full px-8 py-8 rounded-xl">
							<div className="flex justify-end">
								<button
									onClick={onCloseButton}
									className="cursor-pointer text-xl z-50"
								>
									<svg
										className="fill-current text-black dark:text-white"
										xmlns="http://www.w3.org/2000/svg"
										width="18"
										height="18"
										viewBox="0 0 18 18"
									>
										<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
									</svg>
								</button>
							</div>
							<div>
								<h1 className="text-3xl text-center hover:scale-105 hover:text-[#3A007E] duration-500 ease-in-out">
									Dashboard
								</h1>
							</div>

							<div className="flex flex-col space-y-4 text-justify justify-center ">
								<div className="flex flex-row justify-center w-full h-[100px]">
									<div className=" relative flex flex-row items-center justify-start w-2/6   ml-2 w-2/6 bg-[#f4f7fd] dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
										<MdOutlineAttachMoney className="absolute text-4xl" />

										<div className="flex flex-row items-center justify-center  ml-2 w-full ">
											<div className="flex flex-col items-center space-x-2">
												<p className="font-bold text-center text-xl break-keep">
													Faturamento mês
												</p>
												<p className="font-bold text-center break-keep">
													<span className="flex items-center justify-center">
														{faturamento != null
															? utils.formatNumber(faturamento)
															: "R$ 0"}
													</span>
												</p>
											</div>
										</div>
									</div>
									<div sclassName=" relative flex flex-row items-center justify-start w-2/6  bg-[#f4f7fd] ml-2 w-2/6 dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
										<GoProjectRoadmap className="absolute text-4xl" />

										<div className="flex flex-row items-center justify-center  ml-2 w-full ">
											<div className="flex flex-col items-center space-x-2">
												<p className="font-bold text-center text-xl break-keep">
													Número de projetos mês
												</p>
												<p className="font-bold text-center break-keep">
													<span className="flex items-center justify-center">
														{projetosCount}
													</span>
												</p>
											</div>
										</div>
									</div>

									<div className="relative flex flex-row items-center justify-start w-2/6  bg-[#f4f7fd] ml-2 w-2/6 dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
										<ImPower className="absolute text-4xl" />

										<div className="flex flex-row items-center justify-center  ml-2 w-full ">
											<div className="flex flex-col items-center space-x-2">
												<p className="font-bold text-center text-xl break-keep">
													Ticket médio mês
												</p>
												<p className="font-bold text-center break-keep">
													<span classNssame="flex items-center justify-center">
														{ticketMedio != null
															? parseFloat(ticketMedio).toFixed(2) + " Kwp"
															: "0 Kwp"}
													</span>
												</p>
											</div>
										</div>
									</div>
									<div className=" relative flex flex-row items-center justify-start w-2/6  bg-[#f4f7fd] ml-2 w-2/6 dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
										<GiPowerGenerator className="absolute text-4xl" />

										<div className="flex flex-row items-center justify-center  ml-2 w-full ">
											<div className="flex flex-col items-center space-x-2">
												<p className="font-bold text-center text-xl break-keep">
													Potência mês
												</p>
												<p className="font-bold text-center break-keep">
													<span className="flex items-center justify-center">
														{potenciaMes != null
															? potenciaMes + " Kwp"
															: "0 Kwp"}
													</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="flex justify-center mt-6 flex-row">
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart Example */}
									<BarChart
										chartData={FaturamentoAno}
										vendidoData={VendidoAno}
										processadoData={Processo}
										onClick={OnClickBarChartAnual}
										onClickProcessado={OnClickBarChartAnualProcessado}
										onClickVendido={OnClickBarChartAnualVendido}
										type={"Faturamento"}
										onBarClick={handleBarClick}
									/>
									{/* Line Chart Example */}
									<BarChart
										chartData={ticketMedioAno}
										vendidoData={ticketProcessado}
										processadoData={ticketVendido}
										onClick={OnClickBarChartAnualTicket}
										onClickProcessado={OnClickBarChartAnualTicketProcessado}
										onClickVendido={OnClickBarChartAnualTicketVendido}
										type={"Ticket médio"}
										onBarClick={handleBarClick}
									/>
								</div>
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart Example */}
									<BarChart
										chartData={projetosAno}
										vendidoData={projetoVendido}
										processadoData={projetoProcessado}
										onClick={OnClickBarChartAnualProjeto}
										onClickProcessado={OnClickBarChartAnualProjetoProcessado}
										onClickVendido={OnClickBarChartAnualProjetoVendido}
										type={"Projetos"}
										onBarClick={handleBarClick}
									/>

									<MultipleLineChart
										type={"number"}
										chartData={MultipleLineChartData}
										onClick={MultipleChartOnClick}
									/>

									{/* Line Chart Example */}
								</div>
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart Example */}
									<BarChart
										chartData={potenciaAno}
										vendidoData={potenciaVendido}
										processadoData={potenciaProcessado}
										onClick={OnClickBarChartAnualPotencia}
										onClickProcessado={OnClickBarChartAnualPotenciaProcessado}
										onClickVendido={OnClickBarChartAnualPotenciaVendido}
										type={"Potencia"}
										onBarClick={handleBarClick}
									/>

									{/* Line Chart Example */}
									<PieChart
										chartData={porcentLead}
										onClick={onClickPorcentLead}
										name="Leads"
									/>
								</div>
							</div>
							<div className="flex justify-center  flex-row">
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart Example */}

									<PieChart
										chartData={ExpiradoPorcent}
										onClick={onClickPorcentExpirado}
										name="Expirados"
									/>
									<PieChartBanco
										chartData={banco}
										onClick={onClickBanco}
										name="Bancos"
									/>
								</div>
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart 	Example */}
									<PieChart
										chartData={porcentConversion}
										onClick={onClickPorcent}
										name=""
									/>
									<PieChartPagamento
										chartData={TipoPagamento}
										onClick={onClickTipo}
										name="Forma de pagamento"
									/>
									{/* Line Chart Example */}
								</div>
								<div className="flex justify-center  mt-6 flex-col mx-2">
									{/* <HorizontalBarChart
										chartData={HorizontalBar}
										onChange={onChangeHorizontal}
										onClick={onClickHorizontal}
										name="Revendas"
										type={clickCount}
									/> */}
									<BarChart
										chartData={descontoFaturado}
										vendidoData={descontoVendido}
										processadoData={descontoProcessado}
										onClick={OnClickBarChartAnualDescontoFaturado}
										onClickProcessado={OnClickBarChartAnualDescontoProcessado}
										onClickVendido={OnClickBarChartAnualDescontoVendido}
										type={"Desconto"}
										onBarClick={handleBarClick}
									/>
									{/* Line Chart Example */}
								</div>
							</div>
							<div className="flex justify-center  flex-row">
								<div className="flex justify-center mt-6 flex-col mx-2">
									{/* Bar Chart Example */}

									{/* <HorizontalBarChartCidade
										chartData={HorizontalBarCidade}
										onChange={onChangeHorizontalCidade}
										onClick={onClickHorizontalCidade}
										name="Cidades"
										type={clickCount}
									/> */}
								</div>
							
								<div className="flex justify-center  mt-6 flex-col mx-2">
									{/* Line Chart Example */}
								</div>
							</div>
						</div>

						{/* Delete and Add/Edit Modals */}
						{/* {isDeleteModalOpen && (
					<DeleteModal
						onDeleteBtnClick={onDeleteBtnClick}
						type="project"
						title={project.title}
					/>
				)} */}

						{isProjetosListModalOpen && (
							<ProjetosListModal
								setIsProjetosListModalOpen={setIsProjetosListModalOpen}
								ano={barAnoClick}
								mes={barMesClick}
							/>
						)}
						{/* {isProjetosList21ModalOpen && (
							<FinanceiroList21Modal
								setIsProjetosListModalOpen={setIsProjetosList21ModalOpen}
								ano={barAnoClick}
								mes={barMesClick}
							/>
						)} */}
					</div>
				);
			}
		}
	}
}

export default DashModal;
