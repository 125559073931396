import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import utils from "../utils";

const BarChart = ({
	chartData,
	processadoData,
	vendidoData,
	onClick,
	type,
	onClickVendido,
	onClickProcessado,
	onBarClick,
}) => {
	const [selectedYear, setSelectedYear] = useState(2024);
	const faturamento = [];
	const vendido = [];
	const processado = [];
	const mesFaturamento = [];
	const mesProcessado = [];
	const mesVendido = [];


	const [activeDataset, setActiveDataset] = useState(0);

	const gradientColor = (context) => {
		const chart = context.chart;
		const { ctx, chartArea } = chart;

		if (!chartArea) {
			return null;
		}

		const gradient = ctx.createLinearGradient(
			0,
			chartArea.bottom,
			0,
			chartArea.top,
		);
		gradient.addColorStop(0, "#36A2EB");
		gradient.addColorStop(1, "#3A007E");

		return gradient;
	};

	const newLegendClickHandler = function (e, legendItem, legend) {
		let datasetIndex = legendItem.datasetIndex;
		let ci = legend.chart,
			metaSets = [];

		for (let i = 0; i < legend.chart.data.datasets.length; i++) {
			metaSets.push(ci.getDatasetMeta(i));
		}

		metaSets.forEach(function (meta) {
			meta.hidden = meta.index === datasetIndex ? false : true;
		});
		setActiveDataset(datasetIndex); // Update the activeDataset state on legend click
		ci.update();
	};

	chartData.forEach((month) => {
		const monthlyFaturamento = month.monthly;
		const year = month.year;

		if (
			!faturamento.includes(monthlyFaturamento) ||
			!mesFaturamento.includes(month.month)
		) {
			faturamento.push(monthlyFaturamento);
		}
		if (!mesFaturamento.includes(month.month)) {
			mesFaturamento.push(month.month);
		}
	});

	processadoData &&
		processadoData.forEach((month) => {
			const monthlyFaturamento = month.monthly;
			const year = month.year;

			if (
				!processado.includes(monthlyFaturamento) ||
				!mesProcessado.includes(month.month)
			) {
				processado.push(monthlyFaturamento);
			}
			if (!mesProcessado.includes(month.month)) {
				mesProcessado.push(month.month);
			}
		});

	vendidoData &&
		vendidoData.forEach((month) => {
			const monthlyFaturamento = month.monthly;
			const year = month.year;

			if (!vendido.includes(monthlyFaturamento) || !mesVendido.includes(month.month)) {
				vendido.push(monthlyFaturamento);
			}
			if (!mesVendido.includes(month.month)) {
				mesVendido.push(month.month);
			}
		});

	const yearsArray = [
		2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034,
		2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046,
		2047, 2048, 2049, 2050,
	];

	const options = {
		plugins: {
			legend: {
				onClick: newLegendClickHandler,
			},
			datalabels: {
				font: {
					size: 9,
				},
				display: true,
				color: "white",
				anchor: "end",
				align: "end",
				formatter: (value, context) => {
					const index = context.datasetIndex;
					return index === activeDataset
						? type === "Faturamento"
							? utils.formatCurrency(value)
							: type === "Projetos"
							? utils.formatNumberChart(value)
							: type === "Potencia"
							? utils.formatNumberChartPotencia(value)
							: utils.formatfloat(value)
						: "";
				},
			},
		},
		onClick: (event, elements) => {
			if (elements.length > 0) {
			  const mes = elements[0].index+1;
			  const datasetIndex = elements[0].datasetIndex;
			  // Call the onBarClick prop function with relevant data
			  onBarClick({ mes, datasetIndex, selectedYear });
			}
		  },
	};

	const DataYear = {
		labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
		datasets: [
		  {
			label: "Faturados",
			data: faturamento,
			backgroundColor: gradientColor,
		  },
		  ...(processado.length > 0
			? [
				{
				  label: "Processado",
				  data: processado,
				  backgroundColor: "orange",
				  hidden: true,
				},
			  ]
			: []),
		  ...(vendido.length > 0
			? [
				{
				  label: "Vendido",
				  data: vendido,
				  backgroundColor: "green",
				  hidden: true,
				},
			  ]
			: []),
		],
	  };
	  
	useEffect(() => {
		// Use the label of the active dataset to determine which onClick function to call
		const activeLabel = DataYear.datasets[activeDataset].label;
		if (selectedYear && activeLabel) {
			if (activeLabel === "Processado") {
				onClickProcessado(selectedYear);
			} else if (activeLabel === "Vendido") {
				onClickVendido(selectedYear);
			} else {
				onClick(selectedYear);
			}
		}
	}, [selectedYear, activeDataset]);
	return (
		<div className="relative flex flex-col items-center justify-center">
			<div className="bg-[#f4f7fd] dark:bg-[#20212c] w-[400px] rounded-lg text-center shadow-lg hover:scale-110 duration-500 ease-in-out hover:text-[#3A007E] mt-2">
				{type} Anual
				<select
					value={selectedYear}
					onChange={(e) => setSelectedYear(e.target.value)}
					className="select-status ml-2 pr-8 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  focus:outline-[#3A007E] outline-none"
				>
					<option value=""></option>
					{yearsArray.map((year) => (
						<option className="status-options" key={year} value={year}>
							{year}
						</option>
					))}
				</select>
				<Bar
					data={DataYear}
					height={200}
					width={300}
					options={options}
					plugins={[ChartDataLabels]}
				/>
			</div>
		</div>
	);
};

export default BarChart;
