import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ReactNotifications } from "react-notifications-component";
import 'react-notifications-component/dist/theme.css'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Provider store={store} className="app-container" >
      <ReactNotifications />
			<App />
		</Provider>
	</React.StrictMode>,
);
