import React, { useState } from 'react';
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";

const ArrowButton = ({onClick,onUp,OnDown}) => {
  const [isArrowUp, setIsArrowUp] = useState(false);

  const handleClick = () => {
    setIsArrowUp((prevIsArrowUp) => !prevIsArrowUp);
    if(isArrowUp){
        onUp();
    }else{
        OnDown();
    }
  };

  return (
    <button onClick={handleClick}>
      {isArrowUp ? (
        <FaLongArrowAltUp className='text-[#828fa3] font-bold'/>
      ) : (
        <FaLongArrowAltDown  className='text-[#828fa3] font-bold'/>
      )}
    </button>
  );
};

export default ArrowButton;
