import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import AxiosService from "../axios";

function UserListFilterTask({
	type,
	setCodProjFilter,
	setValueFilter,
	setClientFilter,
	setRevendaFilter,
	setTask,
}) {
	const [UserList, setUserList] = useState([]);
	const [responsavel,setResponsavel]=useState(null);
	const [responsavelNome,setResponsavelNome]=useState(null)

	const getUserList = async (id) => {
		await AxiosService.get(`/users/`)
			.then((response) => {
				console.log(response.data);
				setUserList(response.data.users);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};
	useEffect(() => {
		getUserList();
	}, []);
	const onChangeUserResponsavel = (e) => {
		setResponsavel(e.target.value);
		setResponsavelNome(e.target.options[e.target.selectedIndex].text);
        console.log(e.target.value)
        setTask(e.target.value);
	};
	return (
		<div
			className={
				type === "Boards"
					? " absolute  top-1/2  left-10 z-50"
					: " absolute  top-1/2  left-10 z-50"
			}
		>
			<div className=" flex justify-end items-center">
				<div className=" w-40 text-sm z-50 font-medium shadow-md shadow-[#364e7e1a] bg-white dark:bg-[#20212c] space-y-4 py-5 px-4 rounded-lg  h-auto pr-12">
					<div className="flex flex-col items-center justify-center">
						<select
							value={responsavel}
							onChange={onChangeUserResponsavel}
							className=" select-status pr-7 pl-1.5 py-2  text-white rounded-lg dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  border-[1px] border-gray-600 focus:outline-[#3A007E] outline-none hover:outline-[#3A007E]  duration-500 ease-in-out"
						>
							{" "}
							<option disabled selected>
								Responsavel
							</option>
							{UserList.map((column, index) => (
								<option
									className="status-options"
									key={column.ID_USUARIO}
									value={column.ID_USUARIO}
                                    
								>
									{column.NOME}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserListFilterTask;
