import { useDispatch, useSelector } from "react-redux";
import ElipsisMenu from "../components/ElipsisMenu";
import elipsis from "../assets/icon-vertical-ellipsis.svg";
import boardsSlice from "../redux/boardsSlice";
import Subtask from "../components/Subtask";
import AddEditTaskModal from "./AddEditTaskModal";
import DeleteModal from "./DeleteModal";
import AxiosService from "../axios";
import React, { useEffect, useState } from "react";
import utils from "../utils";

function TaskLogModal({ taskIndex, colIndex, setIsTaskModalOpen }) {
	const [log, setLog] = useState([]);

	const onClose = (e) => {
		if (e.target !== e.currentTarget) {
			return;
		}

		setIsTaskModalOpen(false);
	};

	useEffect(() => {
		const getData = async () => {
			AxiosService.get("/projetoslog")
				.then((response) => {
					setLog(response.data);
				})
				.catch((error) => {
					console.error("Error:", error);
				});
		};

		getData();
	}, []);

	function binarySearch(projects, target) {
		let left = 0;
		let right = projects.length - 1;

		while (left <= right) {
			const mid = Math.floor((left + right) / 2);
			const midValue = projects[mid]["COD_PROJETO"];

			if (midValue === target) {
				return true;
			} else if (midValue < target) {
				left = mid + 1;
			} else {
				right = mid - 1;
			}
		}

		return false; // Return -1 if the target is not found
	}

	const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);

	// const valorAsNumber = parseFloat(log.VALOR);
	// // Convert the number to a currency string in Brazilian Real format
	// const formattedNumber = valorAsNumber.toLocaleString("pt-BR", {
	// 	style: "currency",
	// 	currency: "BRL",
	// });
	function formatToBRDate(timestampStr) {
		const timestampDate = new Date(timestampStr);
		const brFormattedDate = timestampDate.toLocaleDateString("pt-BR", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
		return brFormattedDate;
	}

	return (
		<div
			onClick={onClose}
			className="fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide z-50 left-0 bottom-0 justify-center items-center flex dropdown"
		>
			{/* MODAL SECTION */}
			<div className="scrollbar-hide overflow-y-scroll max-h-[95vh] my-auto max-w-[1200px] w-[800px] bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] mx-auto w-full px-8 py-8 rounded-xl">
				{/* Other properties */}
				<div className="container mx-auto p-4">
					<h1 className="font-bold tracking-wide text-center text-2xl">
						Historico de Logs do {taskIndex}
					</h1>
					<ol className="space-y-4">
						{utils.binarySearch(log, taskIndex) != true ? (
							<p className="font-bold tracking-wide text-center text-2xl p-4">
								Não há Logs para este projeto
							</p>
						) : (
							log.map((proj, index) => {
								if (taskIndex == proj.COD_PROJETO) {
									const valorAsNumber = parseFloat(proj.VALOR_DESCONTO);
									// Convert the number to a currency string in Brazilian Real format
									const formattedNumber = valorAsNumber.toLocaleString(
										"pt-BR",
										{
											style: "currency",
											currency: "BRL",
										},
									);
									return (
										<li
											key={index}
											className="p-2 rounded-lg shadow-md fit-content text-justify first:my-5 rounded-lg  bg-[#f4f7fd]  dark:bg-[#20212c] shadow-[#364e7e1a] py-3  px-3 shadow-lg hover:text-[#3A007E] dark:text-white dark:hover:text-[#3A007E] hover:scale-105 cursor-pointer   duration-500 ease-in-out"
										>
											<div className="flex flex-row justify-between">
												<div className="flex flex-col space-y-1">
													<p className=" font-bold tracking-wide text-xs">
														{proj.COD_PROJETO}
													</p>

													<p className="font-bold tracking-wide text-xs">
														Cliente: {proj.CLIENTE}
													</p>
												</div>
												<div className="flex flex-col space-y-1">
													<p className=" font-bold tracking-wide text-xs ">
														Potencia: {proj.POTENCIA} Kwp
													</p>

													<p className="font-bold tracking-wide text-xs">
														Valor: {formattedNumber}
													</p>
												</div>
												<div className="flex flex-col space-y-1">
													<p className=" font-bold tracking-wide text-xs">
														Etapa: {proj.ETAPA}
													</p>

													<p className="font-bold tracking-wide text-xs">
														Etapa Comercial: {proj.ETAPA_COMERCIAL}
													</p>
												</div>
												<div className="flex flex-col space-y-1">
													<p className=" font-bold tracking-wide text-xs">
														Data Pagamento:{" "}
														{formatToBRDate(proj.DATA_PAGAMENTO)}
													</p>

													<p className="font-bold tracking-wide text-xs">
														Data Atualização: {formatToBRDate(proj.TIMESTAMP)}
													</p>
												</div>
											</div>
										</li>
									);
								}
							})
						)}
					</ol>
				</div>
			</div>
		</div>
	);
}

export default TaskLogModal;
