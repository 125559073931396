import React from "react";

function ElipsisMenuLeadFilter({
	type,
	setNomeFilter,
	setOrigemFilter,
	setCidadeFilter,
	setRevendaFilter,
}) {
	return (
		<div
			className={
				type === "Boards"
					? " absolute  top-16  right-5"
					: " absolute  top-6  right-0"
			}
		>
			<div className=" flex justify-end items-center">
				<div className=" w-40 text-sm z-50 font-medium shadow-md shadow-[#364e7e1a] bg-white dark:bg-[#20212c] space-y-4 py-5 px-4 rounded-lg  h-auto pr-12">
					<p
						onClick={() => {
							setNomeFilter();
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease-in-out"
					>
						Nome
					</p>
					<p
						onClick={() => {
							setOrigemFilter();
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease-in-out"
					>
						Origem
					</p>
					<p
						onClick={() => {
							setRevendaFilter();
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease-in-out"
					>
						Revenda
					</p>
					<p
						onClick={() => {
							setCidadeFilter();
						}}
						className=" cursor-pointer dark:text-gray-400 text-gray-700 hover:scale-105 hover:opacity-80 duration-500 ease-in-out"
					>
						Cidade
					</p>
				</div>
			</div>
		</div>
	);
}

export default ElipsisMenuLeadFilter;
