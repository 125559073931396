import React, { useState } from "react";
import Input from "../components/Input.tsx";
import AxiosService from "../axios";
import utils from "../utils";
import { Store } from "react-notifications-component";
const AddEmpresaModal = ({ isOpen, setIsOpen }) => {
	const [nome, setNome] = useState("");
	const [cnpj, setCnpj] = useState("");
	const [cidade, setCidade] = useState("");


	const handleSubmit = async (e) => {
		e.preventDefault();
        const cnpjFormatado= utils.formatCNPJ(cnpj);
		// Here, you would send data to your server using Axios or another HTTP client.
		console.log({ nome, cnpj, cidade });
		await AxiosService.post("empresas/", { nome,cnpj , cidade });
		Store.addNotification({
            title: "Salvo!",
            message: "Empresa salva!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
            },
        });
		// After submission, you can clear the form and close the modal
		setNome("");
		setCnpj("");
		setCidade("");
		setIsOpen(false);
	};
    const handleBlur = (e) => {
        const valorFormatado = utils.formatarCNPJ(e.target.value);
        setCnpj(valorFormatado);
    };
	// Close modal function
	const closeModal = () => {
		setIsOpen(false);
	};

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
			<div
				className=" relative scrollbar-hide overflow-auto max-h-[100vh] my-auto bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold
       shadow-md shadow-[#364e7e1a] max-w-[800px] w-[800px] px-8 mx-auto py-8 rounded-xl"
			>
				<div className="mt-3 text-center">
					<h3 className="text-lg leading-6 font-medium  dark:text-white text-gray-900">
						Adicionar Empresa
					</h3>
					<form onSubmit={handleSubmit} className="mt-2 space-y-5">
						<Input
							type="text"
							labelText="Nome"
							value={nome}
							onChange={(e) => setNome(e.target.value)}
							className=" w-full mt-2"
						/>
						<Input
							type="text"
							labelText="CNPJ"
							value={cnpj}
							onChange={(e) => setCnpj(e.target.value)}
							className=" w-full mt-2"
                            onBlur={handleBlur}
						/>

						<Input
							type="text"
							labelText="Cidade"
							value={cidade}
							onChange={(e) => setCidade(e.target.value)}
							className=" w-full mt-2"
						/>
						<div className="flex items-center justify-end p-6  rounded-b">
							<button
								type="button"
								className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
								onClick={closeModal}
							>
								Cancelar
							</button>
							<button
								type="submit"
								className="bg-green-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
							>
								Salvar
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddEmpresaModal;
