import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "chartjs-plugin-datalabels"; // Import the plugin
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register the plugin globally
Chart.register(ChartDataLabels);

const HorizontalBarChartCidade = ({
	chartData,
	onClick,
	name,
	onChange,
	type,
}) => {
	const [selectedMonth, setSelectedMonth] = useState("");
	const [selectedYear, setSelectedYear] = useState("");
	const chartRef = useRef(null);
	const horizontalBarChartRef = useRef(null);
	const monthArray = [
		"Jan",
		"Fev",
		"Mar",
		"Abr",
		"Mai",
		"Jun",
		"Jul",
		"Ago",
		"Set",
		"Out",
		"Nov",
		"Dez",
	];

	useEffect(() => {
		onChange(getMonthNumber(selectedMonth), selectedYear);
	}, [selectedYear, selectedMonth]);

	const getMonthNumber = (monthName) => {
		const monthMap = {
			Jan: 1,
			Fev: 2,
			Mar: 3,
			Abr: 4,
			Mai: 5,
			Jun: 6,
			Jul: 7,
			Ago: 8,
			Set: 9,
			Out: 10,
			Nov: 11,
			Dez: 12,
		};
		return monthMap[monthName] || 0; // Default to 1 if the month name is not found
	};

	const yearsArray = [
		2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034,
		2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046,
		2047, 2048, 2049, 2050,
	];

	const data = chartData && chartData.length > 0 ? chartData : [];

	const revendas = data.map((item) => item.CIDADE);
	const valores = data.map((item) =>
		item.Valor ? parseFloat(item.Valor) : null,
	);
	const potencia = data.map((item) =>
		item.Potencia ? parseFloat(item.Potencia) : null,
	);
	const projetos = data.map((item) =>
		item.total ? parseFloat(item.total) : null,
	);
	const datasets = [
		{
			data: valores,
			backgroundColor: [
				"#36A2EB",
				"#FFCE56",
				"#FF9F40",
				"#9966FF",
				"#FF6384",
				"#4BC0C0",
			],
			borderWidth: 1,
		},
		potencia[0] != null
			? {
					data: potencia,
					backgroundColor: [
						"#FFCE56",
						"#36A2EB",
						"#FF9F40",
						"#9966FF",
						"#FF6384",
						"#4BC0C0",
					],
					borderWidth: 1,
			  }
			: null,
		projetos[0] != null
			? {
					data: projetos,
					backgroundColor: [
						"#FF9F40",
						"#FFCE56",
						"#36A2EB",
						"#9966FF",
						"#FF6384",
						"#4BC0C0",
					],
					borderWidth: 1,
			  }
			: null,
	];
	const horizontalBarData = {
		labels: revendas,
		datasets: datasets.filter((dataset) => dataset !== null), // Remove null values
	};
	useEffect(() => {
		if (!horizontalBarChartRef.current) {
			const ctx = chartRef.current.getContext("2d");

			// Chart.js configuration
			const config = {
				type: "bar",
				data: horizontalBarData,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						x: {
							stacked: true,
						},
						y: {
							stacked: true,
							ticks: {
								suggestedMax: 10000000,
								callback: (value) => {
									const formattedValue = new Intl.NumberFormat("pt-BR", {
										maximumSignificantDigits: 3,
									}).format(value + 1);
									return formattedValue;
								},
							},
						},
					},
					indexAxis: "y",
					plugins: {
						legend: {
							display: false,
							position: "right",
							align: "center",
						},
						datalabels: {
							display: {
								font: {
									size: 6,
								},
							},
							formatter: (value, context) => {
								
								return revendas[context.dataIndex];
							},
							align: "center", // Adjust the alignment as needed
							anchor: "center", // Adjust the anchor position as needed
							offset: 4, // Adjust the offset distance from the bar as needed
						},
					},
				},
			};

			horizontalBarChartRef.current = new Chart(ctx, config);
		} else {
			// Update the chart data
			horizontalBarChartRef.current.data = horizontalBarData;
			horizontalBarChartRef.current.update();
		}

		return () => {
			if (horizontalBarChartRef.current) {
				horizontalBarChartRef.current.destroy();
				horizontalBarChartRef.current = null;
			}
		};
	}, [horizontalBarData]);

	return (
		<div className="relative flex-none flex-col items-center justify-center bg-[#f4f7fd] w-[200px] dark:bg-[#20212c] w-[400px] h-[290px]  rounded-lg text-center shadow-lg hover:scale-110 duration-500 ease-in-out hover:text-[#3A007E] mt-2">
			Top 10 {name}{" "}
			{type === 0 ? "p/valor" : type === 1 ? "p/projetos" : "p/potencia"}
			<select
				value={selectedMonth}
				onChange={(e) => {
					setSelectedMonth(e.target.value);
				}}
				className="select-status ml-2 pr-8 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  focus:outline-[#3A007E] outline-none"
			>
				<option value=""></option>
				{monthArray.map((month) => (
					<option className="status-options" key={month} value={month}>
						{month}
					</option>
				))}
			</select>
			<select
				value={selectedYear}
				onChange={(e) => setSelectedYear(e.target.value)}
				className=" flex-none	 select-status ml-2 pr-8 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  focus:outline-[#3A007E] outline-none"
			>
				<option value=""></option>
				{yearsArray.map((year) => (
					<option className="status-options" key={year} value={year}>
						{year}
					</option>
				))}
			</select>
			<div
				className=" w-[400px] h-[250px] max-h-[260px] rounded-lg text-center mt-2 "
				onClick={onClick}
			>
				<canvas
					ref={chartRef}
					style={{ border: "0", width: "300px", height: "300px" }}
				></canvas>
			</div>
		</div>
	);
};

export default HorizontalBarChartCidade;
