import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import utils from "../utils";

const MultipleLineChart = ({ chartData, onClick, type }) => {

  const getCurrentMonth = () => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr',
      'May', 'Jun', 'Jul', 'Aug',
      'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const currentDate = new Date();
    const currentMonth = months[currentDate.getMonth()];
    return currentMonth;
  };
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [activeDataset, setActiveDataset] = useState(0); // Set the first dataset as active
  const monthArray = [
    "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez",
  ];
  const currentYear = new Date().getFullYear();

  // Function to convert month string to number
  const getMonthNumber = (monthName) => {
    const monthMap = {
      Jan: 1, Fev: 2, Mar: 3, Abr: 4, Mai: 5, Jun: 6, Jul: 7, Ago: 8, Set: 9, Out: 10, Nov: 11, Dez: 12,
    };
    return monthMap[monthName] || 1; // Default to 1 if the month name is not found
  };

  // Function to get total values for a specific dataset
  const getTotalValuesForDataset = (dataset, dayIndex) => {
    const matchingActivity = dataset.find((activity) => activity.day === dayIndex + 1);
    return matchingActivity ? parseFloat(matchingActivity.total_value) : 0;
  };
  const newLegendClickHandler = function (e, legendItem, legend) {
    let datasetIndex = legendItem.datasetIndex;
    let ci = legend.chart, metaSets = [];

    for (let i = 0; i < legend.chart.data.datasets.length; i++) {
      metaSets.push(ci.getDatasetMeta(i));
    }

    metaSets.forEach(function (meta) {
      meta.hidden = meta.index === datasetIndex ? false : true;
    });
    setActiveDataset(datasetIndex); // Update the activeDataset state on legend click
    ci.update();
  };

  const options = {
    plugins: {
          legend: {
            onClick: newLegendClickHandler
        },
      datalabels: {
        font: {
          size: 9,
        },
        display: true,
        color: "white",
        anchor: "end",
        align: "end",
        formatter: (value, context) => {
          const index = context.datasetIndex;
          return (value === 0 || (activeDataset !== null && activeDataset !== index)) 
            ? '' 
            : (type === "number" || value !== 0 ? utils.formatNumberChart(value) : value);
        },
      },
    },
  };
    

  // Component logic goes here
  const DataMonth = {
    labels: Array.from({ length: 30 }, (_, index) => (index + 1).toString()),
    datasets: Object.entries(chartData).map(([datasetName, dataset], index) => ({
      label: datasetName,
      data: Array.from({ length: 30 }, (_, dayIndex) => {
        return getTotalValuesForDataset(dataset, dayIndex);
      }),
      fill: false,
      borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      borderWidth: 2,
      hidden: activeDataset !== null && activeDataset !== index,
    })),
  };


  

  return (
    // JSX for rendering the line chart
    <div className="bg-[#f4f7fd] dark:bg-[#20212c] max-h-[300px] w-[400px] rounded-lg text-center shadow-lg hover:scale-110 duration-500 ease-in-out hover:text-[#3A007E] mt-2">
      Faturamento/Projetos/Potencia
      <select
        value={selectedMonth}
        onChange={(e) => {
          setSelectedMonth(e.target.value);
          onClick(getMonthNumber(e.target.value));
        }}
        className="select-status ml-2 pr-8 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  focus:outline-[#3A007E] outline-none"
      >
        <option value=""></option>
        {monthArray.map((month) => (
          <option className="status-options" key={month} value={month}>
            {month}
          </option>
        ))}
      </select>

      <Line data={DataMonth} options={options} height={200} width={300} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default MultipleLineChart;
