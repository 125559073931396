import React, { useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import TaskLeadModal from "../modals/TaskLeadModal";
import Input from "./Input.tsx";

const DigitalSearch = ({ data, colIndex, taskIndex, onChange, filter, onSearch }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
	const [selectedLead, setSelectedLead] = useState(null);

	const inputRef = useRef(null);

	const handleSearch = (e) => {
		const term = e.target.value;
		setSearchTerm(term);
		// Case-insensitive search in task names using the dynamic filter prop
		const filteredResults = term
			? data.columns.flatMap((column) =>
					column.tasks.filter((task) =>
						task[filter].toLowerCase().includes(term.toLowerCase()),
					),
			  )
			: null;

		setSearchResults(filteredResults);
	};

	const handleLeadClick = (lead) => {
		setSelectedLead(lead);
		setIsTaskModalOpen(true);
		// Clear the input and reset the search results when a lead is selected
		setSearchTerm("");
		setSearchResults(null); // Set searchResults to null
	};

	const handleSearchIconClick = () => {
		inputRef.current.focus();
		onSearch(searchTerm, filter);
	};

	return (
		<div className="absolute top-20 stiked rounded-md z-100 hidden md:table duration-500 ease-in-out mt-2">
			<div className="flex items-center rounded-md hover:scale-105 duration-500 ease-in-out" onClick={handleSearchIconClick}>
				<Input
					type="text"
					labelText="Pesquisar"
					value={searchTerm}
					onChange={handleSearch}
					ref={inputRef}
					className="p-1 md:w-[110px] w-full rounded-md dark:bg-[#2b2c37] bg-white shadow-[#364e7e1a] shadow-lg hover:text-[#3A007E] dark:text-white dark:hover:text-[#3A007E] cursor-pointer"
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							onSearch(searchTerm, filter);
						}
					}}
				/>
				<div onClick={handleSearchIconClick}>
					<FaSearch className="ml-1 mr-2 text-[#3A007E]" />
				</div>
			</div>
			<ul className="absolute md:w-[125px] bg-white dark:bg-[#2b2c37] duration-500 ease-in-out">
				{searchResults !== null &&
					searchResults.map((result, index) => (
						<li
							key={index}
							onClick={() => handleLeadClick(result)}
							className="text-sm hover:opacity-80 dark:text-white cursor-pointer hover:text-[#3A007E]"
						>
							{result.NOME_LEAD}
						</li>
					))}
			</ul>
			{isTaskModalOpen && selectedLead && (
				<TaskLeadModal
					colIndex={selectedLead.STATUS_LEAD}
					taskIndex={selectedLead.ID_LEAD}
					lead={selectedLead}
					setIsTaskModalOpen={setIsTaskModalOpen}
					onCloseModal={() => {
						onChange();
						setSelectedLead(null); // Reset selected lead when the modal is closed
						setSearchTerm(""); // Clear the input when the modal is closed
					}}
				/>
			)}
		</div>
	);
};

export default DigitalSearch;
