import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto"; // Import 'chart.js/auto' to ensure that all necessary modules are included
import "chartjs-plugin-datalabels";
import utils from "../utils";
const PieChart = ({ chartData, onClick,name }) => {
	const [selectedMonth, setSelectedMonth] = useState(""); // State to track the selected month
	const [selectedYear, setSelectedYear] = useState(2024);
	const chartRef = useRef(null);
	const doughnutChartRef = useRef(null);
	const monthArray = [
		"Jan",
		"Fev",
		"Mar",
		"Abr",
		"Mai",
		"Jun",
		"Jul",
		"Ago",
		"Set",
		"Out",
		"Nov",
		"Dez",
	];
	useEffect(() => {
		onClick(selectedYear,getMonthNumber(selectedMonth));
	}, [selectedYear,selectedMonth]);

	const getMonthNumber = (monthName) => {
		const monthMap = {
			Jan: 1,
			Fev: 2,
			Mar: 3,
			Abr: 4,
			Mai: 5,
			Jun: 6,
			Jul: 7,
			Ago: 8,
			Set: 9,
			Out: 10,
			Nov: 11,
			Dez: 12,
		};
		return monthMap[monthName] || 0; // Default to 1 if the month name is not found
	};
	const yearsArray = [
		2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034,
		2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046,
		2047, 2048, 2049, 2050,
	];

	const data = chartData && chartData.length > 0 ? chartData[0] : {};

// ...

const pieData = {
	labels: Object.keys(data) || [],
	datasets: [
	  {
		data: Object.values(data).map(value => utils.formatfloat(value)),
		backgroundColor: [
		  "#36A2EB", // Blue
		  "#FFCE56", // Yellow
		  "#FF9F40", // Orange
		  "#9966FF", // Purple
		  "#FF6384", // Red
		  "#4BC0C0", // Green
		],
		borderWidth: 1,
	  },
	],
  };
  
  // ...
  
  useEffect(() => {
    if (!doughnutChartRef.current) {
      const ctx = chartRef.current.getContext("2d");
  
      // Chart.js configuration
      const config = {
        type: "doughnut",
        data: pieData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            arc: {
              borderWidth: 0,
              borderColor: "#333",
            },
          },
          plugins: {
            legend: {
              display: true,
              position: "right", // Change the position as needed (e.g., "top", "bottom", "left")
              align: "center",   // Adjust the alignment as needed (e.g., "center", "start", "end")
            },
            datalabels: {
              display: false, // Set to true if you want to display data labels on the chart
            },
          },
        },
      };
  
      doughnutChartRef.current = new Chart(ctx, config);
    } else {
      // Update the chart data
      doughnutChartRef.current.data = pieData;
      doughnutChartRef.current.update();
    }
  
    return () => {
      if (doughnutChartRef.current) {
        doughnutChartRef.current.destroy();
        doughnutChartRef.current = null;
      }
    };
  }, [pieData]);
	return (
		<div className="relative flex-none flex-col items-center justify-center bg-[#f4f7fd] w-[200px] dark:bg-[#20212c] w-[400px] h-[290px]  rounded-lg text-center shadow-lg hover:scale-110 duration-500 ease-in-out hover:text-[#3A007E] mt-2">
			{name != "Expirados" ? "Taxa de Conversão " + name : name}
			<select
				value={selectedMonth}
				onChange={(e) => {
					setSelectedMonth(e.target.value);
					onClick(getMonthNumber(e.target.value), selectedYear);
				}}
				className="select-status ml-2 pr-8 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  focus:outline-[#3A007E] outline-none"
			>
				<option value=""></option>
				{monthArray.map((month) => (
					<option className="status-options" key={month} value={month}>
						{month}
					</option>
				))}
			</select>
      <select
				value={selectedYear}
				onChange={(e) => setSelectedYear(e.target.value)}
				className=" flex-none	 select-status ml-2 pr-8 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  focus:outline-[#3A007E] outline-none"
			>
				<option value=""></option>
				{yearsArray.map((year) => (
					<option className="status-options" key={year} value={year}>
						{year}
					</option>
				))}
			</select>
			<div className=" w-[400px] h-[200px] max-h-[200px] rounded-lg text-center mt-6 ">
				<canvas
					id="customDoughnutChart"
					ref={chartRef}
					style={{ border: "0", width: "300px", height: "300px" }}
				></canvas>
			</div>
		</div>
	);
};

export default PieChart;
