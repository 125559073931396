import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import boardsSlice from "../redux/boardsSlice";
import utils from "../utils";
import AxiosService from "../axios";
import AddEditActiveModal from "../modals/AddEditActiveModal";

function ActionLead({ index, taskIndex, colIndex, action, complet, onClick, }) {
	const subtask = action.find((action, i) => i === taskIndex);
	const [checked, setChecked] = useState(complet);
	const [isActionModalOpen, setIsActionModalOpen] = useState(false);

	const handleClick = () => {
		// Call the onClick function from props and pass the value
		onClick(subtask);
	};
	function formatToBRDate(timestampStr) {
		const timestampDate = new Date(timestampStr);
		const brFormattedDate = timestampDate.toLocaleDateString("pt-BR", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
		return brFormattedDate;
	}
	const token = useSelector((state) => state.auth.token);

	const setToken = (token) => {
		AxiosService.setAuthorizationToken(token);
	};
	setToken(token);
	const checkedTrue = async () => {
		if (checked != complet) {
			await AxiosService.get(`/atividadeLead/find/?conditions[]=ID_ATIVIDADE&values[]=${subtask.ID_ATIVIDADE}`)
				.then((response) => {
					setChecked(response.data[0].CONFERIDO != 0 ? true : false);
				})
				.catch((error) => {
					console.error("Error:", error);
				});
		}
	};
	checkedTrue();

	const onChangeAction = (e) => {
		setChecked(e.target.checked);
		const data = {
			idAtividade: subtask.ID_ATIVIDADE, // Replace with the actual ID_PROJ value
			conferido: e.target.checked != false ? 1 : 0, // Replace with the actual completada value
		};

		const endpoint = "/atividadeLead/completAtividade";

		AxiosService.post(endpoint, data)
			.then((response) => {
				// Handle successful response data
			})
			.catch((error) => {
				console.error("POST Request Error:", error);
				// Handle errors
			});
		// Handle the response or perform actions after successful post
	};

	return (
		<li
			key={index}
			className="p-2 rounded-lg shadow-md fit-content text-justify first:my-2 rounded-lg w-full  bg-[#f4f7fd]  dark:bg-[#20212c] shadow-[#364e7e1a] py-3  px-3 shadow-lg hover:text-[#3A007E] dark:text-white dark:hover:text-[#3A007E] hover:scale-105 cursor-pointer   duration-500 ease-in-out"
		>
			<div className="flex flex-row justify-between">
				<div className="flex flex-row relative items-center justify-start text-center ">
					<input
						className="w-4 h-4 accent-[#3A007E] cursor-pointer mr-2 "
						type="checkbox"
						checked={checked}
						onChange={onChangeAction}
					/>
					<span
						onClick={handleClick}
						className={
							checked != true
								? "font-bold tracking-wide text-xs"
								: "font-bold tracking-wide  text-center justify-center text-xs line-through opacity-30 "
						}
					>
						{subtask.ATIVIDADE}
					</span>
				</div>
				{/* <div
					className="hidden md:flex flex-col space-y-1 "
					onClick={handleClick}
				>
					<p
						className={
							checked != true
								? " font-bold tracking-wide text-xs"
								: "font-bold tracking-wide  text-center justify-center text-xs line-through opacity-30 "
						}
					>
						{subtask.OBSERVACOES}
					</p>

				</div> */}

				<div className="md:flex flex-col  space-y-1" onClick={handleClick}>
				
					<p
						className={
							checked != true
								? " font-bold tracking-wide text-xs"
								: "font-bold tracking-wide  text-center justify-center text-xs line-through opacity-30 "
						}
					>
						Responsavel: {subtask.NOME_USUARIO}
					</p>
				</div>
			</div>
		</li>
	);
}

export default ActionLead;
