import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@headlessui/react";
import boardIcon from "../assets/icon-board.svg";
import useDarkMode from "../hooks/useDarkMode";
import darkIcon from "../assets/icon-dark-theme.svg";
import lightIcon from "../assets/icon-light-theme.svg";

import showSidebarIcon from "../assets/icon-show-sidebar.svg";
import hideSidebarIcon from "../assets/icon-hide-sidebar.svg";

import boardsSlice from "../redux/boardsSlice";
import AddEditBoardModal from "../modals/AddEditBoardModal";
import { useFetchDataFromApiQuery } from "../redux/ApiSlice"; // Replace with the correct path to your boardsSlice file

function Sidebar({ isSideBarOpen, setIsSideBarOpen, onChange }) {
	const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
	const [colorTheme, setTheme] = useDarkMode();
	const [darkSide, setDarkSide] = useState(
		colorTheme === "light" ? true : false,
	);
	const [boards, setBoards] = useState([]);

	const toggleDarkMode = (checked) => {
		setTheme(colorTheme);
		setDarkSide(checked);
	};

	const toggleSidebar = () => {
		setIsSideBarOpen((curr) => !curr);
	};
	const { isLoading, isError, isSuccess, data, isUninitialized } =
		useFetchDataFromApiQuery();

	// Perform any other actions or update UI as needed
	// For example, you might trigger a re-render of the boards
	// or send updated data to an API

	// Log the updated boards to the console

	useEffect(() => {
		if (isSuccess) {
			setBoards(data.boards);
		}
	}, [isSuccess, data]);

	const handleClick = (boardId) => {
		const updatedBoards = boards.map((board) => {
			if (board.id === boardId) {
				return { ...board, isActive: true };
			} else {
				return { ...board, isActive: false };
			}
		});
		setBoards(updatedBoards);
	};
	return (
		<div>
			<div
				className={
					isSideBarOpen
						? `min-w-[261px] bg-white dark:bg-[#2b2c37]  fixed top-[72px] h-screen  items-center left-0 z-20`
						: ` bg-gradient-to-l  from-[#30214b] to-[#181224]  dark:bg-[#2b2c37] dark:hover:bg-gradient-to-l  from-[#30214b] to-[#181224] top-auto bottom-10 justify-center items-center hover:opacity-80 cursor-pointer  p-0 transition duration-300 transform fixed felx w-[56px] h-[48px] rounded-r-full  `
				}
			>
				<div>
					{/* reWrite modal  */}

					{isSideBarOpen && (
						<div className=" bg-white  dark:bg-[#2b2c37]    w-full   py-4 rounded-xl">
							<h3 className=" dark:text-gray-300 text-gray-600 font-semibold mx-4 mb-8 ">
								TODOS OS BOARDS ({boards?.length})
							</h3>

							<div className="  dropdown-borad flex flex-col h-[70vh]  justify-between ">
								<div>
									{boards.map((board, index) => (
										<div
											className={` flex items-baseline space-x-2 px-5 mr-8 rounded-r-full duration-500 ease-in-out py-4 cursor-pointer hover:bg-[#635fc71a] hover:text-cyan-100 dark:hover:bg-white dark:hover:text-cyan-100 dark:text-white  ${
												board.isActive &&
												"bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-r-full text-white mr-8 "
											} `}
											key={index}
											onClick={() => {
												handleClick(board.id);
												onChange(board.id);
											}}
										>
											<img src={boardIcon} className="  filter-white  h-4 " />{" "}
											<p className=" text-lg font-bold ">{board.name}</p>
										</div>
									))}

									{/* <div
                    className=" flex  items-baseline space-x-2  mr-8 rounded-r-full duration-500 ease-in-out cursor-pointer text-[#635fc7] px-5 py-4 hover:bg-[#635fc71a] hover:text-[#635fc7] dark:hover:bg-white  "
                    onClick={() => {
                      setIsBoardModalOpen(true);
                    }}
                  >
                    <img src={boardIcon} className="   filter-white  h-4 " />
                    <p className=" text-lg font-bold  ">Crie Novo Board </p>
                  </div> */}
								</div>

								<div className=" mx-2  p-4 relative space-x-2 bg-slate-100 dark:bg-[#20212c] flex justify-center items-center rounded-lg">
									<img src={lightIcon} alt="sun indicating light mode" />

									<Switch
										checked={darkSide}
										onChange={toggleDarkMode}
										className={`${
											darkSide
												? "bg-gradient-to-l  from-[#fae24d] to-[#ed772f] "
												: "bg-gray-200"
										} relative inline-flex h-6 w-11 items-center rounded-full`}
									>
										<span
											className={`${
												darkSide ? "translate-x-6" : "translate-x-1"
											} inline-block h-4 w-4 transform rounded-full bg-white transition`}
										/>
									</Switch>

									<img src={darkIcon} alt="moon indicating dark mode" />
								</div>
							</div>
						</div>
					)}

					{/* Sidebar hide/show toggle */}
					{isSideBarOpen ? (
						<div
							onClick={() => toggleSidebar()}
							className=" flex  items-center mt-2  absolute bottom-16  text-lg font-bold  rounded-r-full hover:text-white cursor-pointer mr-6 mb-8 px-8 py-4 hover:bg-gradient-to-l  from-[#fae24d] to-[#ed772f]  dark:hover:bg-white  space-x-2 justify-center  my-4 text-gray-500 "
						>
							<img
								className=" min-w-[20px]"
								src={hideSidebarIcon}
								alt=" side bar show/hide"
							/>
							{isSideBarOpen && <p> Hide Sidebar </p>}
						</div>
					) : (
						<div className=" absolute p-5 " onClick={() => toggleSidebar()}>
							<img src={showSidebarIcon} alt="showSidebarIcon" />
						</div>
					)}
				</div>
			</div>

			{isBoardModalOpen && (
				<AddEditBoardModal
					type="add"
					setIsBoardModalOpen={setIsBoardModalOpen}
				/>
			)}
		</div>
	);
}

export default Sidebar;
