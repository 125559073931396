import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import crossIcon from "../assets/icon-cross.svg";
import boardsSlice from "../redux/boardsSlice";
import AxiosService from "../axios";
import DeleteModal from "./DeleteModal";
import { MdAddTask, MdDeleteOutline } from "react-icons/md";
import { Store } from "react-notifications-component";

function AddEditActiveDistribuidoraModal({
	type,
	device,
	setIsTaskModalOpen,
	setIsAddTaskModalOpen,
	taskIndex,
	prevColIndex = 0,
	colIndex,
	action,
	onClose,
}) {
	const dispatch = useDispatch();
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [isValid, setIsValid] = useState(true);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [contactType, setContactType] = useState("");
	const [contact, setContact] = useState("");
	const [date, setDate] = useState("");

	const task = action;

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const handleDateChange = (e) => {
		setDate(e.target.value); // Update the state with the selected date value
		console.log(e.target.value);
	};

	const onChangeStatus = (e) => {
		setContactType(e.target.value);
	};

	const validate = () => {
		setIsValid(false);
		if (!title.trim()) {
			return false;
		}
		setIsValid(true);
		return true;
	};

	if (type === "edit" && isFirstLoad) {
		const currentDate = new Date(action.DATA_RETORNO);
		const date = new Date(currentDate - 3 * 60 * 60 * 1000);
		const formattedDate = date.toISOString().slice(0, 16);
		setTitle(action.TIPO);
		setDescription(action.DESCRICAO);
		setContactType(action.TIPO_CONTATO);
		setContact(action.CONTATO);
		setDate(formattedDate);
		setIsFirstLoad(false);
	}

	const setOpenDeleteModal = () => {
		setIsDeleteModalOpen(true);
	};

	const onDelete = (type, id) => {
		if (type === "edit") {
            console.log(action.ID_ATIVIDADE);
			const data = {
				id: action.ID_ATIVIDADE, // Replace with the actual ID_PROJ value // Replace with the actual completada value
			};

			const endpoint = "atividadeDistribuidora/deleteAtividade";

			AxiosService.post(endpoint, data)
				.then((response) => {
					console.log("POST Request Success:", response.data);
					// Handle successful response data
				})
				.catch((error) => {
					console.error("POST Request Error:", error);
					// Handle errors
				});
		}
		Store.addNotification({
			title: "Deletada!",
			message: "Atividade deletada!",
			type: "danger",
			insert: "top",
			container: "top-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 2500,
				onScreen: true,
			},
		});
	};

	const onSubmit = (type) => {
		if (type === "add") {
			const endpoint = "atividadeDistribuidora/createAtividade";
			const postData = {
				descricao: description,
				tipo: title,
				dataRetorno: date.replace("T", " "),
				tipoContato: contactType,
				contato: contact,
				// Other fields as needed
			};

			AxiosService.post(endpoint, postData)
				.then((response) => {
					console.log("POST Request Success:", response.data);
					// Handle successful response data
				})
				.catch((error) => {
					console.error("POST Request Error:", error);
					// Handle errors
				});
		} else {
			const endpoint = "atividadeDistribuidora/updateAtividade";
			const postData = {
				id: action.ID_ATIVIDADE,
				descricao: description,
				tipo: title,
				dataRetorno: date.replace("T", " "),
				tipoContato: contactType,
				contato: contact,
				// Other fields as needed
			};

			AxiosService.post(endpoint, postData)
				.then((response) => {
					console.log("POST Request Success:", response.data);
					// Handle successful response data
				})
				.catch((error) => {
					console.error("POST Request Error:", error);
					// Handle errors
				});

			Store.addNotification({
				title: "Salvo!",
				message: "Atividade salva!",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 2500,
					onScreen: true,
				},
			});
		}
	};

	const contactList = [
		{ name: "Telefone" },
		{ name: "Email" },
		{ name: "Whatsapp" },
		{ name: "Telegram" },
	];

	return (
		<div
			className={
				device === "mobile"
					? "  py-6 px-6 pb-40  absolute overflow-y-scroll  left-0 flex  right-0 bottom-[-100vh] top-0 dropdown "
					: "  py-6 px-6 pb-40  absolute overflow-y-scroll  left-0 flex  right-0 bottom-0 top-0 dropdown "
			}
			onClick={(e) => {
				if (e.target !== e.currentTarget) {
					return;
				}
				setIsAddTaskModalOpen(false);
			}}
		>
			{/* Modal Section */}

			<div
				className="  relative scrollbar-hide overflow-y-scroll max-h-[95vh] my-auto bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold
       shadow-md shadow-[#364e7e1a] max-w-[800px] w-[600px] mx-auto px-8  py-8 rounded-xl"
			>
				{" "}
				<div>
					<h1 className=" text-3xl text-center hover:scale-105 hover:text-[#3A007E] duration-500 ease-in-out">
						{type === "edit" ? "Editar" : "Adicionar Nova Atividade"}
					</h1>
				</div>
				{/* Task Name */}
				<div className="mt-8 flex flex-col space-y-1 ">
					<label className="  text-sm dark:text-white text-gray-500 py-2">
						Nome da Atividade
					</label>
					<input
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						id="task-name-input"
						type="text"
						maxLength={25}
						className=" bg-transparent  px-4 py-2 outline-none focus:border-0 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-1  ring-0  focus:scale-105 hover:text-[#3A007E] duration-500 ease-in-out "
						placeholder=""
					/>
				</div>
				{/* Description */}
				<div className="mt-2 flex flex-col space-y-1">
					<label className="  text-sm dark:text-white text-gray-500 py-2">
						Descrição
					</label>
					<textarea
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						id="task-description-input"
						className=" bg-transparent outline-none min-h-[100px] focus:border-0 px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-[1px] focus:scale-105 hover:text-[#3A007E] duration-500 ease-in-out "
						placeholder="Descrição da tarefa"
					/>
					<label className="text-sm dark:text-white text-gray-500 py-2">
						Tipo de contato
					</label>
					<select
						value={contactType}
						onChange={onChangeStatus}
						className=" select-status flex-grow px-4 py-2 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  border-[1px] border-gray-300 focus:outline-[#3A007E] outline-none hover:outline-[#3A007E]  duration-500 ease-in-out"
					>
						{contactList.map((column, index) => (
							<option key={index}>{column.name}</option>
						))}
					</select>
				</div>
				{/* Subtasks */}
				<div className="mt-2 flex flex-col space-y-1 ">
					<label className=" text-sm dark:text-white text-gray-500 py-2">
						Contato
					</label>
					<input
						value={contact}
						onChange={(e) => setContact(e.target.value)}
						id="task-name-input"
						type="text"
						className=" bg-transparent  px-4 py-2 outline-none focus:border-0 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-1  ring-0  focus:scale-105 hover:text-[#3A007E] duration-500 ease-in-out "
						placeholder=""
					/>
				</div>
				<div className="mt-2 flex flex-col space-y-1 text-black items-center justify-center">
					<label className="text-sm dark:text-white text-gray-500 py-2 self-start	">
						Data de retorno
					</label>
					<div className="mt-2 flex items-center justify-center ">
						{/* <Calendar
							className="mt-4 rounded-lg bg-[#f4f7fd] dark:bg-[#20212c] dark:text-white hover:scale-105 duration-500 ease-in-out border-0"
							onChange={onChange}
							value={date}
              
						/> */}
						<input
							aria-label="Date and time"
							type="datetime-local"
							className="p-2 rounded-lg bg-[#f4f7fd] dark:bg-[#20212c] dark:text-white hover:scale-105 duration-500 ease-in-out border-0"
							onChange={handleDateChange}
							value={date}
						/>
					</div>
				</div>
				{/* <div className="mt-8 flex flex-col space-y-3">
          <label className="  text-sm dark:text-white text-gray-500">
            Subtasks
          </label>

          {subtasks.map((subtask, index) => (
            <div key={index} className=" flex items-center w-full ">
              <input
                onChange={(e) => {
                  onChangeSubtasks(subtask.id, e.target.value);
                }}
                type="text"
                value={subtask.title}
                className=" bg-transparent outline-none focus:border-0 flex-grow px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-[1px]  "
                placeholder=" e.g Take coffee break"
              />
              <img
                src={crossIcon}
                onClick={() => {
                  onDelete(subtask.id);
                }}
                className=" m-4 cursor-pointer "
              />
            </div>
          ))}

          <button
            className=" w-full items-center dark:text-[#3A007E] dark:bg-white  text-white bg-[#3A007E] py-2 rounded-full hover:scale-105 duration-500 ease-in-out "
            onClick={() => {
              setSubtasks((state) => [
                ...state,
                { title: "", isCompleted: false, id: uuidv4() },
              ]);
            }}
          >
            + Add New Subtask
          </button>
        </div> */}
				{/* current Status  */}
				<div className="mt-8 flex flex-row ">
					<button
						onClick={() => {
							const isValid = validate();
							if (isValid) {
								onSubmit(type);
								setIsAddTaskModalOpen(false);
							}
						}}
						className="w-full flex items-center justify-center text-white m-2 bg-gradient-to-l from-[#fae24d] to-[#ed772f] hover:opacity-80 py-2 rounded-md  hover:scale-105 duration-500 ease-in-out "
					>
						<MdAddTask className="mr-2" />
						{type === "edit" ? " Salvar" : "Criar "}
					</button>
					<button
						className={`w-full py-2 flex items-center justify-center m-2 shadow-lg bg-red-700 hover:opacity-80 rounded-md text-center hover:scale-105 duration-500 ease-in-out ${
							type === "edit" ? "" : "hidden"
						}`}
						onClick={() => {
							setOpenDeleteModal();
						}}
					>
						{" "}
						<MdDeleteOutline className="mr-2" />
						{type === "edit" ? "Excluir" : ""}
					</button>
				</div>
			</div>
			{isDeleteModalOpen && (
				<DeleteModal
					setIsDeleteModalOpen={setIsDeleteModalOpen}
					title={"jogos"}
					onDeleteBtnClick={() => {
						onDelete(type, action.ID_ATIVIDADE);
						setIsAddTaskModalOpen(false);
						onClose();
					}}
					type="atividade"
				/>
			)}
		</div>
	);
}

export default AddEditActiveDistribuidoraModal;
