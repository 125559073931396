class utils {
	
	binarySearch(projects, target) {
		// Sort the projects array based on COD_PROJETO property
		projects.sort((a, b) => a.COD_PROJETO.localeCompare(b.COD_PROJETO));

		let left = 0;
		let right = projects.length - 1;

		while (left <= right) {
			const mid = Math.floor((left + right) / 2);
			const midValue = projects[mid]["COD_PROJETO"];

			if (midValue === target) {
				return true;
			} else if (midValue < target) {
				left = mid + 1;
			} else {
				right = mid - 1;
			}
		}

		return false;
	}

	binarySearchAll(projects, entity, target) {
		// Sort the projects array based on COD_PROJETO property

		projects.sort((a, b) => a[entity] - b[entity]);

		let left = 0;
		let right = projects.length - 1;

		while (left <= right) {
			const mid = Math.floor((left + right) / 2);
			const midValue = projects[mid][entity];

			if (midValue === target) {
				return true;
			} else if (midValue < target) {
				left = mid + 1;
			} else {
				right = mid - 1;
			}
		}

		return false;
	}
	binarySearchLead(projects, target) {
		if (!Array.isArray(projects)) {
			console.error("projects is not an array");
			return false;
		}
		// Sort the projects array based on COD_PROJETO property
		projects.sort((a, b) => a.ID_LEAD - b.ID_LEAD);

		let left = 0;
		let right = projects.length - 1;

		while (left <= right) {
			const mid = Math.floor((left + right) / 2);
			const midValue = projects[mid]["ID_LEAD"];

			if (midValue === target) {
				return true;
			} else if (midValue < target) {
				left = mid + 1;
			} else {
				right = mid - 1;
			}
		}

		return false;
	}
	
	truncateString(str, num) {
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    }

	formatNumber(number) {
		const valorAsNumber = parseFloat(number);
		// Convert the number to a currency string in Brazilian Real format
		const formattedNumber = valorAsNumber.toLocaleString("pt-BR", {
			style: "currency",
			currency: "BRL",
		});
		return formattedNumber;
	}
	 formatDate(dateString) {
		const date = new Date(dateString);
		return new Intl.DateTimeFormat('pt-BR').format(date);
	  }
	formatPhoneNumber(phoneNumber) {
		const cleanedPhoneNumber = phoneNumber.toString().replace(/\D/g, ""); // Remove non-numeric characters
		const countryCode = "+55";
		const areaCode = cleanedPhoneNumber.substring(0, 2);
		const firstPart = cleanedPhoneNumber.substring(2, 7);
		const secondPart = cleanedPhoneNumber.substring(7);

		const formattedPhoneNumber = `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
		return formattedPhoneNumber;
	}

	abbreviateName(name) {
		const words = name.split(" ");
		const initials = words.map((word) => word[0].toUpperCase()).join("");
		return initials;
	}

	formatfloat(number) {
		const valorAsNumber = parseFloat(number);
		const formattedNumber = valorAsNumber.toFixed(2);
		if (valorAsNumber >= 1000) {
			const formattedValue = (valorAsNumber / 1000).toFixed(1);
			return `${formattedValue}K`;
		  } else {
			return `${valorAsNumber.toFixed(1)}`;
		  }
		return formattedNumber;
	}
	formatCurrency(value) {
		// Check if value is a string
	  
		// Remove non-numeric characters and convert to float
		const floatValue = parseFloat(value.replace(/[^\d.]/g, ''));
	  
		// Check if the conversion resulted in a valid number
		if (isNaN(floatValue)) {
		  return "Invalid Value";
		}
	  
		if (floatValue >= 1000) {
		  const formattedValue = (floatValue / 1000).toFixed(1);
		  return `${formattedValue}K`;
		} else {
		  return `${floatValue.toFixed(0).replace('.', ',')}`;
		}
	  }
	formatarCNPJ(cnpj) {
		cnpj = cnpj.replace(/\D/g, ''); // Remove tudo o que não é dígito
		if (cnpj.length > 14) {
			cnpj = cnpj.substring(0, 14); // Garante que o CNPJ tem no máximo 14 dígitos
		}
		return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
	}
	sleep = (milliseconds) => {
		return new Promise(resolve => setTimeout(resolve, milliseconds));
	  }
	formatCNPJ(cnpj) {
		// Garante que o cnpj é uma string
		cnpj = cnpj.toString();
	
		// Remove caracteres indesejados
		cnpj = cnpj.replace(/\D/g, '');
	
		// Verifica se o cnpj tem o comprimento correto para ser formatado
		if (cnpj.length !== 14) {
			console.error('O CNPJ fornecido não tem o número correto de dígitos (14).');
			return null;
		}
	
		// Formata o CNPJ
		return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
	}
	
	formatNumberChart(value) {
		// Check if value is a number
		if (typeof value !== 'number' || isNaN(value)) {
		  return "Invalid Value";
		}
	  
		// Check if the value is an integer
		if (Number.isInteger(value)) {
		  return `${value}`;
		}
	  
		if (value >= 1000) {
		  const formattedValue = (value / 1000).toFixed(1);
		  return `${formattedValue}K`;
		} else {
		  return `${value.toFixed(0).replace('.', ',')}`;
		}
	  }
	 
	  formatNumberChartPotencia(value) {
		// Check if value is a string
	  
		// Remove non-numeric characters and convert to float
		const floatValue = parseFloat(value.replace(/[^\d.]/g, ''));
	  
		// Check if the conversion resulted in a valid number
		if (isNaN(floatValue)) {
		  return "Invalid Value";
		}
	  
		if (floatValue >= 1000) {
		  const formattedValue = (floatValue / 1000).toFixed(1);
		  return `${formattedValue}M`;
		} else {
		  return `${floatValue.toFixed(0).replace('.', ',')}`;
		}
	  }
	firstName(name) {
		const firstName = name.split(" ")[0]; // Get the first word as the first name
		return firstName;
	}
}

export default new utils();
