import React, { useState, useEffect } from "react";
import AxiosService from "../axios";

const FileGallery = ({ type, id }) => {
    const [files, setFiles] = useState([]);
    const [uploadMessage, setUploadMessage] = useState("");

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = () => {
        AxiosService.get(`/files/${type}/${id}`)
            .then((response) => {
                setFiles(response.data);
            })
            .catch((error) => {
                console.error("Error fetching files:", error);
            });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);

        AxiosService.post(`/files/add/${type}/${id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                setUploadMessage(response.data.message);
                fetchFiles();
            })
            .catch((error) => {
                console.error("Error uploading file:", error);
            });
    };

    const handleDelete = (fileId,name) => {
        AxiosService.delete(`/files/delete/${fileId}/${name}`)
            .then((response) => {
                console.log(response.data.message);
                fetchFiles();
            })
            .catch((error) => {
                console.error("Error deleting file:", error);
            });
    };

    const deleteFile = (fileId,name) => {
        handleDelete(fileId,name);
    };

    return (
        <div className="text-center justify-center">
            <input
                className="text-center justify-center"
                type="file"
                onChange={handleFileChange}
            />
            <button className="mt-2 text-white px-4 py-2 rounded-md bg-gradient-to-l from-[#fae24d] to-[#ed772f] hover:opacity-80 focus:outline-none focus:ring focus:border-blue-300">
                Salvar arquivo
            </button>

            {uploadMessage && <p>{uploadMessage}</p>}

            <div
                className="text-center justify-center scrollbar-hide"
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    overflowY: "auto",
                    maxHeight: "25vh",
                }}
            >
                {files.map((file) => (
                    <div
                        key={file.ID}
                        className="text-center justify-center mt-4"
                        style={{ textAlign: "center" }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <a
                                href={`${process.env.REACT_APP_API_HOST}${file.URL}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {file.NAME}
                            </a>
                            <button
                                onClick={() => deleteFile(file.ID,file.NAME)}
                                className="text-white ml-2 bg-red-500 rounded-md px-2 py-1 hover:opacity-80 focus:outline-none focus:ring focus:border-blue-300"
                            >
                                X
                            </button>
                        </div>
                        <br />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FileGallery;
