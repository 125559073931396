import TaskModal from "../modals/TaskModal";
import TaskFinanModal from "../modals/TaskFinanModal";

const ModalComponent = ({ lead, onClose, onChange, bordid }) => {
  const modalProps = {
    colIndex: bordid === 0 ? lead.TEMP : lead.NIVEL,
    taskIndex: lead.ID_PROJ,
    project: lead,
    setIsTaskModalOpen: onClose,
    onCloseModal: () => {
      onChange();
      onClose();
    },
  };

  return bordid === 0 ? <TaskModal {...modalProps} /> : <TaskFinanModal {...modalProps} />;
};

export default ModalComponent;