import axios from "axios";

class AxiosService {
	constructor() {
		const instance = axios.create({
			baseURL: process.env.REACT_APP_API_HOST, // Replace with your API base URL
			timeout: 100000, // Adjust timeout as needed
			headers: {
				"Content-Type": "application/json",
				// Add any other common headers here
			},
		});

		this.instance = instance;
	}

	get(url, config = {}) {
		return this.instance.get(url, config);
	}

	post(url, data = {}, config = {}) {
		return this.instance.post(url, data, config);
	}

	put(url, data = {}, config = {}) {
		return this.instance.put(url, data, config);
	}

	delete(url, config = {}) {
		return this.instance.delete(url, config);
	}

	setAuthorizationToken(token) {
		this.instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	}

	removeAuthorizationToken() {
		delete this.instance.defaults.headers.common["Authorization"];
	}
	getRevendas = async () => {
		try {
			const result = await this.instance.get("/revendas/revendas");

			return result.data.sort((a, b) =>
				a.RAZAO_SOCIAL.localeCompare(b.RAZAO_SOCIAL),
			);
		} catch (error) {
			console.log(error);
		}
	};

	getUserList = async (id) => {
		const result = await this.instance.get(`/users/`);

		return result.data.users;
	};
}

export default new AxiosService();
