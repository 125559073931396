import React, { useState } from "react";
import AxiosService from "./../axios";
import { MdOutlineAttachMoney } from "react-icons/md";
import utils from "../utils";

function DataQueryForm({onBarClick}) {
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const monthArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const yearsArray = [
        2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034,
        2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046,
        2047, 2048, 2049, 2050,
    ];

	// Function to open the modal
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await AxiosService.get(
                `/faturamentoMesIntelbras/${year}/${month}`,
            );
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setError("Failed to fetch data");
            setLoading(false);
        }
    };

    return (
        <div className="relative flex flex-col items-center justify-center bg-[#f4f7fd] dark:bg-[#20212c] w-[400px] h-[290px] rounded-lg text-center shadow-lg hover:scale-110 duration-500 ease-in-out mt-2">
            <form onSubmit={handleSubmit}>
            <p className="font-bold text-xl">Faturamento do dia 21 ao dia 20</p>

                <select
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    className="select-status ml-2 pr-8 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0 focus:outline-[#3A007E] outline-none"
                >
                    <option value=""></option>
                    {monthArray.map((month, index) => (
                        <option className="status-options" key={month} value={month}>
                            {month}
                        </option>
                    ))}
                </select>
                <select
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    className="select-status ml-2 pr-8 rounded-md dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0 focus:outline-[#3A007E] outline-none"
                >
                    <option value=""></option>
                    {yearsArray.map((year) => (
                        <option className="status-options" key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
                <button type="submit" disabled={loading}>
                    {loading ? "Carregando..." : "Buscar"}
                </button>
            </form>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {data && (
                <div className="flex items-center justify-center w-full p-4" onClick={()=>{onBarClick(year,month)}}>
                    <div className="bg-gradient-to-l from-[#30214b] to-[#181224] rounded-lg shadow-lg p-2">
                        <p className="font-bold">
                            <span className="flex items-center justify-center">
                                {data[0].TotalDaSoma != null
                                    ? utils.formatNumber(data[0].TotalDaSoma)
                                    : "R$ 0"}
                            </span>
                        </p>
                    </div>
                </div>
            )}
            	
        </div>
    );
}

export default DataQueryForm;
