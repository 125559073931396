class ExpiringLocalStorage {
    setWithExpiry(key, value, expirationInMinutes) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + expirationInMinutes * 60 * 1000 // Calculate expiration time
      };
      localStorage.setItem(key, JSON.stringify(item));
    }
  
    getWithExpiry(key) {
      const itemString = localStorage.getItem(key);
      if (!itemString) {
        return null;
      }
      const item = JSON.parse(itemString);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key); // Remove item if expired
        return null;
      }
      return item.value;
    }

    getWithOutExpiry(key) {
      const itemString = localStorage.getItem(key);
      if (!itemString) {
        return null;
      }
      const item = JSON.parse(itemString);
  
      return item.value;
    }
  }

  export default new ExpiringLocalStorage();
