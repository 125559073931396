import React, { useState, useEffect, memo } from "react";
import TaskModal from "../modals/TaskModal"; // Ensure the path is correct
import { MdTimer } from "react-icons/md";
import { useSelector } from "react-redux";
import AxiosService from "../axios";
import { shuffle } from "lodash";
import utils from "../utils";

const TaskRow = memo(({ task, colIndex, taskIndex, onChange, tasks }) => {
    const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
    const [isExpiredApi, setExpiredApi] = useState(false);
    const [isExpiringApi, setExpiringApi] = useState(false);
    const [haveAtividade, setHaveAtividade] = useState(false);
    const [lastUser, setLastUser] = useState(false);
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.auth.user);

    const setToken = (token) => {
        AxiosService.setAuthorizationToken(token);
    };

    function abbreviateName(name) {
        const words = name.split(" ");
        const initials = words.map((word) => word[0].toUpperCase()).join("");
        return initials;
    }

    const colors = [
        "bg-red-500",
        "bg-orange-500",
        "bg-blue-500",
        "bg-purple-500",
        "bg-green-500",
        "bg-indigo-500",
        "bg-yellow-500",
        "bg-pink-500",
        "bg-sky-500",
    ];
    const today = new Date();
    const [color, setColor] = useState(null);

    const fetchUpdatedTaskList = async () => {
        try {
            const ID_PROJ = task.ID_PROJ;
            let count = 0;
            const response = await AxiosService.get(`/atividadesfunilbyid/${ID_PROJ}`);
            const greatestIdActivityObject = response.data.reduce((prev, current) => {
                return prev.ID_ATIVIDADE > current.ID_ATIVIDADE ? prev : current;
            });
            setLastUser(abbreviateName(greatestIdActivityObject.NOME_USUARIO));
            response.data.some((item) => {
                const dueDate = new Date(item.DATA_RETORNO);
                if (item.COMPLETADA == 0) {
                    if (dueDate < today) {
                        setExpiredApi(true);
                        count++;
                        return true;
                    } else setExpiredApi(false);
                    count++;
                }
            });
            response.data.some((item) => {
                const dueDate = new Date(item.DATA_RETORNO);
                if (item.COMPLETADA == 0) {
                    if (dueDate.toDateString() === today.toDateString()) {
                        setExpiringApi(true);
                        count++;
                        return true;
                    } else setExpiringApi(false);
                    count++;
                }
            });
            response.data.some(async (item) => {
                if (item.COMPLETADA == 0) {
                    setHaveAtividade(true);
                    await checkExp();
                    count++;
                }
            });
            if (count === 0) {
                setHaveAtividade(false);
                setExpiringApi(false);
                setExpiredApi(false);
            }
        } catch (error) {
            console.error("Error fetching updated project list:", error);
        }
    };

    const checkExp = async () => {
        console.log(isExpiredApi, isExpiringApi, haveAtividade);

        if (isExpiredApi && isExpiringApi && haveAtividade) {
            setExpiringApi(false);
            setHaveAtividade(false);
        } else if (isExpiredApi && haveAtividade) {
            setHaveAtividade(false);
        } else if (isExpiringApi && haveAtividade) {
            setHaveAtividade(false);
        } else if (haveAtividade) {
            setHaveAtividade(true);
        } else {
            setHaveAtividade(true);
        }
    };

    useEffect(() => {
        setColor(shuffle(colors).pop());
    }, []);

    useEffect(() => {
        const getData = async () => {
            fetchUpdatedTaskList();
        };
        setToken(token);
        getData();
    }, [tasks]);

    return (
        <>
            <tr
                key={taskIndex}
                onClick={() => setIsTaskModalOpen(true)}
                className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 hover:scale-105 hover:text-[#3A007E] text-gray-500 duration-300 ease-in-out bg-white dark:bg-[#20212c]"
            >
                <td className="px-6 py-4 whitespace-nowrap text-sm ">
                    {task.COD_PROJETO}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm ">
                    {utils.truncateString(task.REVENDA,45)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm" >
                    {`${task.POTENCIA} kwp`}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm ">
                    {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    }).format(parseFloat(task.VALOR))}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm ">
                    {utils.truncateString(task.CLIENTE,40)}
                </td>
                <td className="flex px-6 py-4 whitespace-nowrap text-sm ">
                    {lastUser && (
                        <div
                            className={`text-center justify-center top-2 right-10 w-7 h-7 font-bold rounded-full p-2 ${color}`}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <p className="text-xs text-gray-700 p-2">
                                {lastUser}
                            </p>
                        </div>
                    )}
                    {haveAtividade && (
                        <MdTimer className=" w-7 h-7 font-bold text-gray-400" />
                    )}
                    {isExpiringApi && (
                        <MdTimer className=" w-7 h-7 font-bold text-amber-400" />
                    )}
                    {isExpiredApi && (
                        <MdTimer className=" w-7 h-7 font-bold text-red-700" />
                    )}
                </td>
            </tr>

            {isTaskModalOpen && (
                <TaskModal
                    colIndex={colIndex}
                    taskIndex={taskIndex}
                    project={task}
                    setIsTaskModalOpen={setIsTaskModalOpen}
                    onCloseModal={() => {
                        onChange();
                        setIsTaskModalOpen(false); // Ensure modal is closed after changes
                    }}
                />
            )}
        </>
    );
});

export default TaskRow;
