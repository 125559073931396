import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "chartjs-plugin-datalabels";

const DoughNutChart = ({ chartData }) => {
	const chartRef = useRef(null);
	const doughnutChartRef = useRef(null);
	const meta = chartData;

	const gradientFromColor = "#06b6d4"; // Replace with your Tailwind 'from' color
	const gradientToColor = "#008743"; // Replace with your Tailwind 'to' color

	useEffect(() => {
		if (!doughnutChartRef.current) {
			const ctx = chartRef.current.getContext("2d");

			const gradient = ctx.createLinearGradient(0, 0, 100, 0); // Create a horizontal gradient
			gradient.addColorStop(0.5, gradientToColor); // Start color position (50%)
			gradient.addColorStop(1, gradientFromColor); // End color position (100%)
			let dataToRender;
			if (meta > 100) {
				dataToRender = [meta]; // Render only meta if it's greater than 100
			} else {
				dataToRender = [meta, 100 - meta]; // Render both meta and 100 - meta
			}

			const data = {
				datasets: [
					{
						label: "%",
						data: dataToRender, // Example data values
						backgroundColor: [gradient, "rgb(75 85 99)"], // Example colors
					},
				],
			};

			// Chart.js configuration
			const config = {
				type: "doughnut",
				data: data,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					elements: {
						arc: {
							borderWidth: 0, // <-- Set this to desired value
							borderColor: "#333",
						},
					},
				},
			};

			doughnutChartRef.current = new Chart(ctx, config);
		} else {
			// Update the chart data
			doughnutChartRef.current.data = chartData;
			doughnutChartRef.current.update();
		}

		return () => {
			if (doughnutChartRef.current) {
				doughnutChartRef.current.destroy();
				doughnutChartRef.current = null;
			}
		};
	}, [chartData, meta]);

	return (
		<div
			style={{
				position: "relative",
			}}
			className="max-h-[120px] h-[220px] w-[150px] rounded-lg text-center mx-auto"
		>
			<h2 style={{ textAlign: "center" }}>Meta trimestral</h2>
			<canvas
				ref={chartRef}
				style={{ border: "0", width: "200px", height: "150px" }} // Adjust the width here
				className=""
			></canvas>
			<div
				className="items-center justify-center"
				style={{
					position: "absolute",
					top: "73%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					textAlign: "center",
					color: "black", // Set the color for the text
				}}
			>
				<div className="rounded-full text-black dark:text-white ">
					<p>{meta != null ? meta + "%" : "..."}</p>
				</div>
			</div>
		</div>
	);
};

export default DoughNutChart;
