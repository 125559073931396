import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "chartjs-plugin-datalabels";

const faturamentoMes = ({ value }) => {
	const payback = value;

	function formatNumber(number) {
		const valorAsNumber = parseFloat(number);
		// Convert the number to a currency string in Brazilian Real format
		const formattedNumber = valorAsNumber.toLocaleString("pt-BR", {
			style: "currency",
			currency: "BRL",
		});
		return formattedNumber;
	}

	return (
		<div className="flex flex-col space-y-1 bg-[#f4f7fd] dark:bg-[#20212c] rounded-lg  px-4 ml-1 w-[150px]  text-center shadow-lg hover:scale-110 duration-500 ease-in-out hover:text-[#3A007E]">
			<p className="flex flex-col items-center justify-center">
				Faturamento Mes
			</p>
			<div className="flex flex-col items-center justify-center py-6">
				<div className="mx-auto bg-gradient-to-l  from-[#30214b] to-[#181224] px-3 py-4 rounded-md text-white duration-500 ease-in-out">
					<p className="duration-500 ease-in-out text-xs">
						{" "}
						{payback != null ? formatNumber(payback) : "R$ 0"}
					</p>
				</div>
			</div>
		</div>
	);
};

export default faturamentoMes;
