import React, { useState, useMemo } from "react";
import TaskRow from "./TaskRow";

function TasksTable({ tasks, colIndex, onChange }) {
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "ascending",
    });

    const sortedTasks = useMemo(() => {
		let sortableTasks = [...tasks];
		if (sortConfig.key !== null) {
			sortableTasks.sort((a, b) => {
				let aValue = a[sortConfig.key];
				let bValue = b[sortConfig.key];
	
				// Check if the sorting is based on a numeric field
				if (['POTENCIA', 'VALOR'].includes(sortConfig.key)) {
					aValue = parseFloat(aValue);
					bValue = parseFloat(bValue);
				}
	
				if (aValue < bValue) {
					return sortConfig.direction === "ascending" ? -1 : 1;
				}
				if (aValue > bValue) {
					return sortConfig.direction === "ascending" ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableTasks;
	}, [tasks, sortConfig]);
	

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };

    return (
        <div className="min-w-[360px] rounded-lg overflow-hidden custom-scrollbar mx-2">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-500">
                <thead className="bg-gray-50 dark:bg-gray-900">
                    <tr>
                        {["COD_PROJETO", "REVENDA", "POTENCIA", "VALOR", "CLIENTE","RESPONSAVEL"].map((header) => (
                            <th
                                key={header}
                                onClick={() => requestSort(header)}
                                className="cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-gray-700 dark:hover:text-gray-300"
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white dark:bg-[#20212c] divide-y divide-gray-200 dark:divide-gray-500">
                    {sortedTasks.map((task, index) => (
                        <TaskRow
                            key={index}
                            task={task}
                            taskIndex={index}
                            colIndex={colIndex}
                            onChange={onChange}
                            tasks={sortedTasks}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TasksTable;
