import React, { useEffect, useState, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import ElipsisMenu from "../components/ElipsisMenu";
import elipsis from "../assets/icon-vertical-ellipsis.svg";
import boardsSlice from "../redux/boardsSlice";
import Subtask from "../components/Subtask";
import AddEditprojectModal from "./AddEditTaskModal";
import AddEditActiveModal from "./AddEditActiveModal";
import DeleteModal from "./DeleteModal";
import AxiosService from "../axios";
import TaskLogModal from "./TaskLogModal";
import utils from "../utils";
import Action from "../components/Action";
import DoughnutChart from "../components/DoughNutChart";
import FaturamentoMes from "../components/FaturamentoMes";
import Conversion from "../components/Conversion";
import ActiveProject from "../components/ActiveProject";
import { MdAddTask } from "react-icons/md";
import { useFetchDataFromApiQuery } from "../redux/ApiSlice"; // Replace with the correct path to your boardsSlice file
import { Store } from "react-notifications-component";
import ExpiredCheck from "../components/ExpiredCheck";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { MdOutlineSendAndArchive } from "react-icons/md";
import { MdAttachEmail } from "react-icons/md";
import { MdLocationSearching } from "react-icons/md";
import { ImTarget } from "react-icons/im";
import ActionLead from "../components/ActionLead";
import { controllers } from "chart.js";
import { set } from "lodash";
import ExpiringLocalStorage from "../ExpiringLocalStorage";
import AddEditActiveLeadModal from "./AddEditActiveLeadModal";
import AddEditLeadModal from "./AddEditLeadModal";
import LeadElipsisMenu from "../components/LeadElipsisMenu";
import AddEditActiveDistribuidoraModal from "./AddEditActiveDistribuidoraModal";
import AddEditDistribuidoraModal from "./AddEditDistribuidoraModel";
import ActionDistribuidora from "../components/ActionDistribuidora";

function TaskDistribuidoraModal({
	taskIndex,
	colIndex,
	setIsTaskModalOpen,
	lead,
	onCloseModal,
}) {
	const dispatch = useDispatch();
	const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isLeadTaskModalOpen, setIsLeadTaskModalOpen] = useState(false);
	const [workout, setWorkout] = useState([]);
	const [checked, setChecked] = useState(false);
	const [pipe, setPipe] = useState([]);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [contactType, setContactType] = useState();
	const [contact, setContact] = useState("");
	const [date, setDate] = useState("");
	const [isValid, setIsValid] = useState(true);
	const [status, setStatus] = useState(); // Initialize status with project.PAGAMENTO value
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingCharts, setIsLoadingCharts] = useState(true);
	const [newColIndex, setNewColIndex] = useState();
	const [action, setActionToOpen] = useState();
	const [payback, setPayback] = useState();
	const [conversion, setConversion] = useState();
	const [doughnut, setDoughnut] = useState();
	const [active, setActiveProject] = useState();
	const [idProj, setIdProj] = useState(""); // Initial state for idProj
	const [atividade, setAtividade] = useState(""); // Initial state for atividade
	const [dataRegistro, setDataRegistro] = useState(); // Initial state for dataRegistro
	const [dataRetorno, setDataRetorno] = useState(); // Initial state for dataRetorno
	const [observacao, setObservacao] = useState(""); // Initial state for observacao
	const [contato, setContato] = useState(""); // Initial state for contato
	const [codProj, setCodProj] = useState(""); // Initial state for codProj
	const [idLead, setIdLead] = useState(""); // Initial state for idLead
	const [conferido, setConferido] = useState(""); // Initial state for conferido
	const [nivel, setNivel] = useState(""); // Initial state for nivel
	const [revendas, setRevendas] = useState([]);
	const [idRevenda, setRevendaId] = useState();
	const [revenda, setRevenda] = useState();
	const [atividadeList, setAtividadeList] = useState([]);
	const [statusLead, setStatusLead] = useState("");
	const { isError, isSuccess, data, isUninitialized } =
		useFetchDataFromApiQuery();

	const user = useSelector((state) => state.auth.user);

	const onChange = (e) => {
		const selectedStatus = e.target.value;
		setStatus(selectedStatus); // Update status state when the selection changes
	};
	const token = useSelector((state) => state.auth.token);

	const setToken = (token) => {
		AxiosService.setAuthorizationToken(token);
	};
	const getCurrentDateTime = () => {
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
		const day = String(now.getDate()).padStart(2, "0");
		const hours = String(now.getHours()).padStart(2, "0");
		const minutes = String(now.getMinutes()).padStart(2, "0");

		return `${year}-${month}-${day}T${hours}:${minutes}`;
	};
	const validate = () => {
		setIsValid(false);
		if (!title.trim()) {
			return false;
		}
		setIsValid(true);
		return true;
	};

	useEffect(() => {
		// Load saved data from storage when the component mounts
		const savedData = ExpiringLocalStorage.getWithExpiry(lead.ID_LEAD);
		if (savedData) {
			setRevendaId(savedData.idRevenda);
			setRevenda(savedData.revenda);
		}
	}, []);
	// const handleModalClose = () => {
	// 	// Save the modal data to a storage mechanism (e.g., local storage, session storage, or a state management solution)
	// 	// You can use browser's local storage as an example:
	// 	ExpiringLocalStorage.setWithExpiry(lead.ID_LEAD, {idRevenda:idRevenda,revenda:revenda}, 120);
	// };
	const onClose = useCallback(
		(e) => {
			if (e.target !== e.currentTarget) {
				return;
			}

			const statusChanged = false;

			onCloseModal();
			setIsTaskModalOpen(false);
		},
		[status, taskIndex, colIndex, newColIndex, dispatch, setIsTaskModalOpen],
	);

	const fetchUpdatedTaskList = async () => {
		try {
			setIsLoading(true); // Set loading state to false after fetching data

			const ID_LEAD = lead.ID_DISTRIBUIDORA;

			const response = await AxiosService.get(
				`/atividadeDistribuidora/find/?conditions[]=ID_DISTRIBUIDORA&values[]=${lead.ID_DISTRIBUIDORA}`,
			);
			// The response.data contains the updated list of projects
			const updatedprojectList = response.data;
			setPipe(updatedprojectList);
			setIsLoading(false); // Set loading state to false after fetching data
		} catch (error) {
			console.error("Error fetching updated project list:", error);
		}
	};

	useEffect(() => {
		// const getStatus = async () => {
		// 	setStatus(lead.PAGAMENTO_FUNIL);
		// };

		const getCharts = async () => {
			if (lead.ID_REVENDA) {
				setIsLoadingCharts(true);
				await faturamentoMesRevenda(lead.ID_REVENDA);
				await currentQuarterData(lead.ID_REVENDA);
				await metaTri(lead.ID_REVENDA);
				await projectExp(lead.ID_REVENDA);
				setIsLoadingCharts(false);
			} else {
				setIsLoadingCharts(true);
				await faturamentoMesRevenda(idRevenda);
				await currentQuarterData(idRevenda);
				await metaTri(idRevenda);
				await projectExp(idRevenda);
				setIsLoadingCharts(false);
			}
		};

		fetchUpdatedTaskList();
		setDataRegistro(getCurrentDateTime());
		setDataRetorno(getCurrentDateTime());
		getCharts();
		setToken(token);
	}, []);

	const onChangeAction = (e) => {
		setChecked(e.target.checked);
	};

	const getPagamentoForm = async (id) => {
		await AxiosService.get(`/pagamentoType/${id}`)
			.then((response) => {
				setStatus(response.data[0].PAGAMENTO_FUNIL);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const onCreatePipe = async () => {
		const endpoint = "atividadeDistribuidora/createAtividade";
		const postData = {
			ID_DISTRIBUIDORA: lead.ID_DISTRIBUIDORA,
			descricao: description,
			tipo: title,
			dataRetorno: dataRetorno.replace("T", " "),
			tipoContato: contactType,
			contato: contact,
			completada: 0,
			nomeUsuario: user.NOME,
		};

		await AxiosService.post(endpoint, postData)
			.then((response) => {
				// Handle successful response data
			})
			.catch((error) => {
				console.error("POST Request Error:", error);
				// Handle errors
			});
		Store.addNotification({
			title: "Sucesso!",
			message: "Atividade criada!",
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 2500,
				onScreen: true,
			},
		});
		setTitle("");
		setDescription("");
		setContact("");
		setDate(getCurrentDateTime());
		setContactType();
		await fetchUpdatedTaskList();
	};

	const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
	const [isLogTaskModalOpen, setIsLogTaskModalOpen] = useState(false);
	const [isActiveTaskModalOpen, setIsActiveTaskModalOpen] = useState(false);

	const setOpenEditModal = () => {
		setIsAddTaskModalOpen(true);
		setIsElipsisMenuOpen(false);
	};
	const setOpenActiveModal = () => {
		setIsActiveTaskModalOpen(true);
		setIsElipsisMenuOpen(false);
	};
	const setOpenDeleteModal = () => {
		setIsElipsisMenuOpen(false);
		setIsDeleteModalOpen(true);
	};

	const setOpenLogModal = () => {
		setIsElipsisMenuOpen(false);
		setIsLogTaskModalOpen(true);
	};

	const setCloseActiveModal = async () => {
		await fetchUpdatedTaskList();
		setIsActiveTaskModalOpen(false);
	};
	const setCloseLeadModal = async () => {
		await fetchUpdatedTaskList();
		setIsLeadTaskModalOpen(false);
	};
	const faturamentoMesRevenda = async (id) => {
		await AxiosService.get(`/faturamentomesrevenda/${id}`)
			.then((response) => {
				setPayback(response.data[0].TotalDaSoma);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const currentQuarterData = async (id) => {
		await AxiosService.get(`/projectPaymentStatus/${id}`)
			.then((response) => {
				setConversion(response.data.percentagePaidProjects);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const metaTri = async (id) => {
		await AxiosService.get(`/revendaMetaTri/${id}`)
			.then((response) => {
				setDoughnut(response.data.percentagePaidProjects);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const projectExp = async (id) => {
		await AxiosService.get(`/projectExp/${id}`)
			.then((response) => {
				setActiveProject(response.data[0].ativos);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	function formatNumber(number) {
		const valorAsNumber = parseFloat(number);
		// Convert the number to a currency string in Brazilian Real format
		const formattedNumber = valorAsNumber.toLocaleString("pt-BR", {
			style: "currency",
			currency: "BRL",
		});
		return formattedNumber;
	}

	const handleDateChange = (e) => {
		setDataRetorno(e.target.value); // Update the state with the selected date value
	};
	const onChangeType = (e) => {
		setContactType(e.target.value);
	};
	const contactList = [
		{ name: "Telefone" },
		{ name: "Email" },
		{ name: "Whatsapp" },
		{ name: "Telegram" },
	];
	const OpenModalActive = (value) => {
		setActionToOpen(value);
		setOpenActiveModal();
		// You can perform any action or state updates here
	};
	const setOpenLeadModal = () => {
		setIsElipsisMenuOpen(false);
		setIsLeadTaskModalOpen(true);
	};
	function extractDigits(phoneNumber) {
		return phoneNumber.replace(/\D/g, "");
	}

	if (isSuccess) {
		const boards = data.boards;
		const board = boards.find((board) => board.isActive === true);
		const columns = board.columns;
		const col = columns.find((col, i) => i === colIndex);
		const phoneNumber = extractDigits(lead.FONE);
		const formattedPhoneNumber = `+55 (${phoneNumber
			.toString()
			.substring(0, 2)}) ${phoneNumber.toString().substring(2, 7)}-${phoneNumber
			.toString()
			.substring(7)}`;
		return (
			<div
				onClick={onClose}
				className="fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide z-50 left-0 bottom-0 justify-center items-center flex dropdown duration-1000 ease-in-out"
			>
				{/* MODAL SECTION */}
				<div className="scrollbar-hide overflow-y-scroll max-h-[95vh] my-auto max-w-[1000px] w-[800px] bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] mx-auto w-full px-8 py-8 rounded-xl">
					<div>
						<h1 className="text-3xl text-center hover:scale-105 hover:text-[#3A007E] duration-500 ease-in-out">
							{(() => {
								switch (lead.STATUS) {
									case 0:
										return "Coleta de dados";
									case 1:
										return "Reunião";
									case 2:
										return "Proposta";
									case 3:
										return "Negociação";
									default:
										return "Status Indefinido";
								}
							})()}
						</h1>
					</div>
					<div className="relative flex justify-between w-full items-center">
						<h1 className="text-lg"></h1>
						<img
							onClick={() => {
								setIsElipsisMenuOpen((prevState) => !prevState);
							}}
							src={elipsis}
							alt="elipsis"
							className="cursor-pointer h-6"
						/>
						{isElipsisMenuOpen && (
							<LeadElipsisMenu
								setOpenEditModal={setOpenLeadModal}
								type="Lead"
							/>
						)}
					</div>

					<div className="flex flex-col space-y-4 text-justify justify-center ">
						<div className="flex flex-row justify-center w-full h-[100px]">
							<div className="flex flex-row items-center justify-center space-x-2 bg-[#f4f7fd] ml-2 w-2/6 dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
								<ImTarget className="text-center  text-2xl" />
								<p className="font-bold text-center  break-keep">{lead.NOME}</p>
							</div>
							<div className="flex flex-row items-center justify-center space-x-2 bg-[#f4f7fd] ml-2 w-2/6 dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
								{/* <p className="font-bold tracking-wide text-xl">Potencia</p> */}
								<MdOutlineSendAndArchive className="text-center text-2xl" />
								<p className="font-bold text-center break-keep">
									{lead.CIDADE}
								</p>
							</div>
							<div className="flex flex-row items-center justify-center space-x-2 bg-[#f4f7fd] ml-2 w-2/6 dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
								{/* <p className="font-bold tracking-wide text-xl">Valor </p> */}
								<MdOutlinePhoneAndroid className="text-center text-2xl" />

								<p className="font-bold text-center break-keep">{lead.FONE}</p>
							</div>
							<div className="flex flex-row items-center justify-center space-x-2 bg-[#f4f7fd] ml-2 w-2/6 dark:bg-gradient-to-l from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out">
								{/* <p className="font-bold tracking-wide text-xl">V. Desc </p> */}
								<MdAttachEmail className="text-center text-2xl" />

								<p className="font-bold text-center break-keep">{lead.EMAIL}</p>
							</div>
						</div>
					</div>

					{/* Current Status Section */}
					<div className="mt-2 flex flex-col md:flex-row w-full">
						<div className="scrollbar-hide overflow-y-scroll space-y-3 text-black dark:text-white font-bold w-full md:w-4/4 mx-auto md:px-2 rounded-xl md:ml-4">
							{/* <div className="flex flex-row justify-center w-full ">
								<div className="flex flex-col  bg-[#f4f7fd] ml-2 justify-center w-2/6 dark:bg-gradient-to-l  from-[#fae24d] to-[#ed772f] rounded-lg shadow-lg p-2 m-2 text-center hover:scale-110 duration-500 ease-in-out ">
									<p className="font-bold tracking-wide text-xl">Projeto </p>
									<p className="font-bold tracking-wide text-center">
									{lead.CLIENTE}
									</p>
								</div>
								<div className="flex flex-col  bg-[#f4f7fd] justify-center w-2/6 dark:bg-gradient-to-l  break-keep from-[#fae24d] to-[#ed772f] rounded-lg p-4 m-2 text-center shadow-lg hover:scale-110 duration-500 ease-in-out ">
									<p className="font-bold tracking-wide text-xl">Potencia</p>
									<p className="font-bold text-center  break-keep">
										{lead.RAZAO_SOCIAL}
									</p>
								</div> 
								 <div className="flex flex-col bg-[#f4f7fd] justify-center w-2/6 dark:bg-gradient-to-l  from-[#fae24d] to-[#ed772f] rounded-lg p-4 m-2 text-center shadow-lg hover:scale-110 duration-500 ease-in-out ">
									<p className="font-bold tracking-wide text-xl">Valor </p>
									<p className="font-bold tracking-wide text-center">
										{formattedPhoneNumber}
									</p>
								</div>
								<div className="flex flex-col bg-[#f4f7fd] justify-center w-2/6 h-[120px] dark:bg-gradient-to-l  from-[#fae24d] to-[#ed772f] rounded-lg m-2 p-4 text-center shadow-lg hover:scale-110 duration-500 ease-in-out ">
									<p className="font-bold tracking-wide text-xl">V. Desc </p>
									<p className="font-bold tracking-wide text-center">
										{lead.EMAIL}
									</p>
								</div>
							</div> */}
							{/* project Name */}
							{
								<>
									<>
										<>
											<div className="flex flex-col w-full  mt-6">
												<input
													value={title}
													onChange={(e) => setTitle(e.target.value)}
													id="project-name-input"
													type="text"
													maxLength={40}
													className=" bg-transparent  px-4 py-2 outline-none focus:border-0 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-1  ring-0  focus:scale-105 hover:text-[#3A007E] duration-500 ease-in-out "
													placeholder="Nome da Atividade"
												/>
											</div>
											<div className="mt-2 flex flex-col">
												<textarea
													value={description}
													onChange={(e) => setDescription(e.target.value)}
													id="project-description-input"
													className=" bg-transparent outline-none min-h-[50px] focus:border-0 px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-[1px] focus:scale-105 hover:text-[#3A007E] duration-500 ease-in-out "
													placeholder="Descrição da tarefa"
												/>
											</div>
											<div className="flex flex-col md:flex-row space-y-0 mt-2 mx-auto">
												<div className="flex flex-col items-center justify-center">
													<select
														value={contactType}
														onChange={onChangeType}
														className=" select-status pr-7 pl-1.5 py-2 rounded-lg dark:bg-[#2b2c37] text-sm bg-transparent focus:border-0  border-[1px] border-gray-600 focus:outline-[#3A007E] outline-none hover:outline-[#3A007E]  duration-500 ease-in-out"
													>
														{" "}
														<option disabled selected>
															Selecione o contato
														</option>
														{contactList.map((column, index) => (
															<option
																className="status-options"
																key={index}
																value={column.name}
															>
																{column.name}
															</option>
														))}
													</select>
												</div>
												<div className="mt-2 flex flex-col items-center justify-center mx-auto">
													<input
														value={contact}
														onChange={(e) => setContact(e.target.value)}
														id="project-name-input"
														type="text"
														className=" bg-transparent m-1 px-2 py-2 outline-none focus:border-0 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#3A007E] outline-1  ring-0  focus:scale-105 hover:text-[#3A007E] duration-500 ease-in-out "
														placeholder="Contato"
													/>
												</div>
												<div className="mt-2 flex flex-col text-black items-center justify-center mx-auto">
													<input
														aria-label="Date and time"
														type="datetime-local"
														className="px-1 py-2 rounded-full bg-[#f4f7fd] dark:bg-[#20212c] dark:text-white hover:scale-105 duration-500 ease-in-out border-0 mr-10"
														onChange={handleDateChange}
														value={dataRetorno}
													/>
												</div>
											</div>
											
										</>
										<div className="flex flex-col space-y-0 items-center">
											<button
												onClick={() => {
													const isValid = validate();
													if (isValid) {
														onCreatePipe();
													}
												}}
												className="flex items-center justify-center text-center w-1/6 text-white bg-gradient-to-l  from-[#fae24d] to-[#ed772f] py-2 rounded-lg hover:scale-105 duration-500 ease-in-out "
											>
												<MdAddTask className="mr-2" />

												{"Salvar"}
											</button>
										</div>
									</>
									<div className="container mx-auto md:p-4">
										<div className="space-y-2 w-full">
											{isLoading ? (
												<div role="status" className="text-center">
													<svg
														aria-hidden="true"
														className="inline w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
														viewBox="0 0 100 101"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
															fill="currentColor"
														/>
														<path
															d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
															fill="currentFill"
														/>
													</svg>
													<span className="sr-only">Loading...</span>
												</div> // Show loading indicator while fetching data
											) : (
												<ol className="space-y-2 w-full">
													{/* Your existing conditional rendering */}
													{utils.binarySearchAll(
														pipe,"ID_DISTRIBUIDORA",
														lead.ID_DISTRIBUIDORA,
													) != true ? (
														// Display a message if no activities are found for the project
														<p className="font-bold tracking-wide text-center text-xl p-4">
															Não há atividades para este projeto
														</p>
													) : (
														pipe
															.sort((a, b) => b.ID_ATIVIDADE - a.ID_ATIVIDADE)
															.map((proj, index) => {
																// Your mapping logic for displaying 'Action' components
																if (
																	lead.ID_DISTRIBUIDORA ==
																	proj.ID_DISTRIBUIDORA
																) {
																	// Convert the number to a currency string in Brazilian Real format

																	return (
																		<ActionDistribuidora
																			onClick={OpenModalActive}
																			key={index}
																			taskIndex={index}
																			colIndex={colIndex}
																			action={pipe}
																			complet={proj.CONFERIDO}
																			lead={lead.ID_DISTRIBUIDORA}
																		/>
																	);
																}
															})
													)}
												</ol>
											)}
										</div>
									</div>
								</>
							}
						</div>
					</div>
				</div>

				{/* Delete and Add/Edit Modals */}
				{/* {isDeleteModalOpen && (
				<DeleteModal
					onDeleteBtnClick={onDeleteBtnClick}
					type="project"
					title={project.title}
				/>
			)} */}
				{isLogTaskModalOpen && (
					<TaskLogModal
						setIsAddprojectModalOpen={setIsLogTaskModalOpen}
						setIsTaskModalOpen={setIsTaskModalOpen}
						colIndex={colIndex}
						taskIndex={lead.COD_PROJ}
						log={workout}
					/>
				)}
				{isActiveTaskModalOpen && (
					<AddEditActiveDistribuidoraModal
						setIsAddTaskModalOpen={setCloseActiveModal}
						setIsTaskModalOpen={setIsTaskModalOpen}
						colIndex={colIndex}
						taskIndex={taskIndex}
						action={action}
						lead={lead}
						onClick={fetchUpdatedTaskList}
						onClose={fetchUpdatedTaskList}
						type="edit"
					/>
				)}
				{isLeadTaskModalOpen && (
					<AddEditDistribuidoraModal
						setIsAddTaskModalOpen={setCloseLeadModal}
						setIsTaskModalOpen={setCloseLeadModal}
						colIndex={colIndex}
						taskIndex={taskIndex}
						action={lead}
						onClick={fetchUpdatedTaskList}
						onClose={fetchUpdatedTaskList}
						type="edit"
					/>
				)}
			</div>
		);
	}
}

export default TaskDistribuidoraModal;
